import { FC } from "react";

// * Data
import { notifyTitles } from "./NotificationItem.data";

// * Props
import { NotificationItemProps } from "./NotificationItem.interface";

// * Components
import Icon from "../../../Icon";

//* Styles
import styles from "./NotificationItem.module.css";

const NotificationItem: FC<NotificationItemProps> = ({ notify }) => {
  const { message, notify_type } = notify;

  return (
    <div className={styles.notification}>
      <div className={styles.notification__icon}>
        <Icon name={`notify-${notify_type}`} />
      </div>

      <div className={styles.notification__content}>
        <h6 className={styles.notification__title}>
          {notifyTitles[notify_type]}
        </h6>

        <p className={styles.notification__text} dangerouslySetInnerHTML={{__html: message}}></p>
      </div>
    </div>
  );
};

export default NotificationItem;
