import { useScreenWidth } from "../../../hooks/useScreenWidth";
import s from "./AddPlanedEventForm.module.css";
import cn from "classnames";
import { Channel } from "../../../features/api/apiSlice";
import { Popup } from "../../Popup";
import PopupStructure from "../../PopupStructure/PopupStructure";
import TooltipButton from "../../TooltipButton/TooltipButton";
import { PopupTitle } from "../../PopupTitle";
import { Button } from "../../Button";
import { ButtonGroup } from "../../ButtonGroup";
import YoutubeForm from "../PlannedForm/YoutubeForm/YoutubeForm";
import { useState } from "react";

interface Props {
  onClose: () => void;
  channel?: Channel;
}

export function AddPlanedEventForm({ onClose, channel }: Props) {
  const { isMobile } = useScreenWidth();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Popup isShow={true} onClose={onClose} maxWidth="530px">
      <div className={cn(s['broadcast-edit'])}>
        <PopupStructure
          headerComponent={
            <PopupTitle>
              Создание события
              {!isMobile &&
                <TooltipButton
                  position="bottom-center"
                  text="Запланированный эфир начнется согласно выставленной дате и времени."
                />
              }
            </PopupTitle>
          }
          contentComponent={
            <YoutubeForm channel={channel} onClose={onClose} setIsLoading={setIsLoading}/>
          }
          footerComponent={
            <ButtonGroup>
              <Button kind="secondary" type="button" onClick={onClose}>Отмена</Button>
              <Button form="planned-form" kind="primary" type="submit" disabled={isLoading}>
                {isLoading ? 'Сохранение...' : 'Сохранить'}
              </Button>
            </ButtonGroup>
          }
        />
      </div>
    </Popup>
  );
}
