import { FC } from "react";

import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { switchTheme } from "../../../features/optionsSlice";

// * Components
import Icon from "../../Icon";

//* Styles
import styles from "./HeaderThemeSwitcher.module.css";

const HeaderThemeSwitcher: FC = () => {
  const { theme } = useAppSelector((state) => state.options);

  const dispatch = useAppDispatch();

  const handleChange = () => {
    dispatch(switchTheme());
  };

  return (
    <div className={styles.switcher} onClick={() => handleChange()}>
      <Icon name={theme === "dark" ? "light-mode" : "dark-mode"} />
    </div>
  );
};

export default HeaderThemeSwitcher;
