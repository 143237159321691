import { IStepProps } from "./Step/Step.interface";

type ISteps = Omit <IStepProps, "step" | "border">

export const steps: ISteps[] = [
  {
    title: "Поделитесь своей ссылкой",
    description: "Опубликуйте ссылку в своих социальных сетях или прикрепите в описание под трансляцией",
  },

  {
    title: "Пользователь регистрируется по вашей ссылке",
    description: "Каждый, кто зарегистрируется по ссылке, получит 150 баллов на баланс",
  },

  {
    title: "Получите баллы",
    description: "С каждого пришедшего пользователя, оплатившего тариф, вы получите 30% баллами от стоимости его подписки",
  },

  {
    title: "Используйте свои баллы",
    description: "Используйте баллы при покупке или продлении своего тарифа",
  }
];