import { Icon } from "../../Icon/Icon.interface";

interface SocialItem {
  icon: Icon;
  link: string;
}

export const socials: SocialItem[] = [
  {
    icon: "vk",
    link: "https://vk.com/restream_su",
  },

  {
    icon: "telegram",
    link: "https://t.me/restream_su",
  },
];
