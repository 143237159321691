import React, { FC } from "react";

// * Icons
import coin from "./assets/coin.svg";

// * Hooks
import { SubmitHandler, useForm } from "react-hook-form";
import { useBuyTariffMutation, useGetTariffsQuery } from "../../../features/api/apiSlice";

// * Components
import { Popup } from "../../Popup";
import { PopupTitle } from "../../PopupTitle";
import { ButtonGroup } from "../../ButtonGroup";
import { Button } from "../../Button";
import { Link } from "react-router-dom";

// * Props
import { WithdrawPopupProps } from "./WithdrawPopup.interface";

//* Styles
import styles from "./WithdrawPopup.module.css";
import PopupStructure from "../../PopupStructure";

const WithdrawPopup: FC<WithdrawPopupProps> = (props) => {
  const [buyTariff] = useBuyTariffMutation();
  const { data: tariffs } = useGetTariffsQuery();
  const selectedTariff = tariffs?.tariffs.find((tariff) => tariff.id === props.selectedTariffId);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    clearErrors,
    resetField,
  } = useForm<{ refPoints: number }>({ mode: "onChange" });

  // * Payment handler
  const handleWithdrawCoins: SubmitHandler<{ refPoints: number }> = ({ refPoints }) => {
    if(!isValid) return;

    buyTariff({
      id: Number(props.selectedTariffId),
      refPoints: refPoints,
    })
      .unwrap()
      .then(({ buy_url }) => (window.location.href = buy_url));
  };

  const handleClose = () => {
    clearErrors("refPoints");
    resetField("refPoints");
    props.onClose();
  };

  return (
    <div className={styles.popup}>
      <Popup {...props} onClose={() => handleClose()}>
        <PopupStructure
          headerComponent={<PopupTitle>Использовать баллы</PopupTitle>}
          contentComponent={
            <>
              <form id="withdraw-form" onSubmit={handleSubmit(handleWithdrawCoins)}>
                <p className={styles.popup__subtitle}>
                  Используйте свои баллы для оплаты тарифа.{" "}
                  <Link to="/referral">Получить больше баллов</Link>
                </p>

                <div className={styles.popup__section}>
                  <p>Доступно:</p>
                  <div>
                    <img src={coin} />
                    <span>{props.coins} баллов</span>
                  </div>
                </div>

                <div className={styles.popup__section}>
                  <p>Списать:</p>
                  <div>
                    <input
                      {...register("refPoints", {
                        max: {
                          value: props.coins || Number(selectedTariff?.price) / 2,
                          message: "Вы не можете списать такое количество баллов!",
                        },
                        min: {
                          value: 0,
                          message: "Укажите число больше 0"
                        },
                        required: "Введите баллы!",
                      })}
                      type="number"
                    />
                  </div>
                </div>

                {errors.refPoints?.message && (
                  <p className={styles.popup__error}>{errors.refPoints.message}</p>
                )}
              </form>
            </>
          }
          footerComponent={
            <ButtonGroup>
              <Button kind="secondary" onClick={() => handleClose()}>
                Отмена
              </Button>
              <Button kind="primary" type="submit" form="withdraw-form">
                Оплатить
              </Button>
            </ButtonGroup>
          }
        />
      </Popup>
    </div>
  );
};

export default WithdrawPopup;
