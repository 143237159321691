import React from "react";

import { InputLegendProps } from './InputLegend.interface';
import styles from './InputLegend.module.css';

const InputLegend: React.FC<InputLegendProps> = ({
  legend,
  children
}) => {
  return (
    <label className={styles.field}>
      <legend className={styles.field__legend}>{legend}</legend>

      {children}
    </label>
  );
};

export default InputLegend;