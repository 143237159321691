import { Notify } from "../../../../features/notifySlice";

type NotifyTitlesType = {
  [key in Notify["notify_type"]]: string;
};

export const notifyTitles: NotifyTitlesType = {
  error: "Произошла ошибка!",
  info: "Информация!",
  success: "Успешно!",
  warning: "Внимание!"
};
