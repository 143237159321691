import { FC } from "react";

// * Icons
import transfer from "./assets/transfer.svg";
import coins from "./assets/coins.svg";
import users from "./assets/users.svg";

// * Components
import CountUp from "react-countup";

//* Props
import { IStatisticsProps } from "./Statistics.interface";

//* Styles
import styles from "./Statistics.module.css";

const Statistics: FC<IStatisticsProps> = ({spend_ref_points, ref_point, referrals_count}) => {
  const statistics = [
    {
      icon: transfer,
      value: spend_ref_points,
      caption: "количество использованных баллов",
    },
    {
      icon: coins,
      value: ref_point,
      caption: "количество текущих баллов",
    },
    {
      icon: users,
      value: referrals_count,
      caption: "количество регистраций по ссылке",
    },
  ];

  return (
    <div className={styles.statistics}>
      <div className={styles.statistics__wrapper}>
        {statistics.map((stat, idx) => (
          <div className={styles.statistics__stat} key={idx}>
            <div className={styles["statistics__stat-value"]}>
              <img src={stat.icon} />
              <h3>
                <CountUp
                  start={0}
                  end={stat.value || 0}
                  duration={3}
                  useEasing
                />
              </h3>
            </div>
            <p>{stat.caption}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Statistics;