import cn from "classnames";
import s from "./ButtonWithImage.module.css";

type Props = {
    imgSrc: string;
    text: string;
    className?: string;
    alt?: string;
    onClick: () => void;
};

export function ButtonWithImage({ imgSrc, text, alt = '', className, onClick }: Props) {
    return (
        <li className={cn(s['button'], className)} onClick={onClick}>
            <img className={s['button__image']} src={imgSrc} alt={alt} />
            <div className={s['button__action-text']}>{text}</div>
        </li>
    );
}