import { FC } from "react";

// * Props
import { IStepProps } from "./Step.interface";

// * Helpers
import cn from "classnames";

//* Styles
import styles from './Step.module.css';

const Step: FC<IStepProps> = ({ step, title, description, border }) => {
  return (
    <div className={styles.step}>
      <div className={cn(styles.step__number, { [styles.step__number_border]: border })}>
        { step }
      </div>

      <div className={styles.step__text}>
        <h2>{ title }</h2>
        <p>{ description }</p>
      </div>
    </div>
  );
};

export default Step;