import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Notify } from "../notifySlice";
const { REACT_APP_API_ENDPOINT: baseUrl } = process.env;

export type User = {
    avatar?: string;
    user_id: number;
    user_name: string;
    user_email: string;
    registration_date: string;

    ref_points: number;
    ref_token: string;
    ref_url: string;
    spend_ref_points: number;
    referrals_count: number;

    chat_config: string;
    chat_token: string;
    no_mailing: boolean;
    tariff: {
        id: number;
        name: keyof typeof UserTariff;
        days_left: number;
        start_at: string;
        end_at: string;
        max_online_outputs: number;
        price: number;
        max_input_bandwidth: number;
    };

    is_first_login: boolean;
};

export interface SignUpParams {
    email: string;
    password: string;
    passwordConfirmation: string;
    utmTag: string | null;
    referralToken: string | null;
}

// * Tariff name with channel count for this tariff
export enum UserTariff {
    "R2" = 2,
    "R5" = 5,
    "R10" = 10,
}

export type Broadcast = {
    id: number;
    name: string;
    description?: string;
    start_at: string;
    end_at: string;
    rtmp_key: string;
    capture_url: string;
    iframe_code: string;
    iframe_url: string;
    m3u8_url: string;
    status: 'offline' | 'starting' | 'stopping' | 'online';
    restream_templates: Channel[];
    error: string;
    current_bitrate: number;
};

export type PlanedBroadcast = {
    id: Broadcast['id'];
    outer_title: Channel['outer_title'];
    outer_desc: Channel['outer_desc'];
    start_time: Broadcast['start_at'];
    latency: Channel['latency'];
    category_id: Channel['category_id'];
    category_name: Channel['category_name'];
}

export type BroadcastList = Broadcast[];

export type BroadcastsData = {
    limit: number;
    offset: number;
    total: number;
    broadcasts: BroadcastList;
};

export type Success = {
    success: boolean;
};

// Planned streams
export interface IPlannedStream {
    restreamId: string;
    title: string;
    type: "channel" | "event";
    scheduled_start_time?: string;
    platform: Platform;
}

export interface IYoutubeStream extends IPlannedStream {
    id: string;
    privacy_status: "public" | "private";
    live_chat_id?: string;
    thumbnails: Record<"default" | "high" | "medium", { url: string; height: number; width: number }>;
}

export interface IVkStream extends IPlannedStream {
    vk_user_id: string;
    description: string;
    thumbnails: {
        url: string;
    };
}

export type Platform =
    'restream' |
    'youtube' |
    'rutube' |
    'twitch' |
    'trovo' |
    'nuum' |
    'gg' |
    'vk' |
    'vk_play' |
    'ok' |
    'telegram' |
    'kick' |
    'boosty';

// Channel aka restream template on the server
export enum ChannelError {
    "TOKEN_AUTH_ERROR" = "TOKEN_AUTH_ERROR",
    "KEY_REQUIRED" = "KEY_REQUIRED",
    "STREAM_ERROR" = "STREAM_ERROR",
    "STREAM_BITRATE_WARNING" = "STREAM_BITRATE_WARNING",
    "OUTDATED_CHANNEL_ERROR" = "OUTDATED_CHANNEL_ERROR"
}

export type Channel = {
    id: number;
    name: string;
    target_url: string;
    key: string;
    enabled: boolean;
    api_authorized?: boolean;
    platform?: Platform;
    category_id?: ChannelCategory['id'];
    category_name?: ChannelCategory['name'];
    outer_title: string;
    outer_desc?: string;
    vk_wallpost?: boolean;
    error?: ChannelError;
    latency?: ChannelLatencyType;
    status: "online" | "offline" | "disabled";
};

export type ChannelStatusType = "ready" | "active" | "inactive";
export type ChannelLatencyType = "normal" | "low" | "ultra_low";

export interface ChannelCategory {
    id: string;
    name: string;
    box_art_url?: string;
}

export type ChannelData = {
    limit: number;
    offset: number;
    total: number;
    restream_templates: Channel[];
};

export type TariffsData = {
    limit: number;
    offset: number;
    total: number;
    tariffs: Tariff[];
};

export interface Tariff {
    id: number;
    name: string;
    description: string;
    max_online_inputs: number;
    max_online_outputs: number;
    promo: boolean; // Акционное предложение или нет
    promo_price: number; // Цена по акции
    price: number;
    duration: number;
    features: string[];
}

interface IResponseWithPagination {
    limit: number;
    offset: number;
    total: number;
}

export interface Transaction {
    id: number;
    tariff_id: number;
    tariff_name: string;
    sum: number; // * Сумма платежа
    buy_date: string;
    terminate_date: string | null;
    status: "aborted" | "completed" | "started";
}

export interface TransactionsData extends IResponseWithPagination {
    transactions: Transaction[];
}

export interface NotificationsData extends IResponseWithPagination {
    notifications: Notify[];
    any_unread: boolean;
}

export interface MarkNotificationsAsReadOptions {
    personalNotificationIds?: (number | undefined)[];
    systemNotificationIds?: (number | undefined)[];
}
export interface Message {
    platform?: Platform;
    user: string;
    message?: string;
    date?: number; // * Technical message has no date
    error?: {
        captcha_sid?: number;
        captcha_img?: string;
    };
}

export type WidgetSettings = {
    name_color?: string;
    message_color?: string;
    background_color?: string;
    id?: string;
    chat_token?: string;
    scale: string;
}

export const BROADCASTS_TAG = 'broadcasts';
const CHANNELS_TAG = 'channels';
const USER_TAG = 'user';
const NOTIFY_TAG = 'notify';

export const apiSlice = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl,
        credentials: 'include',
        prepareHeaders(headers) {
            // ? does not allow sending FormData with an image to the server
            // headers.set('Content-Type', 'application/json');
            headers.set('accept', 'application/json');
            return headers;
        }
    }),
    tagTypes: [BROADCASTS_TAG, CHANNELS_TAG, USER_TAG, NOTIFY_TAG],
    endpoints(builder) {
        return {
            // Broadcasts
            getBroadcasts: builder.query<BroadcastsData, void>({
                query: () => '/api/broadcasts',
                providesTags: [BROADCASTS_TAG]
            }),
            // Broadcast
            getBroadcast: builder.query<{ broadcast: Broadcast }, Broadcast['id']>({
                query: (id) => `/api/broadcasts/${id}`,
                providesTags: [BROADCASTS_TAG]
            }),
            createBroadcast: builder.mutation<{ broadcast: Broadcast }, Pick<Broadcast, 'name' | 'start_at' | 'description'>>({
                query: (body) => ({
                    url: '/api/broadcasts',
                    method: 'POST',
                    body
                }),
                invalidatesTags: [BROADCASTS_TAG]
            }),
            deleteBroadcast: builder.mutation<Success, Broadcast['id']>({
                query: (id) => ({
                    url: `/api/broadcasts/${id}`,
                    method: 'DELETE'
                }),
                invalidatesTags: [BROADCASTS_TAG]
            }),
            updateBroadcast: builder.mutation<Broadcast, { id: Broadcast['id']; body: Pick<Broadcast, 'name' | 'start_at' | 'rtmp_key' | 'description'> }>({
                query: ({ id, body }) => ({
                    url: `/api/broadcasts/${id}`,
                    method: 'PUT',
                    body
                }),
                invalidatesTags: [BROADCASTS_TAG]
            }),
            startBroadcast: builder.mutation<Success, Broadcast['id']>({
                query: (id) => ({
                    url: `/api/broadcasts/${id}/start`,
                    method: 'POST'
                }),
                invalidatesTags: [BROADCASTS_TAG]
            }),
            stopBroadcast: builder.mutation<Success, Broadcast['id']>({
                query: (id) => ({
                    url: `/api/broadcasts/${id}/stop`,
                    method: 'POST'
                }),
                invalidatesTags: [BROADCASTS_TAG]
            }),
            changeRtmpKey: builder.mutation<Broadcast, { broadcastId: Broadcast['id'] }>({
                query: ({ broadcastId }) => ({
                    url: `/api/broadcasts/${broadcastId}/rtmp_key_generate`,
                    method: 'POST'
                }),
                invalidatesTags: [BROADCASTS_TAG]
            }),
            planedBroadcast: builder.mutation<Success, PlanedBroadcast>({
                query: ({ id, outer_title, outer_desc, start_time, latency, category_id, category_name }) => ({
                    url: `/api/broadcasts/${id}/youtube_upcoming`,
                    method: 'POST',
                    body: {
                        outer_title,
                        outer_desc,
                        start_time,
                        latency,
                        category_id,
                        category_name
                    }
                }),
                invalidatesTags: [BROADCASTS_TAG]
            }),
            // User
            getUser: builder.query<User, string | void>({
                query: (token) => token ? `/api/user?chat_token=${token}` : "/api/user",
                providesTags: [USER_TAG]
            }),
            uploadAvatar: builder.mutation<User, { body: FormData; uid?: User["user_id"] }>({
                query: ({ body, uid }) => ({
                    url: `/api/users/${uid}/update_avatar`,
                    method: 'POST',
                    body,
                }),
                invalidatesTags: [USER_TAG],
            }),
            updateRefToken: builder.mutation<void, { uid: number; ref_token: string }>({
                query: ({ uid, ref_token }) => ({
                    url: `/api/users/${uid}/update_ref_token`,
                    method: "POST",
                    body: {
                        ref_token
                    }
                }),
                invalidatesTags: [USER_TAG],
            }),

            // * Notifications
            getNotifications: builder.query<NotificationsData, { isUnread: boolean; page?: number; limit?: number }>({
                query: ({ isUnread, page = 1, limit = 20 }) => ({
                    url: `/api/notifications?unread=${Number(isUnread)}&page=${page}&per_page=${limit}`
                }),
                providesTags: [NOTIFY_TAG]
            }),

            markNotificationAsRead: builder.mutation<void, MarkNotificationsAsReadOptions>({
                query: ({ personalNotificationIds, systemNotificationIds }) => ({
                    method: "POST",
                    url: "/api/notifications/mark_as_read",
                    body: {
                        personal_ids: personalNotificationIds,
                        system_ids: systemNotificationIds,
                    }
                }),
                invalidatesTags: [NOTIFY_TAG]
            }),

            // Channels
            getChannels: builder.query<ChannelData, void>({
                query: () => '/api/restream_templates',
                providesTags: [CHANNELS_TAG]
            }),

            selectYoutubeStreams: builder.mutation<void, { streams: IYoutubeStream[]; broadcastId?: Broadcast["id"] }>({
                query: ({ streams, broadcastId }) => ({
                    url: `/api/broadcasts/${broadcastId}/youtube_select_stream`,
                    method: "POST",
                    body: {
                        streams
                    }
                }),
                invalidatesTags: [BROADCASTS_TAG]
            }),

            selectVkStreams: builder.mutation<void, { streams: IVkStream[]; broadcastId?: Broadcast["id"] }>({
                query: ({ streams, broadcastId }) => ({
                    url: `/api/broadcasts/${broadcastId}/vk_select_stream`,
                    method: "POST",
                    body: {
                        streams
                    }
                }),
                invalidatesTags: [BROADCASTS_TAG]
            }),

            // * Chat widget
            widgetSettings: builder.mutation<{ chat_config: WidgetSettings }, { body: WidgetSettings; uid?: User["user_id"] }>({
                query: ({ body, uid }) => ({
                    url: `/api/users/${uid}/chat_config`,
                    method: 'POST',
                    body,
                }),
                invalidatesTags: [USER_TAG],
            }),

            // * Multichat
            getChatMessages: builder.query<{ response: Message[] }, { id?: string; token?: string }>({
                query: ({ id, token }) => `/api/broadcasts/${id}/multichat?chat_token=${token}`,
            }),

            // Channel
            createChannel: builder.mutation<{ restream_template: Channel }, Pick<Channel, 'name' | 'target_url' | 'key' | 'platform'>>({
                query: (params) => ({
                    url: '/api/restream_templates',
                    method: 'POST',
                    body: params
                }),
                invalidatesTags: [BROADCASTS_TAG]
            }),
            getChannelCategories: builder.query<ChannelCategory[], { channelId?: Channel['id']; categoryName?: string }>({
                query: ({ channelId, categoryName }) => ({
                    url: `/api/restream_templates/${channelId}/categories`,
                    method: 'GET',
                    params: {
                        search_name: categoryName
                    }
                }),

            }),
            updateChannel: builder.mutation<Pick<ChannelData, 'restream_templates'>, Partial<Channel>>({
                query: ({ id, ...restParams }) => ({
                    url: `/api/restream_templates/${id}`,
                    method: 'PUT',
                    body: restParams
                }),
                invalidatesTags: [BROADCASTS_TAG]
            }),
            deleteChannel: builder.mutation<Success, Channel['id']>({
                query: (id) => ({
                    url: `/api/restream_templates/${id}`,
                    method: 'DELETE'
                }),
                invalidatesTags: [BROADCASTS_TAG]
            }),
            enableChannel: builder.mutation<Success, { broadcastId: Broadcast['id']; channelId: Channel['id'] }>({
                query: ({ broadcastId, channelId }) => ({
                    url: `/api/broadcasts/${broadcastId}/template_on/${channelId}`,
                    method: 'POST'
                }),
                invalidatesTags: [BROADCASTS_TAG]
            }),
            disableChannel: builder.mutation<Success, { broadcastId: Broadcast['id']; channelId: Channel['id'] }>({
                query: ({ broadcastId, channelId }) => ({
                    url: `/api/broadcasts/${broadcastId}/template_off/${channelId}`,
                    method: 'POST'
                }),
                invalidatesTags: [BROADCASTS_TAG]
            }),
            toggleChannels: builder.mutation<Success, { broadcastId: Broadcast['id']; target: string }>({
                query: ({ broadcastId, target }) => ({
                    url: `/api/broadcasts/${broadcastId}/toggle_templates`,
                    method: "POST",
                    body: {
                        target: `${target}`,
                    },
                }),
                invalidatesTags: [BROADCASTS_TAG]
            }),
            getChannelStatus: builder.query<ChannelStatusType, { channelId?: Channel['id'] }>({
                query: ({ channelId }) => `/api/restream_templates/${channelId}/check_status`,
                transformResponse(response: { status: ChannelStatusType }) {
                    return response.status;
                },
            }),
            // Tariffs
            getTariffs: builder.query<TariffsData, void>({
                query: () => '/api/tariffs'
            }),
            buyTariff: builder.mutation<{ buy_url: string }, { id: TariffsData['tariffs'][0]['id']; refPoints?: number }>({
                query: ({ id, refPoints = 0 }) => ({
                    url: `/api/buy`,
                    method: 'POST',
                    body: { tariff_id: id, spend_ref_points: refPoints }
                })
            }),
            getTransactions: builder.query<TransactionsData, { limit: number; page: number }>({
                query: ({ limit, page }) => `/api/transactions?page=${page}&per_page=${limit}`
            }),
            // Auth
            signIn: builder.mutation<{ message: string }, { email: string; password: string }>({
                query: ({ email, password }) => ({
                    url: '/api_auth/sign_in',
                    method: 'POST',
                    body: {
                        user: {
                            email,
                            password
                        }
                    }
                }),
                invalidatesTags: [USER_TAG]
            }),
            register: builder.mutation<{ message: string }, SignUpParams>({
                query: ({ email, password, passwordConfirmation, utmTag, referralToken }) => ({
                    url: '/api_auth/sign_up',
                    method: 'POST',
                    body: {
                        user: {
                            email,
                            password,
                            password_confirmation: passwordConfirmation,
                        },
                        ref_token: referralToken,
                        utm_tag: utmTag,
                    }
                })
            }),
            logout: builder.mutation<{ message: string }, void>({
                query: () => ({
                    url: '/api_auth/sign_out',
                    method: 'DELETE'
                }),
                invalidatesTags: [USER_TAG]
            }),
            passwordRecovery: builder.mutation<{ message: string }, string>({
                query: (email) => ({
                    url: '/api_auth/password',
                    method: 'POST',
                    body: {
                        user: {
                            email
                        }
                    }
                }),
            }),
            passwordReset: builder.mutation<{ message: string }, { token: string; password: string; confirmation: string }>({
                query: ({ token, password, confirmation }) => ({
                    url: '/api_auth/password',
                    method: 'PUT',
                    body: {
                        user: {
                            reset_password_token: token,
                            password: password,
                            password_confirmation: confirmation
                        }
                    }
                })
            }),
            // * Utm
            updateUtm: builder.mutation<void, { userId: number; utmTag: string }>({
                query: ({ userId, utmTag }) => ({
                    method: "POST",
                    url: `/api/users/${userId}/update_utm_tag`,
                    body: {
                        utm_tag: utmTag,
                    }
                })
            }),
            // Profile
            changePassword: builder.mutation<{ message: string }, { currentPassword: string; password: string; passwordConfirmation: string }>({
                query: ({ currentPassword, password, passwordConfirmation }) => ({
                    url: '/api_auth/change_password',
                    method: 'PUT',
                    body: {
                        user: {
                            password,
                            password_confirmation: passwordConfirmation,
                            current_password: currentPassword
                        }
                    }
                }),
            }),
            changeEmail: builder.mutation<{ message: string }, { email: string; password: string; userId?: number }>({
                query: ({ email, password, userId }) => ({
                    url: `/api/users/${userId}/account_with_password`,
                    method: 'PUT',
                    body: {
                        user: {
                            email,
                            password
                        }
                    }
                }),
                // invalidatesTags: [USER_TAG]
            }),
            toggleMailing: builder.mutation<void, { userId?: number; noMailing: boolean }>({
                query: ({ userId, noMailing }) => ({
                    url: `/api/users/${userId}/account`,
                    method: 'PUT',
                    body: {
                        user: {
                            no_mailing: noMailing,
                        }
                    }
                }),
            }),
        };
    }
});

export const {
    useGetBroadcastsQuery,
    useGetUserQuery,
    useDeleteBroadcastMutation,
    useUpdateBroadcastMutation,
    useCreateBroadcastMutation,
    useGetBroadcastQuery,
    useCreateChannelMutation,
    useGetChannelCategoriesQuery,
    useGetChannelsQuery,
    useUpdateChannelMutation,
    useDeleteChannelMutation,
    useEnableChannelMutation,
    useDisableChannelMutation,
    useToggleChannelsMutation,
    useStartBroadcastMutation,
    useStopBroadcastMutation,
    useChangeRtmpKeyMutation,
    usePlanedBroadcastMutation,
    useGetTariffsQuery,
    useBuyTariffMutation,
    useGetTransactionsQuery,
    useSignInMutation,
    useRegisterMutation,
    useLogoutMutation,
    useChangeEmailMutation,
    useChangePasswordMutation,
    usePasswordRecoveryMutation,
    usePasswordResetMutation,
    useUploadAvatarMutation,
    useWidgetSettingsMutation,
    useGetChatMessagesQuery,
    useLazyGetUserQuery,
    useLazyGetBroadcastQuery,
    useUpdateRefTokenMutation,
    useGetNotificationsQuery,
    useLazyGetNotificationsQuery,
    useToggleMailingMutation,
    useUpdateUtmMutation,
    useLazyGetChannelStatusQuery,
    useMarkNotificationAsReadMutation,
    useSelectYoutubeStreamsMutation,
    useSelectVkStreamsMutation,
} = apiSlice;