import classNames from "classnames";
import { ComponentProps } from "react";
import s from "./PageContainerWithBackground.module.css";

type Props = ComponentProps<'div'>;

export function PageContainerWithBackground({ className, children }: Props) {
    const containerClassName = classNames(className, s['container']);
    return (
        <div className={containerClassName}>
            {children}
        </div>
    );
}