import { useState } from "react";

// * Components
import Layout from "../../components/Layout";
import { Button } from "../../components/Button";
import PasswordForm from "../../components/Profile/Forms/PasswordForm";
import UserInfo from "../../components/Profile/UserInfo";
import Accordion from "../../components/Accordion";
import UserAvatar from "../../components/Profile/UserAvatar";
import { Popup } from "../../components/Popup";
import ProfileSection from "../../components/Profile/ProfileSection";
import ProfileMailing from "../../components/Profile/ProfileMailing";
import PopupStructure from "../../components/PopupStructure";
import PopupTitleDangerous from "../../components/PopupTitleDangerous";
import EmailForm from "../../components/Profile/Forms/EmailForm";

// * Hooks
import { useGetUserQuery, useLogoutMutation } from "../../features/api/apiSlice";
import { useScreenWidth } from "../../hooks/useScreenWidth";

// * Styles
import styles from "./Profile.module.css";

export function Profile() {
    const { isMobile } = useScreenWidth();
    const { data: user } = useGetUserQuery();
    const [ logout ] = useLogoutMutation();

    const [ showEmailPopup, setShowEmailPopup ] = useState(false);
    const [ showPasswordPopup, setShowPasswordPopup ] = useState(false);

    return (
        <Layout title="Профиль">
            { !isMobile && <div className={styles['title']}>Профиль пользователя</div> }

            <div className={styles['page']}>
                { isMobile &&
                    <>
                        <div className={styles["user-info"]}>
                            <UserAvatar user={user} />
                        </div>

                        <Accordion title="Ваш тариф">
                            <UserInfo user={user}/>
                        </Accordion>

                        <Accordion title="Контактные данные">
                            <EmailForm user={user} setShowPopup={setShowEmailPopup} />
                        </Accordion>

                        <Accordion title="Безопасность и вход">
                            <PasswordForm setShowPopup={setShowPasswordPopup} />
                        </Accordion>

                        <Accordion title="Рассылка сообщений">
                            <div className={styles.page__section}>
                                <ProfileMailing user={user} />
                            </div>
                        </Accordion>

                        <p className={styles["user-logout"]} onClick={() => logout()}>Выйти из аккаунта</p>
                    </>
                }

                { !isMobile &&
                    <>
                        <div className={styles.page__section}>
                            <ProfileSection icon="email" title="Ваша почта">
                                <EmailForm user={user} setShowPopup={setShowEmailPopup} />
                            </ProfileSection>

                            <ProfileSection icon="security" title="Безопасность аккаунта">
                                <PasswordForm setShowPopup={setShowPasswordPopup}/>
                            </ProfileSection>
                        </div>

                        <div className={styles.page__section}>
                            <ProfileSection icon="data" title="Данные">
                                <UserInfo user={user}/>
                            </ProfileSection>

                            <ProfileSection icon="notification" title="Рассылка сообщений">
                                <ProfileMailing user={user} />
                            </ProfileSection>
                        </div>

                        <UserAvatar user={user} />
                    </>
                }
            </div>

            <Popup onClose={() => setShowEmailPopup(false)} isShow={showEmailPopup}>
                <PopupStructure
                    headerComponent={
                        <PopupTitleDangerous>
                            На новый e-mail было отправлено письмо подтверждения
                        </PopupTitleDangerous>
                    }
                    contentComponent={
                        <div className={styles['description']}>До момента перехода по ссылке из письма подтверждения на аккаунте будет сохраняться старый e-mail</div>
                    }
                    footerComponent={
                        <Button kind="primary" onClick={() => setShowEmailPopup(false)}>Хорошо</Button>
                    }
                />
            </Popup>

            <Popup onClose={() => setShowPasswordPopup(false)} isShow={showPasswordPopup}>
                <PopupStructure
                    headerComponent={
                        <PopupTitleDangerous>
                            Пароль успешно изменен!
                        </PopupTitleDangerous>
                    }
                    contentComponent={
                        <div className={styles['description']}>Ваш пароль был успешно изменен! Теперь Вы можете войти в свой аккаунт с новым паролем</div>
                    }
                    footerComponent={
                        <Button kind="primary" onClick={() => setShowPasswordPopup(false)}>Хорошо</Button>
                    }
                />
            </Popup>
        </Layout>
    );
}