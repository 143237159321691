import { FC, PropsWithChildren } from "react";

// * Components
import Icon from "../../Icon";

// * Props
import { ProfileSectionProps } from './ProfileSection.interface';

// * Styles
import styles from './ProfileSection.module.css';


const ProfileSection: FC<PropsWithChildren<ProfileSectionProps>> = ({ icon, title, children }) => {
  return (
    <div className={styles.section}>

      <div className={styles.section__header}>
        <Icon name={icon} />
        <h6>{title}</h6>
      </div>

      <div className={styles.section__body}>
        {children}
      </div>

    </div>
  );
};

export default ProfileSection;