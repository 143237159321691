import React, { FormEvent, useCallback, useRef, useState } from "react";

// * Store
import { useUpdateChannelMutation } from "../../features/api/apiSlice";

// * Components
import { Button } from "../Button";
import { ButtonGroup } from "../ButtonGroup";
import { InputField } from "../InputField";
import { PasswordField } from "../PasswordField";
import { Popup } from "../Popup";
import { PopupTitle } from "../PopupTitle";
import { Error } from "../Error";
import PopupStructure from "../PopupStructure";
import InputLegend from "../InputLegend";

// * Local
import { EditChannelPopupProps } from './EditChannelPopup.interface';
import s from "./EditChannelPopup.module.css";
import TextAreaField from "../TextAreaField";

function getDefaultErrors() {
    return { name: '', url: '', key: '' };
}

export const EditChannelPopup: React.FC<EditChannelPopupProps> = ({ channel, ...popupProps }) => {
    const [ updateChannel ] = useUpdateChannelMutation();
    const nameRef = useRef<HTMLTextAreaElement>(null);
    const urlRef = useRef<HTMLInputElement>(null);
    const keyRef = useRef<HTMLInputElement>(null);
    const [ errorFields, setErrorFields ] = useState(getDefaultErrors());
    const [ errorText, setErrorText ] = useState('');
    const handleInput = useCallback(() => {
        setErrorFields(getDefaultErrors());
        setErrorText('');
    }, []);
    function handleSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const name = nameRef.current?.value;
        const url = urlRef.current?.value;
        const key = keyRef.current?.value;

        const isNameEmpty = name === '';
        const isUrlEmpty = url === '';
        const isKeyEmpty = key === '';
        if (isNameEmpty || isUrlEmpty || isKeyEmpty) {
            setErrorFields({
                name: isNameEmpty ? 'Пустое поле' : '',
                url: isUrlEmpty ? 'Пустое поле' : '',
                key: isKeyEmpty ? 'Пустое поле' : ''
            });
            return setErrorText('Заполните обязательные поля');
        }
        updateChannel({
            id: channel.id,
            key: key!,
            name: name!,
            target_url: url!
        });
        popupProps.onClose();
    }
    console.log('[*]', channel);
    return (
        <Popup {...popupProps} maxWidth="530px">
            <PopupStructure
                headerComponent={
                    <PopupTitle
                        onClose={popupProps.onClose}
                    >
                        Редактировать канал
                    </PopupTitle>
                }
                contentComponent={
                    <React.Fragment>
                        <form id="channel-form" onSubmit={handleSubmit} className={s['form']}>
                            <InputLegend legend="Название канала">
                                <TextAreaField isError={Boolean(errorFields.name)} maxLength={100} placeholder="Название*" onInput={handleInput} ref={nameRef} defaultValue={channel.name} />
                            </InputLegend>

                            <InputLegend legend="URL канала">
                                <InputField isError={Boolean(errorFields.url)} placeholder="URL*" onInput={handleInput} ref={urlRef} defaultValue={channel.target_url} />
                            </InputLegend>

                            <InputLegend legend="Ключ канала">
                                <PasswordField isError={Boolean(errorFields.key)} placeholder="Ключ*" onInput={handleInput} ref={keyRef} icon="toggle" defaultValue={channel.key} />
                            </InputLegend>
                        </form>
                        <Error className={s['error']}>{errorText}</Error>
                    </React.Fragment>
                }
                footerComponent={
                    <ButtonGroup>
                        <Button kind="secondary" type="button" onClick={popupProps.onClose}>Отмена</Button>
                        <Button form="channel-form" kind="primary" type="submit">Сохранить</Button>
                    </ButtonGroup>
                }
            />
        </Popup>
    );
};