import classNames from "classnames";
import s from "./Popover.module.css";

type Props = {
    children: JSX.Element;
    // show: boolean;
    text: string;
    className: string;
}

export function Popover({ children, /* show = false, */ text, className }: Props) {
    const popoverItemClassName = classNames(
        className,
        s['popover-container__popover'],
        // { [s['popover-container__popover_hide']]: !show }
    );
    return (
        <div className={s['popover-container']}>
            {children}
            <div className={popoverItemClassName}>
                {text}
            </div>
        </div>
    );
}