import videojs from "video.js";
import poster from "./assets/poster.svg";

export const PLAYER_DEFAULTS: videojs.PlayerOptions = {
  preload: "auto",
  autoplay: true,
  controls: true,
  muted: true,
  poster: poster,
  retryOnError: true,
};
