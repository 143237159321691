import { useState, useEffect } from 'react';

const mobileBreakpoint = 768;

export const useScreenWidth = () => {
  const [isMobile, setIsMobile] = useState(window.screen.availWidth <= mobileBreakpoint);

  // * Detect mobile width
  const handleWindowResize = () => {
    if (window.screen.availWidth <= mobileBreakpoint) {
      setIsMobile(true);
      return;
    }

    setIsMobile(false);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return {
    isMobile,
  };
};