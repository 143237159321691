import React, { useCallback, useEffect, useState } from "react";
import { IPostMessageData } from "./Broadcast.interface";

// * Router
import { useNavigate, useParams } from "react-router-dom";

// * Types
import { UserTariff, useLazyGetBroadcastQuery } from "../../features/api/apiSlice";

// * Components
import Layout from "../../components/Layout";
import StreamViewer from "../../components/Stream/StreamViewer";
import Accordion from "../../components/Accordion";
import StreamInfo from "../../components/Stream/StreamInfo";
import { PageLoading } from "../../components/PageLoading";
import { Button } from "../../components/Button";
import { ButtonGroup } from "../../components/ButtonGroup";
import { EditBroadcastPopup } from "../../components/EditBroadcastPopup";
import AddChannelPopup from "../../components/AddChannelPopup";
import { EditChannelPopup } from "../../components/EditChannelPopup";
import { Popup } from "../../components/Popup";
import Tabs from "../../components/Tabs";
import Channels from "../../components/Stream/Channels";
import Chat from "../../components/Stream/Chat";
import EditPlatformPopup from "../../components/EditPlatformPopup";
import PopupStructure from "../../components/PopupStructure";
import PopupTitleDangerous from "../../components/PopupTitleDangerous";
import AsideWithTabs from "../../components/AsideWithTabs";

// * Hooks
import useMeasure from "react-use-measure";
import {
    Channel,
    Broadcast as BroadcastType,
    useGetBroadcastQuery,
    useDeleteChannelMutation,
    useEnableChannelMutation,
    useDisableChannelMutation,
    useStopBroadcastMutation,
    useStartBroadcastMutation,
    useGetUserQuery
} from "../../features/api/apiSlice";
import { useScreenWidth } from "../../hooks/useScreenWidth";

// * Helpers
import cn from "classnames";

// * Styles
import styles from "./Broadcast.module.css";
import { useSocketApi } from "../../hooks/useSocketApi";

const { REACT_APP_API_ENDPOINT } = process.env;

export function Broadcast() {
    const { isMobile } = useScreenWidth();

    const [playerContainer, { height: playerHeight }] = useMeasure();
    const asideStyles = { height: `${playerHeight}px` };

    // * User data
    const { data: user } = useGetUserQuery("");

    // * User tariff name
    const tariffName = user?.tariff.name;

    const navigate = useNavigate();
    const { id } = useParams();
    const [ deleteChannel ] = useDeleteChannelMutation();
    const [ enableChannel ] = useEnableChannelMutation();
    const [ disableChannel ] = useDisableChannelMutation();
    const [ startBroadcast ] = useStartBroadcastMutation();
    const [ stopBroadcast ] = useStopBroadcastMutation();
    const { data, isError, isLoading } = useGetBroadcastQuery(Number(id));
    const [ getBroadcast ] = useLazyGetBroadcastQuery();
    const [ isShowEditBroadcastPopup, setIsShowEditBroadcastPopup ] = useState(false);
    const [ isBroadcastOnline, setIsBroadcastOnline ] = useState(false);
    const [ isShowAddChannelPopup, setIsShowAddChannelPopup ] = useState(false);
    const [ channelToEdit, setChannelToEdit ] = useState<Channel>();
    const [ channelToDelete, setChannelToDelete ] = useState<Channel>();
    const [ enableChannelError, setEnableChannelError ] = useState('');
    const handleClosePopupAddChannel = useCallback(() => setIsShowAddChannelPopup(false), []);
    const handleClosePopupEditChannel = useCallback(() => setChannelToEdit(undefined), []);
    const handleClosePopupDelete = useCallback(() => setChannelToDelete(undefined), []);
    const handleDeleteChannel = useCallback((id: BroadcastType['id']) => {
        deleteChannel(id);
        handleClosePopupDelete();
    }, []);

    const handleToggleEnabled = useCallback(
        async (channel: Channel) => {
            const settings = {
                broadcastId: data!.broadcast.id,
                channelId: channel.id
            };

            if (channel.enabled) return disableChannel(settings);

            const result = await enableChannel(settings);
            if ('error' in result) {
                setEnableChannelError((result.error as any).data.error);
            }
        },
        [data]
    );

    const toggleBroadcast = useCallback(() => {
        if (!data) return;
        const { id, status } = data.broadcast;
        if (['starting', 'online'].some((_status) => status === _status)) {
            stopBroadcast(id);
        } else {
            startBroadcast(id);
        }
    }, [data]);

    /*
        * Когда юзер добавляет новый канал на странице /broadcast/:id - бэкэнд при успешной авторизации
        * отправляет window.opener.postMessage({channel_auth: true, platform}), что означает, что канал успешно добавлен.
    */
    useEffect(() => {
        const handleMessage = (event: MessageEvent<any>) => {
            const eventData = event.data as IPostMessageData;

            if (event.origin === REACT_APP_API_ENDPOINT && eventData.channel_auth) {
                getBroadcast(Number(id))
                    .unwrap()
                    .then(({ broadcast }) => {
                        // Интегрированная площадка goodgame не отдает все необходимые поля на сервер
                        // поэтому после ее добавления открываем окно редактирования площадки
                        // для добавления всех необходимых полей
                        if (eventData.platform === "gg") {
                            const goodGameChannel = broadcast.restream_templates.find(
                                ({ platform }) => platform === "gg"
                            );

                            if (!goodGameChannel) return;
                            setIsShowAddChannelPopup(false);
                            setChannelToEdit(goodGameChannel);
                        }
                    });
            }
        };

        window.addEventListener('message', handleMessage);
        return () => window.removeEventListener('message', handleMessage);
    }, []);

    useEffect(() => {
        if (!data) return;

        setIsBroadcastOnline(data.broadcast.status === "online");
    }, [data]);

    // * Components props
    const streamProps = {
        toggleBroadcast,
        setIsShowEditBroadcastPopup,
    };

    const channelsProps = {
        onToggleEnabled: (channel: Channel) => handleToggleEnabled(channel),
        onEdit: (channel: Channel) => setChannelToEdit(channel),
        onDelete: (channel: Channel) => setChannelToDelete(channel),
        onAddNewChannel: () => setIsShowAddChannelPopup(true)
    };

    useSocketApi({
    broadcastId: data?.broadcast.id,
    onBroadcastStatusUpdated: ({ status }) => {
        setIsBroadcastOnline(status === "online");
    }});

    if (isLoading) return <PageLoading />;
    if (isError) navigate('/');

    return (
        <Layout>
            <div className={styles['page']}>
                { isMobile &&
                    <Tabs items={[
                        {
                            title: "Эфир",
                            component:
                                <>
                                    <StreamViewer broadcast={data?.broadcast} tariff={user?.tariff?.name || ''} {...streamProps} />
                                    <div className={styles.page__section}>
                                        <Accordion title="Информация о трансляции" open={false}>
                                            <StreamInfo broadcastId={Number(id)}/>
                                        </Accordion>
                                    </div>
                                </>
                        },

                        {
                            title: "Каналы",
                            component:
                            <div className={styles.page__wrapper}>
                                <Channels channels={data?.broadcast.restream_templates} {...channelsProps} />
                            </div>
                        },

                        {
                            title: "Чат",
                            component:
                            <div className={styles.page__wrapper}>
                                <Chat />
                            </div>
                        },
                    ]} />
                }

                { !isMobile &&
                    <>
                        <div className={styles.page__column}>
                            <div ref={playerContainer} >
                                <StreamViewer tariff={user?.tariff?.name || ''} broadcast={data?.broadcast} {...streamProps} />
                            </div>
                        </div>

                        <div style={asideStyles} className={styles.page__aside}>
                            <div className={cn(styles.page__section, styles.page__section_gradient)}>
                                <Accordion title="Информация о трансляции" open={true}>
                                    <StreamInfo broadcastId={Number(id)}/>
                                </Accordion>
                            </div>

                            <AsideWithTabs
                                items={[
                                    {
                                        title: "Каналы",
                                        tabIcon: "projects",
                                        component: <Channels channels={data?.broadcast.restream_templates} {...channelsProps} />
                                    },
                                    {
                                        title: "Чат",
                                        tabIcon: "email-unread",
                                        component: <Chat />
                                    }
                                ]}
                            />
                        </div>
                    </>
                }

                {isShowAddChannelPopup &&
                    <AddChannelPopup
                        isShow={isShowAddChannelPopup}
                        onClose={handleClosePopupAddChannel}
                    />
                }

                {channelToEdit
                    ? channelToEdit?.api_authorized ? (
                            <EditPlatformPopup
                                isShow={Boolean(channelToEdit)}
                                onClose={handleClosePopupEditChannel}
                                channel={channelToEdit}
                                isBroadcastOnline={isBroadcastOnline}
                            />
                        ) : (
                            <EditChannelPopup
                                isShow={Boolean(channelToEdit)}
                                onClose={handleClosePopupEditChannel}
                                channel={channelToEdit}
                            />
                        )
                    : null
                }

                {data?.broadcast && isShowEditBroadcastPopup &&
                    <EditBroadcastPopup
                        broadcast={data!.broadcast}
                        onClose={() => setIsShowEditBroadcastPopup(false)}
                    />
                }

                <Popup
                    isShow={Boolean(channelToDelete)}
                    onClose={handleClosePopupDelete}
                >
                    <PopupStructure
                        headerComponent={
                            <PopupTitleDangerous>
                                Предупреждение
                            </PopupTitleDangerous>
                        }
                        contentComponent={
                            <div className={styles['page__popup-description']}>Вы уверены, что хотите удалить канал “{channelToDelete?.name}”?</div>
                        }
                        footerComponent={
                            <ButtonGroup>
                                <Button kind="secondary" onClick={() => handleDeleteChannel(channelToDelete!.id)}>Удалить</Button>
                                <Button kind="primary" onClick={handleClosePopupDelete}>Нет</Button>
                            </ButtonGroup>
                        }
                    />
                </Popup>

                <Popup
                    isShow={Boolean(enableChannelError)}
                    onClose={() => setEnableChannelError("")}
                >
                    <PopupStructure
                        headerComponent={
                            <PopupTitleDangerous>Упс!</PopupTitleDangerous>
                        }
                        contentComponent={
                            <div className={styles['page__popup-description']}>
                                <p>
                                    На вашем тарифном плане доступно только {tariffName ? UserTariff[tariffName] : UserTariff["R2"]} {tariffName === "R2" ? "канала" : "каналов"} для рестрима.
                                </p>

                                <p>
                                    Чтобы использовать больше каналов, измените свой тарифный план
                                </p>
                            </div>
                        }
                        footerComponent={
                            <ButtonGroup>
                                <Button kind="secondary" onClick={() => setEnableChannelError("")}>
                                    Отмена
                                </Button>
                                <Button kind="primary" onClick={() => navigate("/tariffs")}>
                                    Сменить тариф
                                </Button>
                            </ButtonGroup>
                        }
                    />
                </Popup>
            </div>
        </Layout>
    );
}
