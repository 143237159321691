import { FC, FormEvent, useRef, useState } from "react";
import { useParams } from 'react-router';
import { IPlannedStream, useGetBroadcastQuery, useSelectVkStreamsMutation, useSelectYoutubeStreamsMutation } from "../../../features/api/apiSlice";
import cn from "classnames";

// * Components
import { InputField } from "../../InputField";
import PlatformInstruction from "../PlatformInstruction";
import PlannedFormItem from "./PlannedFormItem";

// * Props
import { PlannedFormProps } from "./PlannedForm.interface";

//* Styles
import styles from "./PlannedForm.module.css";
import { AddPlanedEventForm } from "../AddPlanedEventForm/AddPlanedEventForm";

const PlannedForm: FC<PlannedFormProps> = ({
  id,
  selectedPlatform,
  closePopup,
}) => {
  const { plannedStreams } = selectedPlatform;
  const { id: broadcastIdString } = useParams();
  const broadcastId = Number(broadcastIdString);
  const { data: broadcast } = useGetBroadcastQuery(broadcastId);
  const channel = broadcast?.broadcast.restream_templates.find((channel) => channel.platform === selectedPlatform.platform);
  const [isShowCreateBroadcastPopup, setIsShowCreateBroadcastPopup] = useState(false);

  const searchInputRef = useRef<HTMLInputElement>(null);

  const [selectedPlannedStreamIds, setSelectedPlannedStreamIds] = useState<IPlannedStream["restreamId"][]>([]);
  const [filteredPlannedStreamIds, setFilteredPlannedStreamIds] = useState<IPlannedStream["restreamId"][]>([]);

  const channels = (plannedStreams as IPlannedStream[])?.filter((stream) => stream.type === "channel") ?? [];
  const events = (plannedStreams as IPlannedStream[])?.filter((stream) => stream.type === "event") ?? [];

  const [selectVkStreams] = useSelectVkStreamsMutation();
  const [selectYoutubeStreams] = useSelectYoutubeStreamsMutation();

  const handleSearchEvent = () => {
    const searchTerm = searchInputRef.current?.value.toLowerCase().trim();

    if (searchTerm) {
      const filteredPlannedStreamsIds = (plannedStreams as IPlannedStream[])
        .filter((stream) => stream.title.toLowerCase().includes(searchTerm))
        .map(({ restreamId }) => restreamId);

      setFilteredPlannedStreamIds(filteredPlannedStreamsIds);
      return;
    }

    setFilteredPlannedStreamIds([]);
  };

  const isSelected = (restreamId: IPlannedStream["restreamId"]) => {
    return selectedPlannedStreamIds.includes(restreamId);
  };

  const getFilteredStreams = () => {
    return (plannedStreams as IPlannedStream[]).filter(({ restreamId }) =>
      filteredPlannedStreamIds.includes(restreamId)
    );
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    function getPayload<T>() {
      const streams = (plannedStreams as IPlannedStream[]).filter(({ restreamId }) =>
        selectedPlannedStreamIds.includes(restreamId)
      );

      return {
        broadcastId: Number(broadcastId),
        streams: streams as unknown as T[]
      };
    }

    if (selectedPlatform.platform === "vk") {
      selectVkStreams(getPayload());
    }

    if (selectedPlatform.platform === "youtube") {
      selectYoutubeStreams(getPayload());
    }

    closePopup();
  };

  const handleSelectPlannedStream = (restreamId: IPlannedStream["restreamId"]) => {
    // При повторном клике по стриму, убираем его из спика выбранных
    if (isSelected(restreamId)) {
      const filteredStreamIds = selectedPlannedStreamIds.filter(
        (id) => id !== restreamId
      );

      setSelectedPlannedStreamIds(filteredStreamIds);
      return;
    };

    setSelectedPlannedStreamIds((selectedStreamIds) => [...selectedStreamIds, restreamId]);
  };

  return (
    <>
      <form id={id} className={styles.streams} onSubmit={(e) => handleSubmit(e)}>
        <PlatformInstruction selectedPlatform={selectedPlatform} />

        <div className={styles.streams__search}>
          <InputField
            ref={searchInputRef}
            type="text"
            className={styles.stream__input}
            placeholder="Поиск каналов..."
            onInput={() => handleSearchEvent()}
          />
        </div>

        {Boolean(searchInputRef.current?.value.trim()) ? (
          <div className={styles.streams__list}>
            <h6 className={styles.streams__heading}>Результат поиска:</h6>

            {filteredPlannedStreamIds.length ? getFilteredStreams().map((stream) => (
              <PlannedFormItem
                isSelected={isSelected(stream.restreamId)}
                platform={selectedPlatform.platform}
                plannedStream={stream}
                handleClick={() => handleSelectPlannedStream(stream.restreamId)}
              />
            )) : (
              <div>По вашему запросу ничего не найдено...</div>
            )}
          </div>
        ) : (
          <>
            <div className={styles.streams__list}>
              <h6 className={styles.streams__heading}>Новый эфир</h6>

              {channels.map((channel) => (
                <PlannedFormItem
                  isSelected={isSelected(channel.restreamId)}
                  platform={selectedPlatform.platform}
                  plannedStream={channel}
                  handleClick={() => handleSelectPlannedStream(channel.restreamId)}
                />
              ))}
            </div>

            {selectedPlatform.platform === "youtube" && (
              <div
                className={cn(styles.stream, { [styles.stream_selected]: selectedPlatform })}
                onClick={() => setIsShowCreateBroadcastPopup(true)}
              >
                <h6 className={styles.streams__heading}>Создать новое событие</h6>
              </div>
            )}

            {Boolean(events.length) && (
              <div className={styles.streams__list}>
                <h6 className={styles.streams__heading}>
                  Запланированные эфиры ({events.length})
                </h6>
                {events.map((event) => (
                  <PlannedFormItem
                    isSelected={isSelected(event.restreamId)}
                    plannedStream={event}
                    handleClick={() => handleSelectPlannedStream(event.restreamId)}
                  />
                ))}
              </div>
            )}
          </>
        )}
      </form>
      {isShowCreateBroadcastPopup && (
        <AddPlanedEventForm onClose={() => setIsShowCreateBroadcastPopup(false)} channel={channel} />
      )}
    </>
  );
};
export default PlannedForm;
