import { memo } from "react";
import circlesSvg from "./img/circles.svg";
import restreamsuSvg from "./img/restreamsu.svg";
import s from "./Loading.module.css";

// Использую React.memo() чтобы анимация не запускалась сначала после каждого рендера приложения
export const Loading = memo(() => (
    <div className={s['loading']}>
        <img className={s['loading__circles']} src={circlesSvg} alt="" />
        <img className={s['loading__restreamsu']} src={restreamsuSvg} alt="" />
    </div>
));