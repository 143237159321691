import { FC } from "react";

// * Components
import Notify from "../Notify";
import { CSSTransition, TransitionGroup } from 'react-transition-group';

// * Hooks
import { useAppSelector } from "../../../app/hooks";
import { useContainerPosition } from "./hooks/useContainerPosition";

//* Styles
import styles from './NotifyContainer.module.css';

const NotifyContainer: FC = () => {

  // * Notification state
  const { notifications } = useAppSelector((state) => state.notifications);

  // * Hooks
  const [ containerRef ] = useContainerPosition();

  return (
    <div className={styles["notify-container"]} ref={containerRef}>
      <TransitionGroup>
        {notifications.map((notify) =>
          <CSSTransition
            key={notify.id}
            timeout={10000}
            classNames={{
              enter: styles["notify-enter"],
              enterActive: styles["notify-enter-active"],
              exit: styles["notify-exit"],
              exitActive: styles["notify-exit-active"]
            }}
          >
            <Notify {...notify} />
          </CSSTransition>
        )}
      </TransitionGroup>
    </div>
  );
};

export default NotifyContainer;