import { ChannelLatencyType } from "../../features/api/apiSlice";

interface IYoutubeChannelLatency {
  label: string;
  value: ChannelLatencyType;
}

export const IYoutubeChannelLatencies: IYoutubeChannelLatency[] = [
  {
    label: "Стандартная",
    value: "normal",
  },
  {
    label: "Малая",
    value: "low",
  },
  {
    label: "Наименьшая",
    value: "ultra_low",
  },
];