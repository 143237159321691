export function checkEmail(value: string) {
    const parts = value.split('@');
    if (parts.length !== 2) {
        return false;
    }
    const server = parts[1];
    const serverParts = server.split('.');
    if (serverParts.length < 2 || serverParts.some((part) => part.length === 0)) {
        return false;
    }
    return true;
}