import twitchIcon from "./img/twitch.svg";
import googleIcon from "./img/google.svg";
import vkIcon from "./img/vk.svg";
import goodgameIcon from "./img/goodgame.svg";
import telegramIcon from "./img/telegram.svg";

const { REACT_APP_API_ENDPOINT: baseUrl } = process.env;

export const socialNetworks = [
  {
    socialIconSrc: vkIcon,
    socialAuthUrl: `${baseUrl}/api_auth/auth/vkontakte`,
  },
  {
    socialIconSrc: twitchIcon,
    socialAuthUrl: `${baseUrl}/api_auth/auth/twitch`,
  },
  {
    socialIconSrc: googleIcon,
    socialAuthUrl: `${baseUrl}/api_auth/auth/google_oauth2`,
  },
  {
    socialIconSrc: goodgameIcon,
    socialAuthUrl: `${baseUrl}/api_auth/auth/goodgame`,
  },
  {
    socialIconSrc: telegramIcon,
    socialAuthUrl: `${baseUrl}/api_auth/auth/telegram`,
  },
];
