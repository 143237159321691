import { MouseEvent, useEffect, useRef } from "react";
import { ComponentProps } from "react";
import { calcScrollWidth } from "../../helpers/calcScrollWidth";
import s from "./Popup.module.css";
import { useScreenWidth } from "../../hooks/useScreenWidth";

export type Props = ComponentProps<'div'> & {
    onClose: () => void;
    isShow: boolean;
    maxWidth?: string;
};

export function Popup({ onClose, isShow, maxWidth = "430px", children, ...restProps }: Props) {
    const { isMobile } = useScreenWidth();
    const popupRef = useRef<HTMLDivElement>(null);
    const popupBodyStyles = { maxWidth: isMobile ? "100%" : maxWidth };

    function handleClose(event: MouseEvent<HTMLDivElement>) {
        if (event.target !== popupRef.current) {
            return;
        }
        onClose();
    }

    useEffect(() => {
        if (isShow && popupRef.current) {
            const scrollWidth = calcScrollWidth();
            document.body.style.overflow = 'hidden';
            document.body.style.marginRight = `${scrollWidth}px`;
        }
        else {
            document.body.style.overflow = '';
            document.body.style.marginRight = '0px';
        }

        return () => {
            document.body.style.overflow = '';
            document.body.style.marginRight = '0px';
        };
    }, [isShow]);

    return (
        <div {...restProps} className={`${s['popup']} ${isShow ? s['popup_show'] : ''}`} onMouseDown={handleClose} ref={popupRef}>
            <div className={s['popup__body']} style={popupBodyStyles}>
                {children}
            </div>
        </div>
    );
}