import { FC } from 'react';

// * Components
import WidgetMessage from './WidgetMessage';

// * Props
import { ChatWidgetProps } from './ChatWidget.interface';

// * Helpers
import cn from "classnames";

// * Styles
import styles from "./ChatWidget.module.css";

const ChatWidget: FC<ChatWidgetProps> = ({ isPreview = false, backgroundColor, messageColor, nameColor, scale, messages }) => {
  const wrapperStyles = { backgroundColor };

  return (
    <div className={cn(styles["chat-widget"], { [styles["chat-widget-preview"]]: isPreview })}>
      <div style={wrapperStyles} className={styles["chat-widget__wrapper"]}>

        { messages.map((message, index) =>
          <WidgetMessage
            message={message}
            messageColor={messageColor}
            nameColor={nameColor}
            scale={scale}
            key={index}
          />)
        }

      </div>
    </div>
  );
};

export default ChatWidget;