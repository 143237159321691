import { PageContainerWithBackground } from "../../components/PageContainerWithBackground";
import { FormWithLogo } from "../../components/FormWithLogo";
import { InputField } from "../../components/InputField";
import { Popover } from "../../components/Popover";
import { FormEvent, useCallback, useRef, useState } from "react";
import s from "./Reset.module.css";
import { Button } from "../../components/Button";
import { useNavigate, useSearchParams } from "react-router-dom";
import { usePasswordRecoveryMutation, usePasswordResetMutation } from "../../features/api/apiSlice";
import { checkEmail } from "../../helpers/checkEmail";
import { PasswordField } from "../../components/PasswordField";
import { checkPassword } from "../../helpers/checkPassword";

const errorText = {
    email: 'Некорректный e-mail',
    errorUndefinedEmail: 'Неверный Email'
};

export function Reset() {
    const navigate = useNavigate();
    const [ searchParams ] = useSearchParams();
    const [ passwordRecovery ] = usePasswordRecoveryMutation();
    const textInput = useRef<HTMLInputElement>(null);
    const [emailError, setEmailError] = useState('');
    const [ isContinue, setIsContinue ] = useState(false);

    const handleFormSubmit = useCallback(async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!textInput.current) {
            return;
        }
        const { value } = textInput.current;
        if (!checkEmail(value)) {
            return setEmailError(errorText.email);
        }
        const result = await passwordRecovery(value);
        if ('data' in result) {
            setIsContinue(true);
        }
        else {
            setEmailError(errorText.errorUndefinedEmail);
        }
    }, []);

    const token = searchParams.get('token');
    if (token) {
        return (
            <SetNewPassword token={token} />
        );
    }

    if (isContinue) {
        return (
            <PageContainerWithBackground>
                <FormWithLogo>
                    <div className={s['reset']}>
                        <div className={s['reset__text_center']}>Инструкции по восстановлению пароля были отправлены на указанную почту</div>
                            <Button kind="secondary" onClick={() => navigate("/login")}>Назад</Button>
                    </div>
                </FormWithLogo>
            </PageContainerWithBackground>
        );
    }

    return (
        <PageContainerWithBackground>
            <FormWithLogo onSubmit={handleFormSubmit}>
                <div className={s['reset']}>
                    <div className={s['reset__text_center']}>Введите email, который вы использовали при регистрации</div>
                        <Popover
                            text={emailError}
                            className={s['reset__popover']}
                        >
                            <InputField
                                type="email"
                                placeholder="Email"
                                ref={textInput}
                                isError={Boolean(emailError)}
                                onInput={() => {setEmailError('');}}
                            />
                        </Popover>
                        <Button kind="primary" type="submit">Восстановить</Button>
                        <Button kind="secondary" onClick={() => navigate("/login")}>Назад</Button>
                </div>
            </FormWithLogo>
        </PageContainerWithBackground>
    );
}


type SetNewPasswordProps = {
    token: string;
}

function SetNewPassword({ token }: SetNewPasswordProps) {
    const errorText = {
        password: 'В пароле должно быть от 8 символов',
        confirmation: 'Пароли должны совпадать'
    };
    const navigate = useNavigate();
    const [ passwordReset ] = usePasswordResetMutation();
    const passwordRef = useRef<HTMLInputElement>(null);
    const passwordConfirmationRef = useRef<HTMLInputElement>(null);
    const [ passwordConfirmationError, setPasswordConfirmationError ] = useState('');
    const [ passwordError, setPasswordError ] = useState('');
    const [ serverError, setServerError ] = useState('');
    const passwordValueRef = useRef('');
    const handleFormSubmit = useCallback(async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!passwordRef.current || !passwordConfirmationRef.current) {
            return;
        }
        const isEmptyPassword = passwordRef.current.value === '';
        const isPasswordsMatch = passwordRef.current.value === passwordConfirmationRef.current.value;
        if (isEmptyPassword || !checkPassword(passwordRef.current.value)) {
            return setPasswordError(errorText.password);
        }
        if (!isPasswordsMatch) {
            return setPasswordConfirmationError(errorText.confirmation);
        }
        const result = await passwordReset({
            token,
            password: passwordRef.current.value,
            confirmation: passwordConfirmationRef.current.value
        });
        if ('error' in result) {
            setServerError((result.error as any).data.errors);
        }
        if ('data' in result) {
            navigate('/', { replace: true });
        }
    }, []);
    const handlePasswordInput = useCallback((event: FormEvent<HTMLInputElement>) => {
        passwordValueRef.current = event.currentTarget.value;
        setPasswordError(
            checkPassword(event.currentTarget.value) ? '' : errorText.password
        );
        setServerError('');
    }, []);
    const handlePasswordConfirmationInput = useCallback((event: FormEvent<HTMLInputElement>) => {
        setPasswordConfirmationError(
            passwordValueRef.current === event.currentTarget.value ? '' : errorText.confirmation
        );
        setServerError('');
    }, []);
    return (
        <PageContainerWithBackground>
            <FormWithLogo onSubmit={handleFormSubmit}>
                <div className={s['reset']}>
                    <div className={s['reset__text_center']}>Придумайте новый пароль</div>
                    <Popover
                        text={passwordError}
                        className={s['reset__popover']}
                    >
                        <PasswordField
                            placeholder="Пароль"
                            icon="toggle"
                            onInput={handlePasswordInput}
                            isError={Boolean(passwordError)}
                            ref={passwordRef}
                        />
                    </Popover>
                    <Popover
                        text={passwordConfirmationError}
                        className={s['reset__popover']}
                    >
                        <PasswordField
                            placeholder="Подтверждение пароля"
                            icon="status"
                            isError={Boolean(passwordConfirmationError)}
                            onInput={handlePasswordConfirmationInput}
                            ref={passwordConfirmationRef}
                        />
                    </Popover>
                    <Popover
                        text={serverError}
                        className={s['reset__popover']}
                    >
                        <Button kind="primary">Восстановить</Button>
                    </Popover>
                </div>
            </FormWithLogo>
        </PageContainerWithBackground>
    );
}