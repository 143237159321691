import { ComponentProps } from "react";
import cn from "classnames";
import s from "./ButtonGroup.module.css";

type Props = ComponentProps<'ul'>;

export function ButtonGroup({ className, ...restProps }: Props) {
    return (
        <ul {...restProps} className={cn(s['button-group'], className)} />
    );
}