import { PageContainerWithBackground } from "../../components/PageContainerWithBackground";
import { FormWithLogo } from "../../components/FormWithLogo";
import { InputField } from "../../components/InputField";
import { PasswordField } from "../../components/PasswordField";
import { Popover } from "../../components/Popover";
import { FormEvent, useCallback, useRef, useState } from "react";
import s from "./Signup.module.css";
import { Checkbox } from "../../components/Checkbox";
import { Link } from "../../components/Link";
import { Button } from "../../components/Button";
import { SocialNetworksLoginOrSignup } from "../../components/SocialNetworksLoginOrSignup";
import { checkEmail } from "../../helpers/checkEmail";
import { useRegisterMutation } from "../../features/api/apiSlice";
import { checkPassword } from "../../helpers/checkPassword";
import cn from "classnames";
import EmailSentSuccess from "./EmailSentSuccess";
import { useEffect } from "react";
import { useAppDispatch } from "../../app/hooks";
import { addNotify } from "../../features/notifySlice";
import { useReferralToken } from "../../hooks/useReferralToken";
import { SignUpParams } from "../../features/api/apiSlice";
import { useUtmTag } from "../../hooks/useUtmTag";

const errorText = {
  email: "Некорректный e-mail",
  password: "В пароле должно быть от 8 символов",
  confirmation: "Пароли должны совпадать",
};

export function Signup() {
  const dispatch = useAppDispatch();

  // * Ref token
  const { tokenFromUrl } = useReferralToken();

  const [ signup, signupStatus ] = useRegisterMutation();
  const [ isEmailSentSuccessfully, setEmailSentSuccessFully ] = useState(false);

  // * Utm tag from landing
  const { utmTagFromUrl } = useUtmTag();

  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const passwordConfirmationRef = useRef<HTMLInputElement>(null);
  const checkboxInputRef = useRef<HTMLInputElement>(null);

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordConfirmationError, setPasswordConfirmationError] =
    useState("");
  const [serverError, setServerError] = useState("");
  const [checkboxError, setCheckboxError] = useState("");

  const passwordValueRef = useRef("");

  const handleFormSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const isInvalidEmail = !checkEmail(emailRef.current?.value ?? "");
      const isEmptyPassword = passwordRef.current?.value === "";
      const isPasswordsMatch =
        passwordRef.current?.value === passwordConfirmationRef.current?.value;
      const isCheckedPublicOffer = checkboxInputRef.current?.checked;

      if (isInvalidEmail) {
        setEmailError(errorText.email);
      }
      if (isEmptyPassword) {
        setPasswordError(errorText.password);
      }
      if (!isPasswordsMatch) {
        setPasswordError(errorText.confirmation);
      }
      if (!isCheckedPublicOffer) {
        setCheckboxError("Обязательное поле");
      }
      if (isInvalidEmail || isEmptyPassword || !isPasswordsMatch) {
        return;
      }
      if (
        !emailRef.current ||
        !passwordRef.current ||
        !passwordConfirmationRef.current ||
        !isCheckedPublicOffer
      ) {
        return;
      }

      // * Send to Server
      const payload: SignUpParams = {
        email: emailRef.current!.value,
        password: passwordRef.current!.value,
        passwordConfirmation: passwordConfirmationRef.current!.value,
        referralToken: tokenFromUrl,
        utmTag: utmTagFromUrl
      };

      signup(payload)
        .unwrap()
        .then(() => {
          setEmailSentSuccessFully(true);
        })
        .catch((e) =>
          setServerError(e?.data?.errors || "Непредвиденная ошибка")
        );
    },
    []
  );

  const handleEmailInput = useCallback((event: FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setEmailError(checkEmail(value) ? "" : errorText.email);
    setServerError("");
    setCheckboxError("");
  }, []);

  const handlePasswordInput = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      passwordValueRef.current = event.currentTarget.value;
      setPasswordError(
        checkPassword(event.currentTarget.value) ? "" : errorText.password
      );
      setServerError("");
      setCheckboxError("");
    },
    []
  );

  const handlePasswordConfirmationInput = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
          setPasswordConfirmationError(
            passwordValueRef.current === event.currentTarget.value
              ? ""
              : errorText.confirmation
          );
          setServerError("");
          setCheckboxError("");
        },
    []
  );

  useEffect(() => {
    if(!tokenFromUrl) return;

    const timeoutId = setTimeout(() => {
      dispatch(addNotify({
        notify_type: "info",
        message: "Вы проходите регистрацию по реферальной ссылке. После регистрации вам начислится 150 баллов, которые можно использовать при покупке тарифа (1 балл = 1 рубль)"
      }));
    }, 500);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <PageContainerWithBackground>
      {!isEmailSentSuccessfully ? (
        <FormWithLogo onSubmit={handleFormSubmit}>
          <div className={s["signup"]}>
            <Popover text={emailError} className={s["signup__popover"]}>
              <InputField
                type="email"
                placeholder="Email"
                onInput={handleEmailInput}
                isError={Boolean(emailError)}
                ref={emailRef}
              />
            </Popover>
            <Popover text={passwordError} className={s["signup__popover"]}>
              <PasswordField
                placeholder="Пароль"
                icon="toggle"
                onInput={handlePasswordInput}
                isError={Boolean(passwordError)}
                ref={passwordRef}
              />
            </Popover>
            <Popover
              text={passwordConfirmationError}
              className={s["signup__popover"]}
            >
              <PasswordField
                placeholder="Подтверждение пароля"
                icon="status"
                isError={Boolean(passwordConfirmationError)}
                onInput={handlePasswordConfirmationInput}
                ref={passwordConfirmationRef}
              />
            </Popover>
            <Popover text={checkboxError} className={s["signup__popover"]}>
              <Checkbox
                inputRef={checkboxInputRef}
                fakeInputClassName={cn({
                  [s["checkbox_error"]]: checkboxError,
                })}
                onChange={() => setCheckboxError("")}
              >
                <>
                  Я соглашаюсь с условиями{" "}
                  <Link to="http://restream.su/offer" isLocal={false}>
                    Публичной Оферты
                  </Link>
                </>
              </Checkbox>
            </Popover>
            <Popover text={serverError} className={s["signup__popover"]}>
              <Button kind="primary" disabled={signupStatus.isLoading}>
                {signupStatus.isLoading ? "Подождите..." : "Зарегистрироваться"}
              </Button>
            </Popover>
            <div className={s["signup__text_center"]}>
              или зарегистрируйтесь через соцсети:
            </div>
            <SocialNetworksLoginOrSignup />
            <div className={s["signup__text_center"]}>
              Уже есть аккаунт?{" "}
              <Link to="/login">Войдите в личный кабинет</Link>
            </div>
          </div>
        </FormWithLogo>
      ) : (
        <EmailSentSuccess toEmail={emailRef.current?.value || "БАГ"} />
      )}
    </PageContainerWithBackground>
  );
}
