import React, { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { Platform } from '../../../../features/api/apiSlice';

import { ReactComponent as SendSVG } from "./assets/send.svg";
import styles from "./Form.module.css";
import { Message as IMessage } from "../../../../features/api/apiSlice";

interface FormValues {
  message: string;
}

const platforms: Platform[] = [
  'youtube',
  'rutube',
  'twitch',
  'trovo',
  'nuum',
  'gg',
  'vk',
  'vk_play',
  'ok',
  'telegram',
  'kick',
  'boosty'
];

interface Props {
  isDisabled: boolean;
  onSubmit: (message: IMessage) => void;
  children: React.ReactNode;
}

const Form: React.FC<Props> = ({onSubmit, children, isDisabled}) => {
  const { register, handleSubmit, reset, setFocus } = useForm<FormValues>();

  const handleSendMessage: SubmitHandler<FormValues> = ({ message }) => {
    if(isDisabled || !message.trim()) return;

    onSubmit({
      message,
      user: 'Testing Assignee',
      platform: platforms[Math.floor((Math.random()*platforms.length))],
      date: Date.now()
    });
    setFocus("message");
    reset();
  };

  useEffect(() => {
    if(!isDisabled) {
      setFocus("message");
    }
  }, [isDisabled]);

  return (
    <form
      className={styles.form}
      onSubmit={handleSubmit(handleSendMessage)}
    >
      <input
        className={styles.form__input}
        type="text"
        autoComplete="new-off"
        placeholder="Введите сообщение"
        {...register("message", {
          required: "Content is required!",
        })}
      />

      <button
        type="submit"
        className={styles.form__submit}
        // disabled={false}
      >
        <SendSVG/>
      </button>

      <div className={styles['form__action-scroll']}>
        {children}
      </div>
    </form>
  );
};

export default Form;
