import cn from "classnames";
import React from "react";
import { formatTime } from "../../../../helpers/formatTime";

// * Components
import PlatformIcon from '../../../PlatformIcon';

// * Message files
import { MessageProps } from './Message.interface';
import styles from "./Message.module.css";

const Message: React.FC<MessageProps> = ({
  message,
  content,
  isTechnical
}) => {
  return (
    <div
      className={cn(
        styles.message,
        {[styles.message_technical]: isTechnical}
      )}
    >
      <div className={styles.message__platform}>
        <PlatformIcon platform={message.platform} />
      </div>

      <div className={styles.message__info}>
        <span className={styles["message__info-top"]}>
          <h3 className={styles.message__name}>
            {message.user}
          </h3>
          {message.date && (
            <p  className={styles.message__time}>
              {formatTime(new Date(message.date * 1000).toString())}
            </p>
          )}
        </span>

        {isTechnical ? (
          <p className={styles.message__content} dangerouslySetInnerHTML={{__html: content as string}}/>
        ) : (
          <p className={styles.message__content}>
            {message.message}
          </p>
        )}
      </div>
    </div>
  );
};

export default Message;
