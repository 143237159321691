import { Broadcast, Message, Platform } from "../features/api/apiSlice";

export enum SocketApiActionType {
  "BROADCAST_STATUS_UPDATED" = "BROADCAST_STATUS_UPDATED",
  "CHANNEL_STATUS_UPDATED" = "TEMPLATE_STATUS_UPDATED",
  "TWITCH_BITRATE_WARNING" = "TWITCH_BITRATE_WARNING",
  "BITRATE_WARNING" = "BITRATE_WARNING",
  "NEW_CHAT_MESSAGE" = "MULTICHAT_MESSAGE",
  "VK_CAPTCHA_ERROR" = "VK_CAPTCHA_ERROR",
  "VK_FLOOD_ERROR" = "VK_FLOOD_ERROR",
  "VK_UNKNOWN_ERROR" = "VK_UNKNOWN_ERROR",
  "SEND_NEW_CHAT_MESSAGE" = "SEND_MULTICHAT_MESSAGE",
}

export type SocketApiParams = {
  channelId?: number;
  broadcastId?: number;
  onBroadcastStatusUpdated?: (payload: BroadcastStatusUpdatedAction["payload"]) => void;
  onChannelStatusUpdated?: (payload: ChannelStatusUpdated["payload"]["restream_template"]) => void;
  onTwitchBitrateWarning?: (payload: TwitchBitrateWarningAction["payload"]) => void;
  onBitrateWarning?: (payload: BitrateWarningAction["payload"]) => void;
  onNewChatMessage?: (payload: ChatMessageAction["payload"]) => void;
  onVkCaptchaError?: (payload: VkCaptchaErrorAction["payload"]) => void;
  onVkFloodError?: (payload: VkFloodErrorAction["payload"]) => void;
  onVkUnknowError?: (payload: VkUnknownErrorAction["payload"]) => void;
};

export interface BroadcastStatusUpdatedAction {
  action: SocketApiActionType.BROADCAST_STATUS_UPDATED;
  payload: {
    broadcast_id: number;
    status: Broadcast["status"];
    m3u8_url: string;
    uptime: number;
  };
}

export interface ChannelStatusUpdated {
  action: SocketApiActionType.CHANNEL_STATUS_UPDATED;
  payload: {
    restream_template: {
      id: number;
      status: "online" | "offline" | "disabled";
    };
  };
}

export interface TwitchBitrateWarningAction {
  action: SocketApiActionType.TWITCH_BITRATE_WARNING;
  payload: {
    error_bitrate: number;
    restream_template_id: number;
    broadcast_id: Broadcast['id'];
    error: boolean;
  };
}

export interface BitrateWarningAction {
  action: SocketApiActionType.BITRATE_WARNING;
  payload: {
    current_bitrate: number;
    broadcast_id: Broadcast['id'];
    error: boolean;
  };
}

export interface ChatMessageAction {
  action: SocketApiActionType.NEW_CHAT_MESSAGE;
  payload: Message;
}

export interface VkCaptchaErrorAction {
  action: SocketApiActionType.VK_CAPTCHA_ERROR;
  payload: {
    platform: Platform;
    message: string;
    error: {
      error_msg: string;
      captcha_sid: string;
      captcha_img: string;
    };
  };
}

export interface VkFloodErrorAction {
  action: SocketApiActionType.VK_FLOOD_ERROR;
  payload: {
    platform: Platform;
    error: {
      error_msg: string;
    };
  };
}

export interface VkUnknownErrorAction {
  action: SocketApiActionType.VK_UNKNOWN_ERROR;
  payload: {
    platform: Platform;
    error: {
      error_msg: string;
    };
  };
}

interface SendMessageParams {
  identifier: string;
}

export interface SendChatMessageAction extends SendMessageParams {
  action: SocketApiActionType.SEND_NEW_CHAT_MESSAGE;
  payload: {
    message?: Message['message'];
    captcha_sid?: string;
    captcha_key?: string;
    retry_platform?: Platform;
    broadcast_id?: number;
  };
}

export type SocketAnswerAction =
  | BroadcastStatusUpdatedAction
  | ChannelStatusUpdated
  | TwitchBitrateWarningAction
  | BitrateWarningAction
  | ChatMessageAction
  | VkCaptchaErrorAction
  | VkFloodErrorAction
  | VkUnknownErrorAction;

export type SocketRequestAction =
  | SendChatMessageAction
