import { FormEvent, forwardRef, useCallback, useState } from "react";
import Icon from "../Icon";
import { InputField } from "../InputField";
import s from "./PasswordField.module.css";
import { Props as InputFieldProps } from "../InputField";

type InputType = 'text' | 'password';

type Props = InputFieldProps & {
    showToggle?: boolean;
    showStatus?: boolean;
    icon?: 'toggle' | 'status';
    isError?: boolean;
};

export const PasswordField = forwardRef<HTMLInputElement, Props>((props, ref) => {
    const { icon, isError = false, onInput, ...restProps } = props;
    const [inputType, setInputType] = useState<InputType>('password');
    const [isStatusIcon, setIsStatusIcon] = useState(false);
    const handleInput = useCallback((event: FormEvent<HTMLInputElement>) => {
        if (!isStatusIcon) {
            setIsStatusIcon(true);
        }
        onInput?.(event);
    }, [onInput]);
    return (
        <div className={s['password-field']}>
            <InputField
                {...restProps}
                ref={ref}
                onInput={handleInput}
                isError={isError}
                type={inputType}
                className={s['password-field__input']}
            />
            {icon === 'toggle' && (
                <button
                    className={`${s['password-field__icon']} ${s['password-field__icon_pointer']}`}
                    type="button"
                    onClick={() => {
                        setInputType((type) => type === 'text' ? 'password' : 'text');
                    }}
                    tabIndex={-1}
                >
                    <Icon name={inputType === 'text' ? 'eye-opened' : 'eye-closed'} />
                </button>
            )}
            {icon === 'status' && isStatusIcon && (
                <span className={s['password-field__icon']}>
                    <Icon name={isError ? 'error' : 'success'} />
                </span>
            )}
        </div>
    );
});