import { FC, useEffect, useState } from 'react';

// * Components
import { Button, ButtonToggleOnOff } from '../../Button';

// * Props
import { ChannelStatus, ChannelsProps } from './Channels.interface';

// * Styles
import styles from "./Channels.module.css";
import ConnectedChannel from './ConnectedChannel';
import { useSocketApi } from '../../../hooks/useSocketApi';

const Channels: FC<ChannelsProps> = ({
  channels,
  onToggleEnabled,
  onEdit,
  onDelete,
  onAddNewChannel
}) => {
  const [channelStatuses, setChannelStatuses] = useState<ChannelStatus>();

  useSocketApi({
    onChannelStatusUpdated: ({ id, status }) => {
      setChannelStatuses(statuses => {
        return { ...statuses, [id]: status };
      });
    }
  });

  useEffect(() => {
    if (!channels) return;

    const channelStatuses = channels.reduce((acc: ChannelStatus, channel) => {
      acc[channel.id] = channel.status;
      return acc;
    }, {});

    setChannelStatuses(channelStatuses);

  }, [channels]);

  return (
    <>
      <div className={styles["container"]}>
        <div>
          <Button
              className={styles['add-channel']}
              kind="primary"
              onClick={onAddNewChannel}
          >
            <span className={styles['button-text']}>+ Добавить канал</span>
          </Button>
        </div>
          <ButtonToggleOnOff/>
      </div>

      <ul className={styles['channel-settings']}>
          {channels?.map((channel, idx) => (
            <ConnectedChannel
              key={channel.id}
              channel={channel}
              status={channelStatuses && channelStatuses[channel.id]}
              onDelete={onDelete}
              onEdit={onEdit}
              onToggleEnabled={onToggleEnabled}
            />
          ))}
      </ul>
    </>
  );
};

export default Channels;