import { FC, useEffect, useState } from "react";

// * Components
import VideojsPlayer from "../../VideojsPlayer";
import CountdownTimer from "../../VideojsPlayer/CountdownTimer";
import Tooltip from "../../Tooltip";
import Icon from "../../Icon";
import StreamStatus from "../../StreamStatus";
import TruncatedText from "../../TruncatedText";

// * Types
import { StreamStatusType } from "../../StreamStatus/SteamStatus.interface";
import { StreamViewerProps } from "./StreamViewer.interface";

// * Hooks
import { useSocketApi } from "../../../hooks/useSocketApi";

// * Helpers
import { formatDate } from "../../../helpers/formatDate";

// * Styles
import styles from "./StreamViewer.module.css";

const BITRATE_WARNING = "BITRATE_WARNING";
const TWITCH_BITRATE_WARNING = "TWITCH_BITRATE_WARNING";

const StreamViewer: FC<StreamViewerProps> = ({
  broadcast,
  setIsShowEditBroadcastPopup,
  tariff
}) => {
  const getTwitchBitrateError = () => {
    return `Вы ведёте ретрансляцию на Twitch, для стабильной работы трансляции понизьте битрейт до 6000.`;
  };
  const getBitrateError = (currentBitrate: number) => {
    const bitrateLimitForTariff = tariff === "R2" ? "15 Мбит/с." : "40 Мбит/с.";
    return `Предупреждение: битрейт, который вы подаете - ${currentBitrate} Мбит/с, превышает ограничение согласно вашему тарифному плану - в ${bitrateLimitForTariff}\nЧтобы продолжить эфир, понизьте уровень битрейта.`;
  };

  const [broadcastUrl, setBroadcastUrl] = useState("");
  const [isPlanned, setIsPlanned] = useState(false);
  const [bitrateError, setBitrateError] = useState(broadcast?.error && broadcast.error.includes(BITRATE_WARNING) ? getBitrateError(broadcast.current_bitrate) : "");
  const [twitchBitrateError, setTwitchBitrateError] = useState(broadcast?.error && broadcast.error.includes(TWITCH_BITRATE_WARNING) ? getTwitchBitrateError() : "");

  const getStreamStatus = (): StreamStatusType => {
    if (broadcastUrl && !isPlanned) {
      return "online";
    } else if (isPlanned) {
      return "planned";
    } else {
      return "offline";
    }
  };

  useEffect(() => {
    if (!broadcast) return;
    if (broadcast.status === "online"){
      setBroadcastUrl(broadcast.m3u8_url);
    }

    setIsPlanned(new Date(String(broadcast?.start_at)).getTime() > Date.now());
  }, [broadcast]);

  useSocketApi({
    broadcastId: broadcast?.id,
    onBroadcastStatusUpdated: ({ m3u8_url, status }) => {
      if (status === "online") {
        setBroadcastUrl(m3u8_url);
      } else {
        setBroadcastUrl("");
      }
    },
    onTwitchBitrateWarning: ({ error }) => {
      setTwitchBitrateError(error ? getTwitchBitrateError() : "");
    },
    onBitrateWarning: ({ current_bitrate, error }) => {
      setBitrateError(error ? getBitrateError(current_bitrate) : "");
    }
  });

  return (
    <div className={styles.stream}>
      <div className={styles.stream__player}>
        {isPlanned ? (
          <div className={styles.stream__remaining}>
            <CountdownTimer
              startAt={broadcast?.start_at}
              onTimerEnd={() => setIsPlanned(false)}
            />
          </div>
        ) : (
          <div className={styles.stream__video}>
            <VideojsPlayer broadcastUrl={broadcastUrl} />
          </div>
        )}
        <div className={styles.warnings__container}>
          {twitchBitrateError && (
            <div className={styles.warning}>
              {twitchBitrateError}
            </div>
          )}
          {bitrateError && (
            <div className={styles.warning}>
              {bitrateError}
            </div>
          )}
        </div>

      </div>
      <div className={styles.stream__info}>
        <div className={styles.stream__heading}>
          <div className={styles.stream__name} onClick={() => setIsShowEditBroadcastPopup(true)}>
            <p>{broadcast?.name}</p>
            <Tooltip text={'Заполните поля "Название" и "Описание" для этого эфира, эти данные автоматически будут присвоены эфирам на интегрированных площадках (YouTube, Twitch, Trovo, ВКонтакте)'}>
              <Icon name="settings" />
            </Tooltip>
          </div>
          <StreamStatus status={getStreamStatus()} />
        </div>

        <div className={styles.stream__section}>
          <h6 className={styles.stream__subtitle}>Дата начала</h6>
          <p className={styles.stream__description}>{formatDate(broadcast?.start_at, ".")}</p>
        </div>

        <div className={styles.stream__section}>
          <h6 className={styles.stream__subtitle}>Описание</h6>
          {broadcast?.description ? (
            <TruncatedText text={broadcast.description} maxLength={100} />
          ) : (
            <p className={styles.stream__description}>
              Трансляция не имеет описания, Вы можете добавить его
              <span onClick={() => setIsShowEditBroadcastPopup(true)}>Добавить описание</span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default StreamViewer;
