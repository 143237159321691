import { FormEvent, FC, useState } from 'react';
import { useParams } from 'react-router';

// * Components
import { Popup, Props } from '../Popup';
import { ButtonGroup } from '../ButtonGroup';
import { Button } from '../Button';
import { CopyField } from '../CopyField';
import InputSlider from '../InputSlider';
import ChatWidget from '../ChatWidget';
import ColorPicker from '../ColorPicker';

// * Helpers
import { getRouteParams } from '../../helpers/getRouteParams';
import { generateWidgetUrl } from '../../helpers/generateWidgetUrl';

// * Hooks
import { useGetUserQuery, useWidgetSettingsMutation } from '../../features/api/apiSlice';
import { useColor } from 'react-color-palette';

// * Mock
import { previewMessages } from '../ChatWidget/ChatWidget.data';

// * Styles
import styles from "./ChatSettingsPopup.module.css";

// * Helpers
import cn from 'classnames';
import PopupStructure from '../PopupStructure';

const ChatSettingsPopup: FC<Props> = (props) => {
  // * User data
  const { data: user } = useGetUserQuery();

  // * Params
  const { id } = useParams();

  // * Widget params from url
  const widgetParams = getRouteParams(user?.chat_config as string || "");

  // * States
  const [nameColor, setNameColor] = useColor("hex", widgetParams.name_color ? widgetParams.name_color : "#04cafb");
  const [messageColor, setMessageColor] = useColor("hex", widgetParams.message_color ? widgetParams.message_color : "#d9d9d9");
  const [backgroundColor, setBackgroundColor] = useColor("hex", widgetParams.background_color ? widgetParams.background_color : "#1f1f1fE6");
  const [scale, setScale] = useState(widgetParams.scale || "100");

  // * Hooks
  const [ updateSettings ] = useWidgetSettingsMutation();

  const copyUrl = generateWidgetUrl(backgroundColor?.hex, messageColor?.hex, nameColor?.hex, scale, id, user?.chat_token);

  // * Handlers
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    updateSettings({
      body: {
        name_color: nameColor?.hex,
        message_color: messageColor?.hex,
        background_color: backgroundColor?.hex,
        scale,
      },
      uid: user?.user_id
    });
  };

  return (
    <div className={styles.popup}>
      <Popup {...props}>
        <div className={styles.preview}>
          <ChatWidget
            isPreview={true}
            messages={previewMessages}
            scale={scale}
            nameColor={nameColor.hex}
            messageColor={messageColor.hex}
            backgroundColor={backgroundColor.hex}
          />
        </div>

        <PopupStructure
          contentComponent={
            <form id="settings-form" className={styles.settings} onSubmit={handleSubmit}>
              <div className={styles.settings__section}>
                <h3 className={cn(styles.settings__title, styles.settings__title_scale)}>Масштаб</h3>
                <InputSlider
                  range={scale}
                  max={150}
                  min={50}
                  step={1}
                  markStep={50}
                  changeRange={setScale}
                />
              </div>
              <div className={cn(styles.settings__section, styles.settings__section_row)}>
                <div className={styles.settings__block}>
                  <h3 className={styles.settings__title}>Цвет имен</h3>

                  <div className={styles.settings__picker}>
                    <ColorPicker color={nameColor} onChange={setNameColor} pos={{padding: '0 4.5% 0 0'}} />
                  </div>
                </div>

                <div className={styles.settings__block}>
                  <h3 className={styles.settings__title}>Цвет сообщений</h3>

                  <div className={styles.settings__picker}>
                    <ColorPicker color={messageColor} onChange={setMessageColor} />
                  </div>
                </div>

                <div className={styles.settings__block}>
                  <h3 className={styles.settings__title}>Цвет фона</h3>

                  <div className={styles.settings__picker}>
                    <ColorPicker color={backgroundColor} onChange={setBackgroundColor} pos={{padding: '0 0 0 4.5%'}} />
                  </div>
                </div>
              </div>

              <div className={styles.settings__section}>
                <h3 className={styles.settings__title}>Ссылка на виджет чата</h3>

                <div className={styles.settings__copy}>
                  <CopyField type="text" defaultValue={copyUrl} useValueState={false} />
                </div>
              </div>
            </form>
          }
          footerComponent={
            <ButtonGroup>
              <Button kind="secondary" type="button" onClick={() => props.onClose()}>Отмена</Button>
              <Button form='settings-form' kind="primary" type="submit">Сохранить</Button>
            </ButtonGroup>
          }
        />
      </Popup>
    </div>
  );
};

export default ChatSettingsPopup;