import ReactTimePicker from 'react-time-picker';
import "./react-time-picker.custom.css";

type Props = {
    value: Date;
    onChange: (date: string | Date) => void;
}

export function TimePicker({ onChange, value }: Props) {
    return (
        <ReactTimePicker onChange={onChange} value={value} clearIcon={null} clockIcon={null} />
    );
}