import { FC } from "react";

// * Props
import { StreamStatusProps } from "./SteamStatus.interface";

// * Helpers
import cn from "classnames";

//* Styles
import styles from "./StreamStatus.module.css";

const StreamStatus: FC<StreamStatusProps> = ({ status, isStatusText = true }) => {
  const getStreamStatusText = () => {
    if (status === "online") {
      return "есть сигнал";
    } else if (status === "planned") {
      return "запланирована";
    } else {
      return "нет сигнала";
    }
  };

  return (
    <div className={cn(styles.status, styles[`status_${status}`])}>
      <div className={styles.status__icon}>
        <div className={styles["status__icon-upper"]}></div>
        <div className={styles["status__icon-lower"]}></div>
      </div>

      {isStatusText && (
        <div className={styles.status__name}>
          {getStreamStatusText()}
        </div>
      )}
    </div>
  );
};

export default StreamStatus;