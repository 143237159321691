export const checkFile = ({ size, name }: File) => {

  // * Format check
  if(!/\.(jpe?g|png)$/i.test(name))
    return { status: false, message: "Используйте JPG, JPEG, PNG файлы!" };

  // * Size check
  if(size > 2097152)
    return { status: false, message: "Слишком большой файл!" };

  return { status: true, message: "Фотография обновлена!" };
};