import {
  FC,
  PropsWithChildren,
  createContext,
  useEffect,
  useState,
} from "react";
import { ISocketIndentifier, ISocketContext } from "./SocketProvider.interface";
import { useGetUserQuery } from "../../features/api/apiSlice";

const socketUrl = String(process.env.REACT_APP_API_ENDPOINT).replace("https://","");

export const SocketContext = createContext<ISocketContext>({
  webSocket: null,
  connectToChatSocket: () => {},
});

export const SocketProvider: FC<PropsWithChildren> = ({ children }) => {
  const { data: user } = useGetUserQuery();

  const [webSocket, setWebSocket] = useState<ISocketContext["webSocket"]>(null);
  const [chatSocketIdentifier, setChatSocketIdentifier] = useState("");

  const getSerializedSocketIdentifier = ({
    command,
    identifier,
  }: ISocketIndentifier) => {
    return JSON.stringify({
      command,
      identifier: JSON.stringify(identifier),
    });
  };

  const connectToChatSocket = (broadcastId: number) => {
    if (!webSocket || webSocket.readyState !== webSocket.OPEN) return;

    const identifier: ISocketIndentifier["identifier"] = {
      channel: "ChatChannel",
      room: `Room_${broadcastId}`,
      broadcast_id: broadcastId,
    };

    webSocket.send(
      getSerializedSocketIdentifier({
        command: "subscribe",
        identifier,
      })
    );

    setChatSocketIdentifier(JSON.stringify(identifier));
  };

  useEffect(() => {
    if (!user || webSocket) return;

    const socket = new WebSocket(`wss://${socketUrl}/websocket`);
    setWebSocket(socket);

    const handleSocketOpen = () => {
      socket.send(
        getSerializedSocketIdentifier({
          command: "subscribe",
          identifier: {
            channel: "BroadcastChannel",
            room: `User_${user.user_id}`,
            user_id: user.user_id,
          },
        })
      );
    };

    // Комната трансляций может в любой момент отдать событие
    // поэтому сразу подключаемся к ней
    socket.addEventListener("open", handleSocketOpen);

    return () => {
      socket.close();
      socket.removeEventListener("open", handleSocketOpen);
    };
  }, [user]);

  return (
    <SocketContext.Provider
      value={{
        webSocket,
        chatSocketIdentifier,
        connectToChatSocket,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;
