import { ComponentProps } from "react";
import cn from "classnames";
import calendarImg from "./img/calendar.svg";
import tariffImg from "./img/tariff.svg";
import s from "./Actions.module.css";
import { ButtonWithImage } from "../ButtonWithImage";
import { ButtonGroup } from "../ButtonGroup";
import { useNavigate } from "react-router-dom";

type Props = ComponentProps<'div'> & {
    onCreateBroadcast: () => void;
};

export function Actions({ className, onCreateBroadcast }: Props) {
    const navigate = useNavigate();
    return (
        <div className={cn(s['actions'], className)}>
            <div className={s['actions__title']}>Действия</div>
            <div className={s.actions__buttons}>
                <ButtonGroup>
                    <ButtonWithImage
                        imgSrc={calendarImg}
                        alt="Calendar"
                        text="Запланировать эфир"
                        className={s['button-with-image']}
                        onClick={onCreateBroadcast}
                    />
                    <ButtonWithImage
                        imgSrc={tariffImg}
                        alt="Tariff"
                        text="Мой тариф"
                        className={s['button-with-image']}
                        onClick={() => navigate('/tariffs')}
                    />
                </ButtonGroup>
            </div>
        </div>
    );
}