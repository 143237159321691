import cn from "classnames";
import React from "react";
import { useScreenWidth } from "../../../../hooks/useScreenWidth";
import Icon from "../../../Icon";

import { ButtonScrollProps } from './ButtonScroll.interface';
import styles from './ButtonScroll.module.css';

const ButtonScroll: React.FC<ButtonScrollProps> = ({ onClick }) => {
  const { isMobile } = useScreenWidth();

  return (
    <button
      className={cn(
        styles.button,
        {
          [styles['button_prevented-pointer']]: isMobile
        }
      )}
      onClick={onClick}
      type="button"
    >
      <Icon name="double-arrow"/>
    </button>
  );
};

export default ButtonScroll;