import { useState, useCallback } from "react";

// * Icons
import clock from "./assets/clock.svg";

// * Components
import Layout from "../../components/Layout";
import { Actions } from "../../components/Actions";
import { Broadcasts } from "../../components/Broadcasts";
import { Greeting } from "../../components/Greeting";
import GreetingImage from "../../components/Greeting/GreetingImage";
import { CreateBroadcastPopup } from "../../components/CreateBroadcastPopup";
import { StartBroadcasting } from "../../components/StartBroadcasting";
import { ButtonWithImage } from "../../components/ButtonWithImage";

// * Styles
import s from "./Dashboard.module.css";

// * Hooks
import { useScreenWidth } from "../../hooks/useScreenWidth";

// * Helpers
import classNames from "classnames";

export function Dashboard() {
    const { isMobile } = useScreenWidth();
    const [ isStartBroadcastingError, setIsStartBroadcastingError ] = useState(false);
    const [ isShowCreateBroadcastPopup, setIsShowCreateBroadcastPopup ] = useState(false);
    const handleStartBroadcastingError = useCallback((error: string) => setIsStartBroadcastingError(Boolean(error)), []);
    const handleCreateBroadcast = useCallback(() => setIsShowCreateBroadcastPopup(true), []);
    return (
        <Layout isBackBtn={false}>
            <div className={classNames(
                    s['dashboard'],
                    { [s['dashboard_broadcasting-error']]: isStartBroadcastingError }
                )}
            >
                { isMobile && <GreetingImage />}

                <Greeting />
                <StartBroadcasting onError={handleStartBroadcastingError} />

                { isMobile &&
                    <ButtonWithImage
                        className={s["stream-planning"]}
                        imgSrc={clock}
                        text="Запланировать эфир"
                        onClick={() => setIsShowCreateBroadcastPopup(true)}
                    />
                }

                { !isMobile && <Broadcasts onCreateBroadcast={handleCreateBroadcast} /> }
                { !isMobile && <Actions className={s['dashboard__actions']} onCreateBroadcast={handleCreateBroadcast} /> }

            </div>

            {isShowCreateBroadcastPopup && (
                <CreateBroadcastPopup onClose={() => setIsShowCreateBroadcastPopup(false)} />
            )}
        </Layout>
    );
}