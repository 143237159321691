import { FC, useEffect, useRef, useState } from "react";

// * Components
import Icon from "../../Icon";
import NotificationsList from "./NotificationsList";
import { CSSTransition } from "react-transition-group";

// * Hooks
import { useClickOutside } from "../../../hooks/useClickOutside";
import { useScreenWidth } from "../../../hooks/useScreenWidth";
import { useGetNotificationsQuery } from "../../../features/api/apiSlice";

// * Helpers
import cn from "classnames";

//* Styles
import styles from "./HeaderNotifications.module.css";

const HeaderNotifications: FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const notificationsRef = useRef<HTMLDivElement>(null);

  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const { isMobile } = useScreenWidth();

  const { data: notifications } = useGetNotificationsQuery(
    {
      isUnread: false
    },
    {
      pollingInterval: Number(process.env.REACT_APP_NOTIFY_POLLING_INTERVAL)
    }
  );

  const toggleNotificationsVisibility = () => {
    setIsNotificationsOpen((isNotificationsOpen) => !isNotificationsOpen);
  };

  const handleNotificationsClose = () => {
    setIsNotificationsOpen(false);
  };

  useClickOutside(containerRef, () => {
    handleNotificationsClose();
  });

  useEffect(() => {
    if (!isMobile) return;

    if (isNotificationsOpen) {
      document.body.classList.add("disable-scroll");
    } else {
      document.body.classList.remove("disable-scroll");
    }

  }, [isNotificationsOpen]);

  return (
    <div ref={containerRef} className={styles.notifications}>
      <div
        className={cn(styles.notifications__icon, {
          [styles.notifications__icon_active]: isNotificationsOpen,
        })}
        onClick={() => toggleNotificationsVisibility()}
      >
        {notifications?.any_unread &&
          <div className={styles["notifications__icon-point"]}></div>
        }
        <Icon name="notifications" />
      </div>

      <CSSTransition
        nodeRef={notificationsRef}
        in={isNotificationsOpen}
        timeout={200}
        unmountOnExit
        classNames="fade-in"
      >
        <div ref={notificationsRef} className={styles.notifications__list}>
          <NotificationsList handleNotificationsClose={handleNotificationsClose} />
        </div>
      </CSSTransition>
    </div>
  );
};

export default HeaderNotifications;
