import cn from "classnames";
import Icon from "../Icon";
import { PopupTitleProps } from "./PopupTitle.interface";
import { useScreenWidth } from "../../hooks/useScreenWidth";
import s from "./PopupTitle.module.css";

export function PopupTitle({
    clarity,
    onClose,
    className,
    children,
    ...restProps
}: PopupTitleProps) {
    const { isMobile } = useScreenWidth();

    return (
        <div {...restProps} className={cn(s['popup__title'], className)}>
            <div className={s['popup__title-content']}>
                {children}

                {clarity && <span className={s['popup__title-clarity']}>{clarity}</span>}
            </div>

            {(onClose && isMobile) && (
                <button
                    className={s['popup__title-close']}
                    onClick={onClose}
                >
                    <Icon name="close"/>
                </button>
            )}
        </div>
    );
}