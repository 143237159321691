import classNames from "classnames";
import { ComponentProps } from "react";
import { Logo } from "../Logo";
import s from "./FormWithLogo.module.css";

type Props = ComponentProps<'form'> & {
    legendSmallIndent?: boolean;
};

export function FormWithLogo({ children, className, legendSmallIndent = false, ...restProps }: Props) {
    const formClassName = classNames(className, s['form']);
    return (
        <form {...restProps} className={formClassName} noValidate={true}>
            <fieldset className={s['form__fieldset']}>
                <legend className={`${s['form__legend']} ${legendSmallIndent ? s['form__legend_small-indent'] : ''}`}>
                    <Logo />
                </legend>
                {children}
            </fieldset>
        </form>
    );
}