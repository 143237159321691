import { FC, useState } from "react";

// * Icons
import copy from "./assets/copy.svg";
import done from "./assets/done.svg";
import bank from "./assets/bank.png";

// * Hooks
import { copyText } from "../../../helpers/copyText";

// * Props
import { HeroProps } from "./Hero.interface";

// * Helpers
import cn from "classnames";

//* Styles
import styles from './Hero.module.css';

import { delayAfterSuccessfulCopy } from "../../../constants";

const Hero: FC<HeroProps> = ({ referralUrl }) => {
  const [ isCopy, setIsCopy ] = useState(false);

  const handleCopy = () => {
    copyText(referralUrl);
    setIsCopy(true);
    setTimeout(() => {
      setIsCopy(false);
    }, delayAfterSuccessfulCopy);
  };

  return (
    <section className={styles.hero}>
      <div className={styles.hero__wrapper}>
        <div className={styles.hero__left}>
          <h1>Получите 30% баллами с каждой оплаты по вашей ссылке!</h1>
          <p>Вы будете получать бонусы с каждого приглашенного пользователя, оформившего подписку</p>

          <div className={styles.hero__copy}>
            <p>{referralUrl}</p>

            <div
              className={cn(styles["hero__copy-btn"], {[styles["hero__copy-btn_copied"]]: isCopy})}
              onClick={() => handleCopy()}
            >
              <img src={isCopy ? done : copy} alt="copy" />
            </div>
          </div>
        </div>

        <div className={styles.hero__right}>
          <img src={bank} alt="bank" />
        </div>
      </div>
    </section>
  );
};

export default Hero;