import { FC } from "react";

// * Icons
import sprite from "./img/sprite.svg";

// * Props
import { IconsProps } from "./Icon.interface";

export const Icon: FC<IconsProps> = ({ name }) => {
	return (
		<svg width="100%" height="100%">
			<use href={sprite + `#${name}`} />
		</svg>
	);
};

export default Icon;