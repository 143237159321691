import { FC, useEffect } from "react";

// * Components
import ChatWidget from "../../components/ChatWidget";

// * Helpers
import { getRouteParams } from "../../helpers/getRouteParams";

// * Hooks
import { useChat } from "../../hooks/useChat";

// * Styles
import styles from "./Widget.module.css";

const Widget: FC = () => {
  const widgetParams = getRouteParams(window.location.href);

  const { messages } = useChat({
    broadcastId: Number(widgetParams.id),
    chatToken: widgetParams.chat_token
  });

  useEffect(() => {
    const root = document.querySelector("#root") as HTMLElement;
    root.style.background = "transparent";
  }, []);

  return (
    <div className={styles.widget}>
      <ChatWidget
        messages={messages}
        scale={widgetParams.scale}
        nameColor={`#${widgetParams.name_color}`}
        messageColor={`#${widgetParams.message_color}`}
        backgroundColor={`#${widgetParams.background_color}`}
      />
    </div>
  );
};

export default Widget;
