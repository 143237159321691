import { FC } from 'react';

// * Components
import PlatformIcon from '../../PlatformIcon';

// * Props
import { WidgetMessageProps } from './WidgetMessage.interface';

// * Styles
import styles from "./WidgetMessage.module.css";

const WidgetMessage: FC<WidgetMessageProps> = ({ message, nameColor, messageColor, scale }) => {

  // * Styles
  const platformStyle = { maxWidth: `${(23 * Number(scale)) / 100}px`, height: `${(23 * Number(scale)) / 100}px` };
  const contentStyle = { fontSize: `${(13 * Number(scale)) / 100}px`, lineHeight: `${(13 * Number(scale)) / 100}px`};
  const usernameStyle = { color: nameColor };
  const messageStyle = { color: messageColor };

  // * Message data
  const { user, platform, message: text } = message;

  return (
    <div className={styles.message}>
      <div style={platformStyle} className={styles.message__platform}>
        <PlatformIcon platform={platform} />
      </div>
      <p style={contentStyle} className={styles.message__content}>
        <span style={usernameStyle} className={styles.message__username}>{user}</span>
        <span style={messageStyle} className={styles.message__text}>{text}</span>
      </p>
    </div>
  );
};

export default WidgetMessage;