import { useCallback, useEffect, useRef, useState } from "react";

interface UseScrollButtonParams {
  appearsAfterScrolledPixels: number;
}

type UseScrollButtonResult = [
  windowRef: any,
  isVisible: boolean,
  scrollWindow: (behavior: 'smooth' | 'auto') => any
]

export const useScrollButton = ({
  appearsAfterScrolledPixels
}: UseScrollButtonParams): UseScrollButtonResult => {
  const windowRef = useRef<HTMLElement | null>(null);
  const [ isVisible, setVisible ] = useState(false);

  const changeVisibility = () => {
    const windowElement = windowRef.current;

    if (windowElement) {
      const scrolledFromBottom = windowElement.scrollHeight - (windowElement.scrollTop + windowElement.offsetHeight);

      if (scrolledFromBottom > appearsAfterScrolledPixels) {
        setVisible(true);
      }
      else {
        setVisible(false);
      }
    }
  };

  const scrollWindow = useCallback(
    (behavior: 'smooth' | 'auto' = 'smooth') => {
      if (windowRef.current) {
        windowRef.current.scrollTo({
          top: windowRef.current.scrollHeight,
          behavior
        });

        setVisible(false);
      }
    },
    [windowRef.current]
  );

  useEffect(() => {
    const windowElement = windowRef?.current;

    if (windowElement) {
      changeVisibility();

      windowElement.addEventListener('scroll', changeVisibility);
      windowElement.addEventListener('touchmove', changeVisibility);

      return () => {
        windowElement.removeEventListener('scroll', changeVisibility);
        windowElement.removeEventListener('touchmove', changeVisibility);
      };
    }

  }, [windowRef.current]);

  return [ windowRef, isVisible, scrollWindow ];
};