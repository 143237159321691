import { FC } from "react";

// * Icons
import PlatformIcon from "../../PlatformIcon";

//* Props
import { PlatformInstructionProps } from "./PlatformInstruction.interface";

//* Styles
import styles from "./PlatformInstruction.module.css";

const PlatformInstruction: FC<PlatformInstructionProps> = ({
  selectedPlatform,
}) => {
  return (
    <div className={styles.instruction}>
      <div className={styles.instruction__icon}>
        {selectedPlatform.platform !== "other" &&
          <PlatformIcon platform={selectedPlatform.platform} />
        }
      </div>

      <p className={styles.instruction__text}>
        Прочтите инструкцию по подключению к сервису
        <a
          href={selectedPlatform.instructionLink}
          className={styles.instruction__link}
          target="_blank"
          rel="noreferrer"
        >
          здесь
        </a>
      </p>
    </div>
  );
};

export default PlatformInstruction;
