import { FC, PropsWithChildren, useRef } from 'react';

// * Hooks
import { useTooltip } from './hooks/useTooltip';

// * Props
import { TooltipProps } from './Tooltip.interface';

// * Helpers
import cn from "classnames";

// * Styles
import styles from "./Tooltip.module.css";


const Tooltip: FC<PropsWithChildren<TooltipProps>> = ({ children, text, position = "top-right" }) => {
  const tooltipRef = useRef<HTMLDivElement>(null);

  const { handleMouseEnter, handleMouseLeave } = useTooltip(tooltipRef);

  return (
    <div
      className={styles.tooltip}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >

      <div ref={tooltipRef} className={cn(styles.tooltip__wrapper, styles[position])}>
        <p className={styles.tooltip__text}>{text}</p>
      </div>

      { children }
    </div>
  );
};

export default Tooltip;