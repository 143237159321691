import React from "react";
import { Link } from "react-router-dom";

import { FormWithLogo } from "../../../components/FormWithLogo";
import { Button } from "../../../components/Button";

import { EmailSentSuccessProps } from './EmailSentSuccess.interface';
import s from './EmailSentSuccess.module.css';

const EmailSentSuccess: React.FC<EmailSentSuccessProps> = ({ toEmail }) => {
  return (
    <FormWithLogo className={s['form']}>
        <h1 className={s['heading']}>
            Подтвержение почты
        </h1>

        <div className={s['verbose']}>
            <p className={s['verbose__main']}>
                На вашу почту <strong> {toEmail} </strong> было отправлено письмо, с ссылкой для подтверждения аккаунта.
            </p>

            <p className={s['verbose__hint']}>
                Если Вы не получили письмо, проверьте папку “Спам” или попробуйте еще раз.
            </p>
        </div>

        <Link to='/login'>
            <Button type="submit" kind="primary"> Войти в аккаунт </Button>
        </Link>
    </FormWithLogo>
  );
};

export default EmailSentSuccess;