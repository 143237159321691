import React from "react";
import classNames from "classnames";

import { TableProps } from './Table.interface';
import styles from './Table.module.css';
import { useScreenWidth } from "../../../hooks/useScreenWidth";
import Icon from "../../../components/Icon";

const Table: React.FC<TableProps> = ({columns, transactions}) => {
  const { isMobile } = useScreenWidth();

  return (
    <div className={styles.table}>
      { !isMobile &&
        <div
          className={classNames(styles.table__row, styles.table__row_thead)}
          style={{ gridTemplateColumns: `repeat(${columns.length}, 1fr)` }}
        >
          {columns.map(column => (
            <span key={column.name}>{column.name}</span>
          ))}
        </div>
      }

      {transactions.map(transaction => (
        <div
          key={transaction.id}
          className={classNames(
            styles.table__row,
            {[styles.table__row_aborted]: transaction.status !== 'completed'}
          )}
          style={{ gridTemplateColumns: `repeat(${isMobile ? 2 : columns.length}, 1fr)` }}
        >
          {columns.map(column => {
            const columnValue = transaction[column.key]
              ? column.transformValue
                ? column.transformValue(transaction[column.key])
                : transaction[column.key]
              : null;

            return (
              <React.Fragment>
                <span
                  key={column.name}
                  className={styles.table__row_td}
                >
                  { isMobile
                    ? (
                      <React.Fragment>
                        <span>{column.name}:</span>
                        <span>{columnValue}
                      </span> </React.Fragment>
                    )
                    : columnValue
                  }
                </span>

                {transaction.status !== 'completed' && (
                  <div className={styles['table__row-aborted']}>
                    <div className={styles['table__row-aborted-icon']}>
                      <Icon name="denied"/>
                    </div>
                    <p className={styles['table__row-aborted-text']}>Отменен пользователем</p>
                  </div>
                )}
              </React.Fragment>
            );
          })
          }
        </div>
      ))}
    </div>
  );
};

export default Table;