
import React from 'react';
import cn from 'classnames';
import s from './YoutubeLatency.module.css';
import { ChannelLatencyType } from "../../../../features/api/apiSlice";
import { IYoutubeChannelLatencies } from "../../../EditPlatformPopup/EditPlatformPopup.data";

export interface YoutubeLatencySelectorProps {
  selectedLatency: ChannelLatencyType;
  handleLatencyChange: (value: ChannelLatencyType) => void;
}

const YoutubeLatencySelector: React.FC<YoutubeLatencySelectorProps> = ({ selectedLatency, handleLatencyChange }) => {
  return (
    <div className={s.form__section}>
      <h6 className={s.form__heading}>Уровень задержки</h6>
      <div className={s["form__buttons-container"]}>
        {IYoutubeChannelLatencies.map(({ value, label }) => (
          <div className={cn(s.form__latency)} key={value}>
            <div
              className={cn(s["form__latency-button"], {
                [s["form__latency-button_active"]]: selectedLatency === value
              })}
              onClick={() => handleLatencyChange(value)}
            >
              {label}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default YoutubeLatencySelector;
