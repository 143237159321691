import ReactDatePicker, { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import "react-datepicker/dist/react-datepicker.css";
import "./react-datepicker.custom.css";
import s from "./DatePicker.module.css";

registerLocale('ru', ru);

type Props = {
    selected: Date | null | undefined;
    onChange: (date: Date | null, event: React.SyntheticEvent<any, Event> | undefined) => void;
}

export function DatePicker({ selected, onChange }: Props) {
    return (
        <div className={s['container']}>
            <ReactDatePicker
                selected={selected}
                onChange={onChange}
                dateFormat="dd/MM/yyyy"
                locale="ru"
            />
        </div>
    );
}