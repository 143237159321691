import cn from "classnames";
import { Link as LocalLink, LinkProps } from "react-router-dom";
import s from "./Link.module.css";

type Props = LinkProps & React.RefAttributes<HTMLAnchorElement> & {
    isLocal?: boolean;
};

export function Link({ isLocal = true, className, ...restProps }: Props) {
    const linkClassName = cn(className, s['link']);
    if (isLocal) {
        return <LocalLink {...restProps} className={linkClassName} tabIndex={-1} />;
    }
    const {to, children, ...otherProps} = restProps;
    const href = typeof to === 'string' ? to : `${to.pathname}${to.hash}${to.search}`;
    return (
        <a
            {...otherProps}
            className={linkClassName}
            href={href}
            target="_blank"
            rel="noreferrer"
            tabIndex={-1}
        >
            {children}
        </a>
    );
}