import { useSearchParams } from "react-router-dom";

export const useReferralToken = () => {
  const [searchParams] = useSearchParams();

  const tokenFromUrl = searchParams.get(process.env.REACT_APP_REF_TOKEN_KEY as string);
  const tokenFromStorage = localStorage.getItem(process.env.REACT_APP_REF_TOKEN_KEY as string);

  const removeTokenFromStorage = () => localStorage.removeItem(process.env.REACT_APP_REF_TOKEN_KEY as string);
  const saveTokenInStorage = (token: string) => localStorage.setItem(process.env.REACT_APP_REF_TOKEN_KEY as string, token);

  return {
    tokenFromUrl,
    tokenFromStorage,
    removeTokenFromStorage,
    saveTokenInStorage,
  };
};