import React from "react";

import Message from '../Message';

import { MessageTechnicalProps } from './MessageTechnical.interface';

const MessageTechnical: React.FC<MessageTechnicalProps> = ({ content }) => {
  return (
    <Message
      message={{
        user: 'Restream.su',
        platform: undefined
      }}
      content={content}
      isTechnical
    />
  );
};

export default MessageTechnical;