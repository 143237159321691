import { FC, PropsWithChildren, useState } from 'react';

// * Components
import Icon from '../Icon';

// * Props
import { AccordionProps } from './Accordion.interface';

// * Helpers
import cn from "classnames";

// * Styles
import styles from "./Accordion.module.css";

const Accordion: FC<PropsWithChildren<AccordionProps>> = ({ open, title, children }) => {
  const [isActive, setIsActive] = useState(open || false);

  const arrowClass = cn(styles.accordion__title__arrow, { [styles.active]: isActive });

  return (
    <div className={styles.accordion}>
      <div className={styles.accordion__title} onClick={() => setIsActive(!isActive)}>
        { title }

        <div className={arrowClass}>
          <Icon name="accordion-arrow" />
        </div>
      </div>

      { isActive && children }
    </div>
  );
};

export default Accordion;