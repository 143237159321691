import { FC } from "react";

// * Icons
import bag from "./assets/bag.png";
import coin from "./assets/coin.png";

// * Components
import Layout from "../../components/Layout";
import Hero from "../../components/Referral/Hero";
import Steps from "../../components/Referral/Steps";
import Statistics from "../../components/Referral/Statistics";
import InfoCard from "../../components/Referral/InfoCard";

// * Hooks
import { useGetUserQuery } from "../../features/api/apiSlice";

//* Styles
import styles from "./Referral.module.css";

const Referral: FC = () => {
  //* User
  const { data: user } = useGetUserQuery("");

  return (
    <Layout>
      <div className={styles.referral}>
        <Hero referralUrl={user?.ref_url} />

        <main className={styles.referral__main}>
          <section>
            <h5 className={styles.referral__title}>
              Как работает реферальная система?
            </h5>

            <Steps />
          </section>

          <section>
            <h5 className={styles.referral__title}>Ваша статистика</h5>

            <Statistics
              ref_point={user?.ref_points}
              referrals_count={user?.referrals_count}
              spend_ref_points={user?.spend_ref_points}
            />

            <div className={styles.referral__info}>
              <InfoCard
                title="Почему это выгодно?"
                text="Каждый приглашенный, оформивший подписку, поможет вам экономить на своей. А вы, разместив ссылку, поможете совершить легкий старт новым пользователям:)"
                image={bag}
              />

              <InfoCard
                title="1 балл = 1 рубль"
                text="Конвертация построена таким образом, что использование определенного количества баллов равносильно использованию того же количества рублей"
                image={coin}
              />
            </div>
          </section>
        </main>
      </div>
    </Layout>
  );
};

export default Referral;
