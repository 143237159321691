import React from 'react';
import s from "./ClientAreement.module.css";
import { Button } from '../../Button';
import { ButtonGroup } from '../../ButtonGroup';
import { PopupTitle } from '../../PopupTitle';


interface Props {
  onConfirm: () => void;
  onClose: () => void;
}

const ClientAgreementModal: React.FC<Props> = ({ onConfirm, onClose }) => {
  return (
    <div className={s["modal-overlay"]}>
      <div className={s["modal-content"]}>
        <div className={s["title-container"]}>
          <PopupTitle>Добавить канал</PopupTitle>
        </div>
        <div className={s["text-container"]}>
          <p className={s["text-center"]}>
            Подключая свой канал YouTube, вы соглашаетесь с
            <a href="https://policies.google.com/privacy" target="_blank" className={s["link-style"]}>
              Политикой конфиденциальности Google
            </a>
            <a href="https://www.youtube.com/t/terms" target="_blank" className={s["link-style"]}>
              Условиями использования YouTube
            </a>
          </p>
        </div>
        <div className={s["button-container"]}>
          <ButtonGroup>
            <Button onClick={onClose} kind={'secondary'}>Отмена</Button>
            <Button onClick={onConfirm} kind={'primary'}>Соглашаюсь</Button>
          </ButtonGroup>
        </div>
      </div>
    </div>
  );
};

export default ClientAgreementModal;
