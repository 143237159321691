import { FC } from "react";
import { clearNotifyArray } from "../../../../features/notifySlice";

// * Components
import Icon from "../../../Icon";

// * Hooks
import { useAppDispatch } from "../../../../app/hooks";
import { useNavigate } from "react-router-dom";
import { useGetUserQuery, useLogoutMutation } from "../../../../features/api/apiSlice";

// * Menu items
import { items } from "./Menu.data";

//* Styles
import styles from './Menu.module.css';

const Menu: FC = () => {
  const dispatch = useAppDispatch();

  // * Router
  const navigate = useNavigate();

  // * User
  const { data: user } = useGetUserQuery();

  // * Hooks
  const [ logout ] = useLogoutMutation();

  const handleLogout = () => {
    logout();
    dispatch(clearNotifyArray());
  };

  return (
    <div className={styles.menu}>
      <div className={styles.menu__btn}>
        <Icon name="coin"/>
        <h6>{user?.ref_points || 0} баллов</h6>
      </div>

      { items.map((item) =>
        <div className={styles.menu__btn} key={item.caption} onClick={() => navigate(item.url)}>
          <Icon name={item.icon} />
          <h6>{item.caption}</h6>
        </div>
      )}

      <div className={styles.menu__btn} onClick={() => handleLogout()}>
        <Icon name="logout-menu"/>
        <h6>Выход из аккаунта</h6>
      </div>
    </div>
  );
};

export default Menu;