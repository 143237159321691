import { FC, useRef, FormEvent, useState } from 'react';

// * Components
import { InputField } from '../../../InputField';
import { PasswordField } from '../../../PasswordField';
import { Button } from '../../../Button';

// * Props
import { EmailFormProps } from './EmailForm.interface';

// * Hooks
import { useChangeEmailMutation } from '../../../../features/api/apiSlice';
import { useScreenWidth } from '../../../../hooks/useScreenWidth';

// * Helpers
import { checkEmail } from '../../../../helpers/checkEmail';

// * Styles
import styles from "../Forms.module.css";
import { checkPassword } from '../../../../helpers/checkPassword';

const EmailForm: FC<EmailFormProps> = ({ user, setShowPopup }) => {
  const { isMobile } = useScreenWidth();

  const [ newEmailError, setNewEmailError ] = useState("");
  const [ passwordError, setPasswordError ] = useState("");

  const [ isResetChangeEmail, setIsResetChangeEmail ] = useState(false);
  const [ isResetChangeEmailPassword, setIsResetChangeEmailPassword ] = useState(false);

  // * Template refs
  const changeEmailRef = useRef<HTMLInputElement>(null);
  const changeEmailPasswordRef = useRef<HTMLInputElement>(null);

  // * Hooks
  const [ changeEmail, { isLoading } ] = useChangeEmailMutation();

  const handleChangeEmail = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!changeEmailRef.current || !changeEmailPasswordRef.current) return;

    const emailValue = changeEmailRef.current.value;
    const passwordValue = changeEmailPasswordRef.current.value;

    const isEmailValid = checkEmail(emailValue);
    const isPasswordValid = checkPassword(passwordValue);

    if(emailValue === user?.user_email) {
        return setNewEmailError("Новый email не должен совпадать с текущим!");
    }
    if (!isEmailValid) {
        return setNewEmailError("Не правильный email!");
    }
    if(!isPasswordValid) {
        return setPasswordError("Не правильный пароль!");
    }

    setNewEmailError("");
    setPasswordError("");

    changeEmail({
        userId: user?.user_id,
        email: emailValue,
        password: changeEmailPasswordRef.current?.value ?? ''
    })
      .unwrap()
      .then(() => {
        setShowPopup(true);
        setIsResetChangeEmail(true);
        setIsResetChangeEmailPassword(true);
      })
      .catch((e) => {
        setPasswordError(e.data.message);
      });
  };

  const handleChangeEmailInput = () => {
    if (isResetChangeEmail) {
        setIsResetChangeEmail(false);
    }
    if (isResetChangeEmailPassword) {
        setIsResetChangeEmailPassword(false);
    }
  };


  return (
    <form className={styles['form']} onSubmit={handleChangeEmail} onInput={handleChangeEmailInput}>

      { !isMobile &&
        <div className={styles['form__fieldset']}>
          <span className={styles['form__field-title']}>E-mail</span>
          <span className={styles['form__current-email']}>{user?.user_email}</span>
        </div>
      }

      <fieldset className={styles['form__fieldset']}>
        <span className={styles['form__field-title']}>Новый e-mail</span>
        <InputField
          isError={Boolean(newEmailError)}
          ref={changeEmailRef}
          isReset={isResetChangeEmail}
          type="email"
          autoComplete="new-off"
          error={newEmailError}
          onInput={() => {
            if (newEmailError) {
              setNewEmailError("");
            }
          }}
        />
      </fieldset>

      <fieldset className={styles['form__fieldset']}>
        <span className={styles['form__field-title']}>Ваш пароль</span>
        <PasswordField
          isError={Boolean(passwordError)}
          ref={changeEmailPasswordRef}
          isReset={isResetChangeEmailPassword}
          autoComplete="new-off"
          error={passwordError}
          icon='toggle'
          onInput={() => {
            if (passwordError) {
              setPasswordError("");
            }
          }}
        />
      </fieldset>

      <Button kind="primary" className={styles['form__full-width']}>
        {isLoading ? "Загрузка..." : "Изменить e-mail"}
      </Button>
    </form>
  );
};

export default EmailForm;