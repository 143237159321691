import { FC } from "react";

// * Components
import Icon from "../../Icon";

// * Data
import { socials } from "./HeaderSocials.data";

//* Styles
import styles from './HeaderSocials.module.css';

const HeaderSocials: FC = () => {
  return (
    <div className={styles.socials}>
      {socials.map((social) => (
        <a
          href={social.link}
          key={social.link}
          target="_BLANK"
          rel="noreferrer"
        >
          <Icon name={social.icon} />
        </a>
      ))}
    </div>
  );
};

export default HeaderSocials;
