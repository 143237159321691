import React from "react";

// * Icons
import notFound from "./icons/not-found.svg";

// * Components
import { Link } from "react-router-dom";
import { Button } from "../../components/Button";

// * Props
import { ErrorProps } from "./Error.interface";

// * Styles
import styles from './Error.module.css';

const Error: React.FC<ErrorProps> = ({ icon = notFound, message, description }) => {
  return (
    <div className={styles.layout}>
      <div className={styles.error}>
        <img src={icon} className={styles.error__icon} />

        <div className={styles.error__text}>
          <span className={styles.error__text_message}>{message}</span>
          <span className={styles.error__text_description}>{description}</span>
        </div>

        <Link className={styles.error__link} to="/">
          <Button kind="primary">Домой</Button>
        </Link>
      </div>
    </div>
  );
};

export default Error;