function getSplitter(value: string, splitter: string) {
    return value ? splitter : '';
}

export function formatInputDate(input: string, splitter: string = '/') {
    const clearInput = input.replace(/\D/g, '');
    const date = clearInput.slice(0, 2);
    const month = clearInput.slice(2, 4);
    const year = clearInput.slice(4, 8);
    return `${date}${getSplitter(month, splitter)}${month}${getSplitter(year, splitter)}${year}`;
}

export function formatInputTime(input: string, splitter: string = ':') {
    const clearInput = input.replace(/\D/g, '');
    const hour = clearInput.slice(0, 2);
    const minutes = clearInput.slice(2, 4);
    return `${hour}${getSplitter(minutes, splitter)}${minutes}`;
}

export function formatLength(input: string, length = 50) {
    return input.slice(0, length);
}