import { FormEvent, useCallback, useRef, useState } from "react";
import { useNavigate } from "react-router";
import cn from "classnames";
import { useCreateBroadcastMutation } from "../../features/api/apiSlice";
import { Popup } from "../Popup";
import { PopupTitle } from "../PopupTitle";
import s from "./CreateBroadcastPopup.module.css";
import { ButtonGroup } from "../ButtonGroup";
import { Button } from "../Button";
import { Error } from "../Error";
import { DatePicker } from "../DatePicker";
import { TimePicker } from "../TimePicker";
import TooltipButton from "../TooltipButton";
import PopupStructure from "../PopupStructure";
import InputLegend from "../InputLegend";
import { useScreenWidth } from "../../hooks/useScreenWidth";
import TextAreaField from "../TextAreaField";

type Props = {
    onClose: () => void;
};

export function CreateBroadcastPopup({ onClose }: Props) {
    const { isMobile } = useScreenWidth();
    const [ date, setDate ] = useState(new Date());
    const [createBroadcast] = useCreateBroadcastMutation();
    const [createBroadcastError, setCreateBroadcastError] = useState('');
    const [createErrors, setCreateErrors] = useState('');
    const nameRef = useRef<HTMLTextAreaElement>(null);
    const descRef = useRef<HTMLTextAreaElement>(null);
    const navigate = useNavigate();
    const handleClosePopup = useCallback(() => {
        setCreateBroadcastError('');
        setCreateErrors('');
        onClose();
    }, []);

    const broadcastNameText = "Заполните Название и Описание для эфира, и эти данные присвоятся автоматически для интегрированных каналов (YouTube, Twitch, Trovo, ВКонтакте). Так же вы можете изменить Название и Описание для конкретного канала";
    const broadcastDateText = "Внимание! Если вы запланировали эфир и запланированное время ещё не наступило, эфир не запустится даже при наличии сигнала";

    function handleSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (nameRef.current === null || descRef.current === null) {
            return;
        }
        const nameValue = nameRef.current.value;
        const descValue = descRef.current.value;
        const isNameEmpty = nameValue === '';
        const isError = isNameEmpty;
        setCreateErrors(isNameEmpty ? 'Name is empty' : '');
        if (isError) {
            return setCreateBroadcastError('Заполните обязательные поля');
        }
        createBroadcast({
            name: nameValue,
            description: descValue,
            start_at: date.toISOString()
        });
        if(isMobile) {
            navigate("/broadcasts");
        }
        handleClosePopup();
    }
    return (
        <Popup isShow={true} onClose={handleClosePopup} maxWidth="530px">
            <div className={cn(s['broadcast-edit'])}>
                <PopupStructure
                    headerComponent={
                        <PopupTitle>
                            Планирование эфира
                            {!isMobile &&
                                <TooltipButton
                                    position="bottom-center"
                                    text="Запланированный Эфир начнется согласно выставленной Дате и Времени."
                                />
                            }
                        </PopupTitle>
                    }
                    contentComponent={
                        <form id="broadcast-form" className={s['broadcast-edit__form']} onSubmit={handleSubmit}>
                            <InputLegend legend={<>Название эфира {!isMobile && <TooltipButton position="bottom-right" text={broadcastNameText}/>}</>}>
                                <TextAreaField
                                    isError={Boolean(createErrors)}
                                    placeholder="Название эфира*"
                                    maxLength={100}
                                    type="text"
                                    ref={nameRef}
                                />
                            </InputLegend>

                            <InputLegend legend={<>Описание эфира {!isMobile && <TooltipButton text={broadcastNameText}/>}</>}>
                                <TextAreaField
                                    placeholder="Описание эфира"
                                    maxLength={5000}
                                    type="text"
                                    ref={descRef}
                                />
                            </InputLegend>

                            <div className={s["broadcast-edit__field-section"]}>
                                <InputLegend legend={<>Дата начала {!isMobile && <TooltipButton text={broadcastDateText}/>}</>}>
                                    <DatePicker
                                        selected={date}
                                        onChange={(date) => setDate(date!)}
                                    />
                                </InputLegend>

                                <InputLegend legend={<>Время начала {!isMobile && <TooltipButton position="top-left" text={broadcastDateText}/>}</>}>
                                    <TimePicker
                                        value={date}
                                        onChange={(value) => {
                                            const [ hour, minutes ] = value ? (value as string).split(':') : ["00", "00"];
                                            date.setHours(parseInt(hour, 10));
                                            date.setMinutes(parseInt(minutes, 10));
                                            setDate(date);
                                        }}
                                    />
                                </InputLegend>
                            </div>
                            <Error>{createBroadcastError}</Error>
                        </form>
                    }
                    footerComponent={
                        <ButtonGroup>
                            <Button kind="secondary" type="button" onClick={handleClosePopup}>Отмена</Button>
                            <Button form="broadcast-form" kind="primary" type="submit">Сохранить</Button>
                        </ButtonGroup>
                    }
                />
            </div>
        </Popup>
    );
}