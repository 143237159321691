import { FC, useEffect, useState } from "react";

// * Components
import Icon from "../../Icon";

//* Props
import { CountdownTimerProps } from "./CountdownTimer.interface";

// * Helpers
import { formatTimestamp } from "./helpers/formatTimestamp";

//* Styles
import styles from "./CountdownTimer.module.css";

const CountdownTimer: FC<CountdownTimerProps> = ({ startAt, onTimerEnd }) => {
  const calculateRemainingTime = (startAt?: string) => {
    return startAt ? new Date(startAt).getTime() - Date.now() : 0;
  };

  const [remainingTime, setRemainingTime] = useState(
    calculateRemainingTime(startAt)
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRemainingTime(calculateRemainingTime(startAt));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [startAt]);

  useEffect(() => {
    if (remainingTime <= 0) {
      onTimerEnd?.();
    }
  }, [remainingTime]);

  return (
    <div className={styles.remaining}>
      <div className={styles.remaining__icon}>
        <Icon name="timer" />
      </div>

      <h5 className={styles.remaining__title}>
        {formatTimestamp(remainingTime)}
      </h5>
      <p className={styles.remaining__subtitle}>До начала трансляции</p>
    </div>
  );
};

export default CountdownTimer;
