import React from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import { Login } from './pages/Login';
import { Tariffs } from './pages/Tariffs';
import { Reset } from './pages/Reset';
import { Signup } from './pages/Signup';
import { Dashboard } from './pages/Dashboard';
import { useEffect, useState } from 'react';
import { PageLoading } from './components/PageLoading';
import { useGetUserQuery } from './features/api/apiSlice';
import { Broadcast } from './pages/Broadcast';
import { Profile } from './pages/Profile';
import { useAppDispatch } from './app/hooks';
import Error from './pages/Error';
import Referral from './pages/Referral/Referral';
import BroadcastsMobile from './pages/BroadcastsMobile';
import Widget from './pages/Widget';
import PaymentHistory from './pages/PaymentHistory';
import { getRouteParams } from './helpers/getRouteParams';
import { useAppSelector } from './app/hooks';
import NotifyContainer from './components/Notify/NotifyContainer';
import { addNotify } from './features/notifySlice';
import { useReferralToken } from './hooks/useReferralToken';
import { useUpdateRefTokenMutation } from './features/api/apiSlice';
import { useLazyGetNotificationsQuery } from './features/api/apiSlice';
import { useUtmTag } from './hooks/useUtmTag';
import { useUpdateUtmMutation } from './features/api/apiSlice';

function App() {
    const { tokenFromStorage, removeTokenFromStorage } = useReferralToken();
    const { utmTagFromStorage, removeUtmTagFromStorage } = useUtmTag();
    const [ updateToken ] = useUpdateRefTokenMutation();
    const [ updateUtm ] = useUpdateUtmMutation();
    const [ getNotifications ] = useLazyGetNotificationsQuery();

    const [ loginServerError, setLoginServerError ] = useState(''); // Нужно чтобы уменьшить время появления спиннера после ошибки авторизации
    const location = useLocation();
    const navigate = useNavigate();

    const { theme } = useAppSelector((state) => state.options);
    const dispatch = useAppDispatch();

    const widgetParams = getRouteParams(window.location.href);

    const {
        isFetching: isUserFetching,
        isSuccess: isUserSuccess,
        data: user,
    } = useGetUserQuery(widgetParams.chat_token);

    // * Theme state
    useEffect(() => {
        const app = document.querySelector("#root") as HTMLElement;

        if(theme === "dark") {
            app.classList.remove("light");
            app.classList.add(theme);
            return;
        };

        app.classList.remove("dark");
        app.classList.add(theme);
    }, [theme]);

    // * Отправляет реферальный токен на сервер если он есть в localstorage
    // * используется для пользователей соц. сетей
    useEffect(() => {
        if(!tokenFromStorage || !user) return;

        updateToken({
            uid: user?.user_id,
            ref_token: tokenFromStorage
        });
        removeTokenFromStorage();
    }, [user]);

    useEffect(() => {
        if(!user) return;

        getNotifications({ isUnread: true })
            .unwrap()
            .then((data) => {
                if(data.notifications.length === 0) return;

                data.notifications.forEach((notify) => {
                    dispatch(addNotify(notify));
                });
            });
    }, [user]);

    useEffect(() => {
        if(utmTagFromStorage && user) {
            updateUtm({ userId: user.user_id, utmTag: utmTagFromStorage });
            removeUtmTagFromStorage();
        }
    }, [user]);

    if (isUserFetching) {
        return <PageLoading />;
    }

    const isAuthPage = location.pathname === '/login' || location.pathname === '/signup' || location.pathname === '/reset';

    if (!isUserSuccess && !isAuthPage && location.pathname !== "/widget") {
        navigate('/login', { replace: true });
    } else if (isUserSuccess && isAuthPage && location.pathname !== "/widget") {
        navigate('/', { replace: true });
    }

    return (
        <>
            <NotifyContainer />
            <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/broadcasts" element={<BroadcastsMobile />} />
                <Route path="/login" element={<Login serverError={loginServerError} setServerError={setLoginServerError} />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/reset" element={<Reset />} />
                <Route path="/tariffs" element={<Tariffs />} />
                <Route path="/broadcast/:id" element={<Broadcast />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/payment-history" element={<PaymentHistory />} />
                <Route path="/widget" element={<Widget />} />
                <Route path="/referral" element={<Referral />} />

                <Route path="*" element={<Error message="Not Found" description='Простите, страница не найдена'/>} />
            </Routes>
        </>
    );
}

export default App;
