import { useContext, useEffect } from "react";
import { SocketContext } from "../providers/SocketProvider/SocketProvider";
import {
  SocketAnswerAction,
  SocketApiActionType,
  SocketApiParams,
  SocketRequestAction,
} from "../types/socket.api.types";

// Сокет отправляет лишние сообщения с типами, которые обрабатывать не нужно
const ignoredMessageTypes = ["ping", "welcome", "confirm_subscription"];

/**
 * @param {number} channelId - id канала, для которого нужно перехватывать события. Хук будет отлавливать и обрабатывать только события,
 *                            связанные с этим конкретным каналом. Если параметр не указан, то будут обрабатываться события всех каналов
 *
 * @param {number} broadcastId - id трансляции, для которого нужно перехватывать события. Хук будет отлавливать и обрабатывать только события,
 *                            связанные с этим конкретной трансляцией. Если параметр не указан, то будут обрабатываться события всех трансляций
 */
export const useSocketApi = ({
  channelId,
  broadcastId,
  onBroadcastStatusUpdated,
  onTwitchBitrateWarning,
  onBitrateWarning,
  onChannelStatusUpdated,
  onNewChatMessage,
  onVkCaptchaError,
  onVkFloodError,
  onVkUnknowError,
}: SocketApiParams = {}) => {
  const { webSocket } = useContext(SocketContext);

  const sendMessage = ({ payload, identifier }: SocketRequestAction) => {
    if (!webSocket || webSocket.readyState !== webSocket.OPEN) return;

    webSocket.send(
      JSON.stringify({
        command: "message",
        data: JSON.stringify(payload),
        identifier,
      })
    );
  };

  useEffect(() => {
    if (!webSocket) return;

    const handleMessage = ({ data }: any) => {
      const parsedData = JSON.parse(data);

      if (ignoredMessageTypes.includes(parsedData.type)) return;

      const { action, payload } = parsedData.message as SocketAnswerAction;

      switch (action) {
        case SocketApiActionType.BROADCAST_STATUS_UPDATED: {
          const { broadcast_id } = payload;

          if (!broadcastId || broadcastId === broadcast_id) {
            onBroadcastStatusUpdated?.(payload);
            break;
          }

          break;
        }

        case SocketApiActionType.TWITCH_BITRATE_WARNING: {
          const { broadcast_id, restream_template_id } = payload;

          if (broadcast_id === broadcastId || channelId === restream_template_id){
            onTwitchBitrateWarning?.(payload);
          }
          break;
        }

        case SocketApiActionType.BITRATE_WARNING: {
          const { broadcast_id } = payload;

          if (broadcast_id === broadcastId){
            onBitrateWarning?.(payload);
          }
          break;
        }

        case SocketApiActionType.CHANNEL_STATUS_UPDATED: {
          const { id } = payload.restream_template;

          if (channelId === id) {
            onChannelStatusUpdated?.(payload.restream_template);
            break;
          }

          onChannelStatusUpdated?.(payload.restream_template);

          break;
        }

        case SocketApiActionType.NEW_CHAT_MESSAGE: {
          onNewChatMessage?.(payload);
          break;
        }

        case SocketApiActionType.VK_CAPTCHA_ERROR: {
          onVkCaptchaError?.(payload);
          break;
        }

        case SocketApiActionType.VK_FLOOD_ERROR: {
          onVkFloodError?.(payload);
          break;
        }

        case SocketApiActionType.VK_UNKNOWN_ERROR: {
          onVkUnknowError?.(payload);
          break;
        }
      }
    };

    webSocket.addEventListener("message", handleMessage);

    return () => {
      webSocket.removeEventListener("message", handleMessage);
    };
  }, [webSocket]);

  return {
    sendMessage,
  };
};
