import { useEffect } from "react";
import { useLazyGetUserQuery } from "../../features/api/apiSlice";
import s from "./SocialNetworksLoginOrSignup.module.css";
import { openWindow } from "../../helpers/openWindow";
import { useReferralToken } from "../../hooks/useReferralToken";
import { useUtmTag } from "../../hooks/useUtmTag";
import { socialNetworks } from "./SocialNetworksLoginOrSignup.data";

const { REACT_APP_API_ENDPOINT: baseUrl } = process.env;

export function SocialNetworksLoginOrSignup() {
    const { tokenFromUrl, saveTokenInStorage } = useReferralToken();
    const { utmTagFromUrl, saveUtmTagInStorage } = useUtmTag();

    const [ getUser ] = useLazyGetUserQuery();

    const handleLoginViaSocialNetwork = (socialNetworkUrl: string) => {
        openWindow(socialNetworkUrl);
    };

    useEffect(() => {
        const handleMessage = (event: MessageEvent<any>) => {
            if (event.origin === baseUrl && event.data?.auth) {
                tokenFromUrl && saveTokenInStorage(tokenFromUrl);
                utmTagFromUrl && saveUtmTagInStorage(utmTagFromUrl);
                getUser();
            }
        };

        window.addEventListener('message', handleMessage);
        return () => window.removeEventListener('message', handleMessage);
    }, []);

    return (
        <ul className={s['networks']}>
            {socialNetworks.map(({ socialAuthUrl, socialIconSrc }) =>
                <li onClick={() => handleLoginViaSocialNetwork(socialAuthUrl)}>
                    <img src={socialIconSrc} />
                </li>
            )}
        </ul>
    );
}