// * Types
import { Icon } from "../../../Icon/Icon.interface";

interface UserMenuItem {
  caption: string;
  icon: Icon;
  url: string;
}

export const items: UserMenuItem[] = [
  {
    caption: "Профиль",
    icon: "profile",
    url: "/profile",
  },

  {
    caption: "Пригласите друзей",
    icon: "gift",
    url: "/referral",
  }
];