import { FC } from 'react';

// * Props
import { TabTitleProps } from './TabTitle.interface';

// * Helpers
import cn from "classnames";

// * Styles
import styles from "./TabTitle.module.css";

const TabTitle: FC<TabTitleProps> = ({ title, tabIndex, isActive, setSelectedTab }) => {

  const handleClick = () => {
    setSelectedTab(tabIndex);
  };

  const titleClass = cn(styles["title__button"], { [styles.active]: isActive });

  return (
    <li className={styles.title}>
      <button className={titleClass} onClick={handleClick}>
        { title }
      </button>
    </li>
  );
};

export default TabTitle;