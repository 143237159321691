import { useCallback, useEffect, useState } from "react";

// * Components
import Icon from "../Icon";
// import { Pagination } from "../Pagination";
import { EditBroadcastPopup } from "../EditBroadcastPopup";
import { DeleteItemPopup } from "../DeleteItemPopup";
import TooltipButton from "../TooltipButton";
import EmptyState from "../EmptyState";

// * Hooks
import { useSocketApi } from "../../hooks/useSocketApi";
import { Broadcast, useDeleteBroadcastMutation, useGetBroadcastsQuery } from "../../features/api/apiSlice";
import { useNavigate } from "react-router-dom";
import { useScreenWidth } from "../../hooks/useScreenWidth";

// * Helpers
import { formatDate } from "../../helpers/formatDate";
import { formatTime } from "../../helpers/formatTime";
import cn from "classnames";

// * Styles
import s from "./Broadcasts.module.css";

// const PER_PAGE = 122;

// function getPage(currentPage: number, broadcasts: BroadcastList) {
//     const startIndex = (currentPage - 1) * PER_PAGE;
//     const endIndex = currentPage * PER_PAGE;
//     return broadcasts.slice(startIndex, endIndex);
// }

type Props = {
    onCreateBroadcast?: () => void;
}

export function Broadcasts({ onCreateBroadcast }: Props) {
    const [liveStreamIds, setLiveStreamIds] = useState<number[]>([]);
    const { isMobile } = useScreenWidth();

    const navigate = useNavigate();
    // const [ currentPage, setCurrentPage ] = useState(1);
    const [ isShowArchive, setIsShowArchive ] = useState(false);
    const [ broadcastToDelete, setBroadcastToDelete ] = useState<Broadcast>();
    const [ broadcastToEdit, setBroadcastToEdit ] = useState<Broadcast>();
    const { data: broadcastsData, refetch: refetchBroadcasts } = useGetBroadcastsQuery();
    // broadcastsData = {broadcasts: [], limit: 1, offset: 0, total: 0};
    const [ deleteBroadcast ] = useDeleteBroadcastMutation();
    const handleClosePopupDelete = useCallback(() => setBroadcastToDelete(undefined), []);
    const handleCloseEditBroadcastPopup = useCallback(() => setBroadcastToEdit(undefined), []);
    const isBroadcasts = broadcastsData?.broadcasts && broadcastsData.broadcasts.length > 0;
    useEffect(() => {
        refetchBroadcasts();
    }, []);
    // const numberPaginationPages = broadcastsData?.broadcasts && Math.ceil(broadcastsData?.broadcasts.length / PER_PAGE) || 0;
    function handleDeleteBroadcast(id: Broadcast['id']) {
        deleteBroadcast(id);
        handleClosePopupDelete();
    }
    function handleClickByBroadcast(event: React.MouseEvent<HTMLDivElement, MouseEvent>, id: Broadcast['id']) {
        if ((event.target as Element).closest(`.${s['broadcast-table__action']}`)) {
            return;
        }
        navigate(`/broadcast/${id}`);
    }
    // Ломает отображение трансляций, т.к. currentPage становится равна 0
    // useEffect(() => {
    //     if (broadcastsData?.broadcasts && broadcastsData?.broadcasts.length % PER_PAGE === 1 && numberPaginationPages === currentPage) {
    //         setCurrentPage((currentPage) => currentPage - 1);
    //     }
    // }, [broadcastsData]);

    useSocketApi({
        onBroadcastStatusUpdated: ({ status, broadcast_id }) => {
            if (status === "online") {
                setLiveStreamIds((ids) => [...ids, broadcast_id]);
            } else {
                setLiveStreamIds((ids) => ids.filter(id => id !== broadcast_id));
            }
        }
    });

    useEffect(() => {
        if (!broadcastsData) return;

        const onlineBroadcasts = broadcastsData?.broadcasts.filter(( broadcast => broadcast.status === 'online'));
        const onlineIds = onlineBroadcasts.map(broadcast => broadcast.id);

        setLiveStreamIds(state => [...state, ...onlineIds ]);
    }, [broadcastsData]);

    const broadcastsToShow = (() => {
        const broadcasts = broadcastsData?.broadcasts ?? [];
        return isShowArchive
            ? broadcasts.filter((broadcast) => typeof broadcast.end_at === 'string' && new Date(broadcast.end_at).getTime() < Date.now())
            : broadcasts;
    })();
    return (
        <>
            <div className={s['broadcasts']}>
                <div className={s['broadcasts__head']}>
                    <div className={s['broadcasts__title']}>
                        Эфиры
                        {!isMobile &&
                            <TooltipButton
                                position="top-right"
                                text="Здесь будут все Эфиры. У каждого из них свой URL и ключ, которые необходимо скопировать в OBS. Чтобы всякий раз при начале Эфира не менять URL и ключ в настройках OBS, можно использовать один и тот же Эфир."
                            />
                        }
                    </div>
                    <button
                        onClick={() => setIsShowArchive((isShowArchive) => !isShowArchive)}
                        className={cn(s['broadcasts__archive-button'], { [s['broadcasts__archive-button_active']]: isShowArchive })}
                    >
                        Смотреть архив
                    </button>
                </div>
                <div className={`${s['broadcast-table']} ${s['broadcast__table']}`}>

                    { !isMobile &&
                        <div className={`${s['broadcast-table__row']} ${s['broadcast-table__row_thead']}`}>
                            <span>Название</span>
                            <span>Дата</span>
                            <span>Время начала</span>
                            <span>Действия</span>
                        </div>
                    }

                    {broadcastsToShow.map((broadcast) => {
                        const { id, name, start_at } = broadcast;
                        return (
                            <div key={id} className={s['broadcast-table__row']} onClick={(event) => handleClickByBroadcast(event, id)}>
                                <span className={`${s['broadcast-table__td']} ${s['broadcast-table__td_name']}`}>{name}</span>
                                { !isMobile && <span className={`${s['broadcast-table__td']} ${s['broadcast-table__td_center']}`}>{formatDate(start_at)}</span> }
                                { !isMobile && <span className={`${s['broadcast-table__td']} ${s['broadcast-table__td_center']}`}>{formatTime(start_at)}</span> }
                                <span className={`${s['broadcast-table__td']} ${s['broadcast-table__action-list']}`}>
                                    {liveStreamIds.includes(id) && <button className={s['broadcast-table__action']}><Icon name="live" /></button> }
                                    <button className={s['broadcast-table__action']} onClick={() => setBroadcastToEdit(broadcast)}><Icon name="edit" /></button>
                                    <button className={s['broadcast-table__action']} onClick={() => setBroadcastToDelete(broadcast)}><Icon name="delete" /></button>
                                </span>
                            </div>
                        );
                    })}
                </div>
                {!isBroadcasts && (
                    <div className={s['broadcasts__empty']}>
                        <EmptyState
                            icon="(;-;)"
                            subtitle="У вас еще нет эфиров. Создайте свой первый эфир, чтобы увидеть его здесь"
                            buttonTitle="Создать эфир"
                            buttonAction={onCreateBroadcast}
                        />
                    </div>
                )}
                {/* {isBroadcasts && <Pagination
                    pageCount={numberPaginationPages}
                    onChangePage={(nextPage: number) => setCurrentPage(nextPage)}
                />} */}
            </div>
            {broadcastToDelete && (
                <DeleteItemPopup
                    isShow={Boolean(broadcastToDelete)}
                    onClose={handleClosePopupDelete}
                    onDelete={handleDeleteBroadcast}
                    item={broadcastToDelete}
                />
            )}
            {broadcastToEdit && (
                <EditBroadcastPopup
                    broadcast={broadcastToEdit}
                    onClose={handleCloseEditBroadcastPopup}
                />
            )}
        </>
    );
}
