import { useEffect, useState } from "react";
import classNames from "classnames";
import Icon from "../Icon";
import s from "./Pagination.module.css";

type Props = {
    pageCount: number;
    paginationClassName?: string;
    paginationItemClassName?: string;
    onChangePage: (pageNumber: number) => void;
};

function getPagesToShow(currentPage: number, pageNumbers: number[], maxToShow: number) {
    const showBefore = Math.ceil(maxToShow / 2);
    const showAfter = maxToShow - showBefore;
    let startIndex;
    let endIndex;
    if (currentPage <= showBefore) {
        startIndex = 0;
        endIndex = Math.min(maxToShow, pageNumbers.length);
    } else if (currentPage >= pageNumbers.length - showAfter) {
        startIndex = Math.max(pageNumbers.length - maxToShow, 0);
        endIndex = pageNumbers.length;
    } else {
        startIndex = currentPage - showBefore;
        endIndex = currentPage + showAfter;
    }
    return pageNumbers.slice(startIndex, endIndex);
}

function getLastOf<T>(list: T[]) {
    return list[list.length - 1];
}

export function Pagination({ pageCount, onChangePage, paginationClassName, paginationItemClassName }: Props) {
    const pageNumbers = new Array(pageCount).fill(1).map((value, index) => index + 1);
    const [ currentPage, setCurrentPage ] = useState(pageNumbers[0]);
    const pagesToShow = getPagesToShow(currentPage, pageNumbers, 5);
    function handleClick(goTo: 'prev' | 'next' | number) {
        if (goTo === 'prev') {
            setCurrentPage((currentPage) => {
                const nextPage = Math.max(currentPage - 1, pageNumbers[0]);
                onChangePage(nextPage);
                return nextPage;
            });
        } else if (goTo === 'next') {
            setCurrentPage((currentPage) => {
                const nextPage = Math.min(currentPage + 1, getLastOf<number>(pageNumbers));
                onChangePage(nextPage);
                return nextPage;
            });
        } else {
            setCurrentPage(goTo);
            onChangePage(goTo);
        }
    }
    const isPrevButton = currentPage !== 1;
    const isNextButton = currentPage !== getLastOf<number>(pageNumbers);
    useEffect(() => {
        if (pageNumbers.includes(currentPage)) return;
        setCurrentPage(pageNumbers[pageNumbers.length - 1]);
    }, [pageCount]);
    if (pagesToShow.length <= 1) {
        return null;
    }
    return (
        <ul className={classNames(paginationClassName, s['pagination'])}>
            <li
                className={classNames(
                    paginationItemClassName,
                    s['pagination__item'],
                    { [s['pagination__item_no-pointer']]: !isPrevButton }
                )}
                onClick={() => handleClick('prev')}
            >
                {isPrevButton && <span className={s['pagination__button']}><Icon name="arrow" /></span>}
            </li>
            {pagesToShow.map((pageNumber) => (
                <li
                    className={classNames(
                        paginationItemClassName,
                        s['pagination__item'],
                        { [s['pagination__item_active']]: currentPage === pageNumber }
                    )}
                    key={pageNumber}
                    onClick={() => handleClick(pageNumber)}
                >{pageNumber}</li>
            ))}
            <li
                className={classNames(
                    paginationItemClassName,
                    s['pagination__item'],
                    { [s['pagination__item_no-pointer']]: !isNextButton }
                )}
                onClick={() => handleClick('next')}
            >
                {isNextButton && <span
                    className={classNames(
                        s['pagination__button'],
                        s['pagination__button_rotate-180']
                    )}>
                        <Icon name="arrow" />
                    </span>
                }
            </li>
        </ul>
    );
}