export const headerNavList = [
  {
    caption: "Главная",
    url: "/",
  },
  {
    caption: "Тарифы",
    url: "/tariffs",
  },
];
