import { FC } from "react";

// * Components
import { Logo } from "../Logo";
import { Link, NavLink } from "react-router-dom";
import HeaderThemeSwitcher from "./HeaderThemeSwitcher";
import HeaderNotifications from "./HeaderNotifications";
import HeaderProjects from "./HeaderProjects";
import HeaderUserMenu from "./HeaderUserMenu";
import HeaderSocials from "./HeaderSocials";
import Icon from "../Icon";

// * Data
import { headerNavList } from "./Header.data";

//* Props
import { HeaderProps } from "./Header.interface";

// * Helpers
import cn from "classnames";

//* Styles
import styles from "./Header.module.css";

const Header: FC<HeaderProps> = ({
  isBackBtn = true,
  returnUrl = "/",
  title = "На главную",
}) => {
  return (
    <header className={styles.header}>
      {isBackBtn && (
        <Link to={returnUrl} className={styles["header__mobile-back"]}>
          <div className={styles["header__back-arrow"]}>
            <Icon name="arrow-back" />
          </div>

          <span className={styles.header__title}>{title}</span>
        </Link>
      )}

      <div className={styles.header__logo}>
        <Logo />
      </div>

      <nav className={styles.header__nav}>
        {headerNavList.map(({ caption, url }) => (
          <NavLink
            to={url}
            key={url}
            className={({ isActive }) =>
              cn(styles.header__link, {
                [styles.header__link_active]: isActive,
              })
            }
          >
            {caption}
          </NavLink>
        ))}
      </nav>

      <div className={styles.header__actions}>
        <HeaderThemeSwitcher />
        <HeaderNotifications />

        <div className={styles.header__projects}>
          <HeaderProjects />
        </div>

        <HeaderUserMenu />

        <div className={styles.header__socials}>
          <HeaderSocials />
        </div>
      </div>
    </header>
  );
};

export default Header;
