export function formatTime(timestamp: string | void, withSeconds = false) {
    if (typeof timestamp !== 'string') return '';
    const dateObj = new Date(timestamp);
    const hour = `0${dateObj.getHours()}`.slice(-2);
    const minutes = `0${dateObj.getMinutes()}`.slice(-2);
    const dateString = `${hour}:${minutes}`;

    if (withSeconds) {
        const seconds = `0${dateObj.getSeconds()}`.slice(-2);
        return `${dateString}:${seconds}`;
    }

    return dateString;
}