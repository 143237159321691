import { createSlice } from "@reduxjs/toolkit";

export type Theme = "dark" | "light";
interface OptionsState {
  theme: Theme;
}

const initialState: OptionsState = {
  theme: localStorage.getItem("theme") as Theme || 'light'
};

export const optionsSlice = createSlice({
  name: "options",

  initialState,

  reducers: {
    switchTheme: (state) => {
      state.theme = state.theme === "light" ? "dark" : "light";
      localStorage.setItem("theme", state.theme);
    }
  },
});

export const { switchTheme } = optionsSlice.actions;