import { useSearchParams } from "react-router-dom";

export const useUtmTag = () => {
  const [searchParams] = useSearchParams();

  const utmTagFromUrl = searchParams.get(String(process.env.REACT_APP_UTM_TAG_KEY));
  const utmTagFromStorage = localStorage.getItem(String(process.env.REACT_APP_UTM_TAG_KEY));

  const saveUtmTagInStorage = (utm: string) => localStorage.setItem(String(process.env.REACT_APP_UTM_TAG_KEY), utm);
  const removeUtmTagFromStorage = () => localStorage.removeItem(String(process.env.REACT_APP_UTM_TAG_KEY));

  return {
    utmTagFromUrl,
    utmTagFromStorage,
    saveUtmTagInStorage,
    removeUtmTagFromStorage,
  };
};