import { MutableRefObject, useState } from "react";
import cn from "classnames";
import s from "./Checkbox.module.css";

type Props = {
    value?: boolean;
    children: JSX.Element | string;
    inputRef?: MutableRefObject<HTMLInputElement | null>;
    fakeInputClassName?: string;
    onChange?: () => void;
};

export function Checkbox({ children, value, inputRef, fakeInputClassName, onChange }: Props) {
    const [ isChecked, setIsChecked ] = useState(value || false);
    function handleChange() {
        setIsChecked((isChecked) => !isChecked);
        onChange?.();
    }
    return (
        <label
            className={s['checkbox']}
        >
            <input
                type="checkbox"
                className={s['checkbox__input']}
                checked={isChecked}
                onChange={handleChange}
                ref={inputRef}
            />
            <span
                className={cn(s['checkbox__fake-input'], fakeInputClassName)}
                tabIndex={0}
                onKeyDown={(event) => {
                    const { code } = event;
                    if (code === 'Enter' || code === 'Space') {
                        handleChange();
                    }
                }}
            ></span>
            <span className={s['checkbox__text']}>{children}</span>
        </label>
    );
}