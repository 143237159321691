import React from "react";

import { Button } from "../../../Button";

import { CardProps } from './Card.interface';
import styles from './Card.module.css';

const Card: React.FC<CardProps> = ({ icon, title, description, href }) => {
  const Icon = icon;

  return (
    <div className={styles.card}>
      <div className={styles.card__heading}>
        <Icon/>
        <h1 className={styles.card__heading_title}> {title} </h1>
      </div>

      <p className={styles.card__description}>
        {description}
      </p>

      <a href={href || "/"} target="_blank" rel="noreferrer">
        <Button kind="primary"> Подробнее </Button>
      </a>
    </div>
  );
};

export default Card;