import { FC, PropsWithChildren } from "react";

// * Components
import Header from "../Header";
import MobileNav from "../MobileNav";

// * Mobile header props
import { HeaderProps } from "../Header/Header.interface";

// * Hooks
import { useScreenWidth } from "../../hooks/useScreenWidth";

const Layout: FC<PropsWithChildren<HeaderProps>> = ({
  children,
  isBackBtn,
  returnUrl,
  title,
}) => {
  const { isMobile } = useScreenWidth();

  return (
    <>
      <Header
        title={title}
        returnUrl={returnUrl}
        isBackBtn={isBackBtn}
      />

      {children}

      {isMobile && <MobileNav />}
    </>
  );
};

export default Layout;
