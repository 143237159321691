import { FC } from "react";

// * Components
import Step from "./Step/Step";

// * Data
import { steps } from "./Steps.data";

//* Styles
import styles from "./Steps.module.css";

const Steps: FC = () => {
  return (
    <div className={styles.steps}>
      {steps.map((step, idx) => (
        <Step
          key={idx}
          step={idx + 1}
          title={step.title}
          description={step.description}
          border={idx < steps.length - 1}
        />
      ))}
    </div>
  );
};

export default Steps;