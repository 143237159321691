import { Broadcast, Channel } from "../../features/api/apiSlice";
import { Button } from "../Button";
import { ButtonGroup } from "../ButtonGroup";
import { Popup, Props as PopupProps } from "../Popup";
import PopupStructure from "../PopupStructure";
import PopupTitleDangerous from "../PopupTitleDangerous";
import s from "./DeleteItemPopup.module.css";

type Props = PopupProps & {
    item: Broadcast | Channel;
    onDelete: (id: Broadcast['id']) => void;
};

export function DeleteItemPopup({ onDelete, item, ...restProps }: Props) {
    return (
        <Popup {...restProps}>
            <div className={s['broadcast-delete']}>
                <PopupStructure
                    headerComponent={
                        <PopupTitleDangerous>
                            Вы уверены, что хотите удалить эфир?
                        </PopupTitleDangerous>
                    }

                    contentComponent={
                        <div className={s['broadcast-delete__description']}>Это действие безвозвратно удалит эфир “{item.name}”.</div>
                    }

                    footerComponent={
                        <ButtonGroup>
                            <Button kind="secondary" onClick={restProps.onClose}>Отмена</Button>
                            <Button kind="primary" onClick={() => {
                                if (item.id) {
                                    onDelete(item.id);
                                }
                            }}>Удалить</Button>
                        </ButtonGroup>
                    }
                />
            </div>
        </Popup>
    );
}