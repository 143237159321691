import { FC } from "react";

// * Components
import { Button } from "../Button";

// * Props
import { EmptyStateProps } from './EmptyState.interface';

//* Styles
import styles from './EmptyState.module.css';

const EmptyState: FC<EmptyStateProps> = ({ icon, subtitle, buttonTitle, buttonAction }) => {

  // * Handlers
  const handleClick = () => {
    buttonAction && buttonAction();
  };

  return (
    <div className={styles.empty}>
      <h3>{icon}</h3>
      <p>{subtitle}</p>

      <Button kind="primary" onClick={() => handleClick()}>
        {buttonTitle}
      </Button>
    </div>
  );
};

export default EmptyState;