import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Notify {
  notify_type: "info" | "success" | "error" | "warning";
  message: string;
  id?: number;
  read?: boolean;
  type?: "system" | "personal";
}

// * State interface
interface OptionsState {
  notifications: Notify[];
}

// * Init state
const initialState: OptionsState = {
  notifications: [],
};

export const notifySlice = createSlice({
  name: "notifications",
  initialState,

  reducers: {
    addNotify: (state, action: PayloadAction<Omit<Notify, "id" | "type" | "read">>) => {
      // * Не добавляем сообщения с одинаковым текстом
      if(state.notifications.some((notify) =>
        notify.message.toLocaleLowerCase() === action.payload.message.toLocaleLowerCase())
      ) return;

      state.notifications.unshift({ ...action.payload });
    },

    removeNotify: (state, action: PayloadAction<number>) => {
      state.notifications = state.notifications.filter((notify) => notify.id !== action.payload);
    },

    clearNotifyArray: (state) => {
      state.notifications = [];
    }
  },
});

export const { addNotify, removeNotify, clearNotifyArray } = notifySlice.actions;