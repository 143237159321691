import { ComponentProps } from "react";
import cn from "classnames";
import Icon from "../Icon";
import s from "./ExclamationMark.module.css";

type Props = ComponentProps<'div'>;

export function ExclamationMark({ className, ...restProps }: Props) {
    return (
        <div className={cn(s['mark'], className)}>
            <Icon name="exclamation-mark" />
        </div>
    );
}