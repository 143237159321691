import { FC, FormEvent, useState, useRef } from 'react';

// * Props
import { PasswordFormProps } from './PasswordForm.interface';

// * Components
import { PasswordField } from '../../../PasswordField';
import { Button } from '../../../Button';

// * Helpers
import { checkPassword } from '../../../../helpers/checkPassword';

// * Styles
import styles from "../Forms.module.css";
import { useChangePasswordMutation } from '../../../../features/api/apiSlice';

const PasswordForm: FC<PasswordFormProps> = ({ setShowPopup }) => {
  const [ updatePassword, { isLoading } ] = useChangePasswordMutation();

  const [ changePasswordCurrentError, setChangePasswordCurrentError ] = useState("");
  const [ changePasswordNewError, setChangePasswordNewError ] = useState("");
  const [ changePasswordConfirmationError, setChangePasswordConfirmationError ] = useState("");

  const [ isResetChangePasswordCurrent, setIsResetChangePasswordCurrent ] = useState(false);
  const [ isResetChangePasswordNew, setIsResetChangePasswordNew ] = useState(false);
  const [ isResetChangePasswordConfirmation, setIsResetChangePasswordConfirmation ] = useState(false);

  const changePasswordCurrentRef = useRef<HTMLInputElement>(null);
  const changePasswordConfirmationRef = useRef<HTMLInputElement>(null);
  const changePasswordNewRef = useRef<HTMLInputElement>(null);

  const handleChangePassword = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!changePasswordNewRef.current || !changePasswordConfirmationRef.current) return;
    const changePasswordCurrentValue = changePasswordCurrentRef.current?.value ?? '';
    const changePasswordConfirmationValue = changePasswordConfirmationRef.current.value;
    const changePasswordNewValue = changePasswordNewRef.current.value;

    if (!checkPassword(changePasswordNewValue)) {
        return setChangePasswordNewError("Неверный пароль!");
    }
    if (changePasswordCurrentValue === changePasswordNewValue) {
        return setChangePasswordNewError("Новый пароль не должен совпадать со старым!");
    }
    if (changePasswordNewValue !== changePasswordConfirmationValue) {
        return setChangePasswordConfirmationError("Пароли не совпадают!");
    }
    if (!checkPassword(changePasswordConfirmationValue)) {
        return setChangePasswordConfirmationError("Неверный пароль!");
    }

    updatePassword({
        currentPassword: changePasswordCurrentValue,
        password: changePasswordNewValue,
        passwordConfirmation: changePasswordConfirmationValue
    })
      .unwrap()
      .then(() => {
        setShowPopup(true);
        setIsResetChangePasswordNew(true);
        setIsResetChangePasswordCurrent(true);
        setIsResetChangePasswordConfirmation(true);
      })
      .catch((e) => {
        setChangePasswordCurrentError(e.data.errors);
      });
  };

  const handleChangePasswordInput = () => {
    if (isResetChangePasswordCurrent) {
        setIsResetChangePasswordCurrent(false);
    }
    if (isResetChangePasswordNew) {
        setIsResetChangePasswordNew(false);
    }
    if (isResetChangePasswordConfirmation) {
        setIsResetChangePasswordConfirmation(false);
    }
  };

  return (
    <form className={styles['form']} onSubmit={handleChangePassword} onInput={handleChangePasswordInput}>
      <fieldset className={styles['form__fieldset']}>
        <span className={styles['form__field-title']}>Старый пароль</span>
        <PasswordField
          isError={Boolean(changePasswordCurrentError)}
          icon="toggle"
          ref={changePasswordCurrentRef}
          isReset={isResetChangePasswordCurrent}
          error={changePasswordCurrentError}
          onInput={() => {
            if (changePasswordCurrentError) {
              setChangePasswordCurrentError("");
            }
            if (changePasswordNewError) {
              setChangePasswordNewError("");
            }
          }}
        />
      </fieldset>

      <fieldset className={styles['form__fieldset']}>
        <span className={styles['form__field-title']}>Новый пароль</span>
        <div className={styles['form__field']}>
          <PasswordField
            isError={Boolean(changePasswordNewError)}
            icon="toggle"
            ref={changePasswordNewRef}
            onInput={() => {
              if (changePasswordNewError) {
                setChangePasswordNewError("");
              }
              if (changePasswordConfirmationError) {
                setChangePasswordConfirmationError("");
              }
            }}
            isReset={isResetChangePasswordNew}
            error={changePasswordNewError}
          />
        </div>
      </fieldset>

      <fieldset className={styles['form__fieldset']}>
        <span className={styles['form__field-title']}>Повторите пароль</span>
        <div className={styles['form__field']}>
          <PasswordField
            isError={Boolean(changePasswordConfirmationError)}
            isReset={isResetChangePasswordConfirmation}
            icon="toggle"
            ref={changePasswordConfirmationRef}
            onInput={() => {
              if (changePasswordConfirmationError) {
                setChangePasswordConfirmationError("");
              }
            }}
            error={changePasswordConfirmationError}
          />
        </div>
      </fieldset>

      <Button kind="primary" className={styles['form__full-width']}>
        {isLoading ? "Загрузка..." : "Изменить пароль"}
      </Button>
    </form>
  );
};

export default PasswordForm;