import React from "react";
import { useScreenWidth } from "../../hooks/useScreenWidth";
import useMeasure from "react-use-measure";

import { PopupStructureProps } from './PopupStructure.interface';
import styles from './PopupStructure.module.css';

const PopupStructure: React.FC<PopupStructureProps> = ({
  headerComponent,
  contentComponent,
  footerComponent
}) => {
  const { isMobile } = useScreenWidth();
  const [ headerRef, headerBounds ] = useMeasure();
  const [ footerRef, footerBounds ] = useMeasure();

  return (
    <div className={styles.structure}>
      {headerComponent && (
        <div className={styles.structure__header} ref={headerRef}>
          {headerComponent}
        </div>
      )}

      {contentComponent && (
        <div
          className={styles.structure__content}
          style={{
            paddingTop:    (headerComponent && isMobile) ? headerBounds.height + 20 : 0,
            paddingBottom: (footerComponent && isMobile) ? footerBounds.height + 5 : 0
          }}
        >
          {contentComponent}
        </div>
      )}

      {footerComponent && (
        <div className={styles.structure__footer} ref={footerRef}>
          {footerComponent}
        </div>
      )}
    </div>
  );
};

export default PopupStructure;