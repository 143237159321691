import { useEffect, useState } from "react";

// * Components
import { Button, LinkButton } from "../../components/Button";
import Layout from "../../components/Layout";
import WithdrawPopup from "../../components/Referral/WithdrawPopup/WithdrawPopup";

// * Hooks
import { useBuyTariffMutation, useGetTariffsQuery, useGetUserQuery } from "../../features/api/apiSlice";

// * Helpers
import { formatDate } from "../../helpers/formatDate";
import cn from "classnames";

// * Styles
import s from "./Tariffs.module.css";

const initialTabs = [{
    isActive: true,
    text: 'За месяц'
}, {
    isActive: false,
    text: 'За год'
}];

export function Tariffs() {
    const [ buyTariff ] = useBuyTariffMutation();
    const { data: user } = useGetUserQuery();
    const { data: tariffs } = useGetTariffsQuery();

    const [ tabs, setTabs ] = useState(initialTabs);
    const [ infoMessage, setInfoMessage ] = useState('');
    const [ isWithdrawPopup, setIsWithdrawPopup] = useState(false);
    const [ selectedTariffId, setSelectedTariffId ] = useState(0);

    const activeTabIndex = tabs.findIndex(tab => tab.isActive);

    const handleTabClickByTab = (tabText: string) => {
        setTabs((tabs) => tabs.map((tab) => ({ ...tab, isActive: tab.text === tabText })));
    };

    const handlePayment = async (id: number) => {
        if(user && user?.ref_points > 0) {
            setSelectedTariffId(id);
            setIsWithdrawPopup(true);
            return;
        }

        await buyTariff({ id })
            .unwrap()
            .then(({ buy_url }) => window.location.href = buy_url);
    };

    useEffect(() => {
        let buyInfo = localStorage.getItem('buy');
        if (typeof buyInfo !== 'string') return;
        setInfoMessage(buyInfo === 'ok' ? 'Ваш тариф обновлен!' : buyInfo);
        localStorage.removeItem('buy');
    }, []);

    if (!user) {
        return <div />;
    }

    const isYear = activeTabIndex === 1;
    return (
        <Layout>
            <div className={s['tariffs']}>
                <div className={cn(s['info-message'], {[s['info-message_hide']]: !infoMessage})}>{infoMessage}</div>
                <div className={cn(s['curren-tariff'], {[s['curren-tariff_indent-small']]: infoMessage}, {[s['curren-tariff_indent-big']]: !infoMessage})}>
                    <div className={s['current-tariff__title']}>Выбранный тариф:</div>
                    <div className={cn(s['current-tariff__value'], s['current-tariff__value_gradient'])}>{user.tariff.name}</div>
                    <div className={s['current-tariff__title']}>Дата подключения:</div>
                    <div className={s['current-tariff__value']}>{formatDate(user.tariff.start_at, '.')}</div>
                    <div className={s['current-tariff__title']}>Дата окончания подписки:</div>
                    <div className={cn(s['current-tariff__value'], { [s['current-tariff__value-infinity']]: user.tariff.name === "R2"})}>
                        { user.tariff.name === "R2" ? "∞" : formatDate(user.tariff.end_at, '.')}
                    </div>
                </div>
                <div className={s['tariffs__proposal-text']}>Изменились потребности? Рассмотрите новый тарифный план</div>
                <ul className={cn(s['tariffs__tabs'], s['tabs'], s['tabs_active-' + activeTabIndex])}>
                    {tabs.map((tab, index) => (
                        <li
                            className={cn(s['tabs__item'], {[s['tabs__item_active']]: activeTabIndex === index})}
                            key={tab.text}
                            onClick={() => handleTabClickByTab(tab.text)}
                        >
                            {tab.text}
                        </li>
                    ))}
                </ul>
                <ul className={s['card-list']}>
                    {tariffs?.tariffs
                        .filter((tariff) => tariff.duration === (isYear ? 365 : 30))
                        .map(({ id, price, name, description, features, promo, promo_price }, index) => (
                            <li key={id} className={s['card']}>
                                {index === 1 && <div className={s['card__mark']}>Популярный</div>}

                                <div className={s['card__price-info']}>
                                    {promo && (
                                        <div className={s['card__promo-price']}>
                                            <span className={s.card__price}>{promo_price} ₽</span>
                                        </div>
                                    )}
                                    <span className={cn(s.card__price, {[s.card__price_crossed]: promo})}>{price} ₽</span>
                                    <span className={s.card__period}>/мес</span>

                                    {!promo && <span className={cn(s['card__year'], {[s['card__year_show']]: isYear})}>при оплате за 12 месяцев</span>}
                                </div>

                                <div className={s['card__title']}>{name}</div>
                                <div className={s['card__description']}>{description}</div>
                                <ul className={s['card__option-list']}>
                                    {features.map((feature) => <li className={s['card__option']} key={feature}>{feature}</li>)}
                                </ul>
                                <Button
                                    kind="secondary"
                                    className={s['card__button']}
                                    onClick={() => handlePayment(id)}
                                >Выбрать тариф</Button>
                            </li>
                    ))}
                    <li className={cn(s['card'], s['card_custom'])}>
                        <div className={s['card__title']}>Настраиваемый</div>
                        <div className={s['card__description']}>Нужно больше 10 каналов или оформление для юр. лица? Давайте обсудим условия</div>
                        <ul className={s['card__option-list']}>
                            <li className={s['card__option']}>Любое количество каналов</li>
                            <li className={s['card__option']}>Настраиваемый битрейт</li>
                            <li className={s['card__option']}>Закрывающие документы</li>
                        </ul>
                        <LinkButton
                            kind="secondary"
                            className={s['card__button']}
                            href="https://t.me/restream_webcaster"
                            target="_blank"
                            rel="noopener noreferrer"
                        >Связаться с нами</LinkButton>
                    </li>
                </ul>
            </div>

            <WithdrawPopup
                selectedTariffId={selectedTariffId}
                coins={user.ref_points}
                isShow={isWithdrawPopup}
                onClose={() => setIsWithdrawPopup(false)}
            />
        </Layout>
    );
}