import { FC } from 'react';

// * Data
import { buttons } from './MobileNav.data';

// * Components
import NavButton from './NavButton';

// * Styles
import styles from "./MobileNav.module.css";


const MobileNav: FC = () => {
  return (
    <div className={styles["mobile-nav"]}>
      { buttons.map(button =>
          <NavButton {...button} key={button.label} />
        )
      }
    </div>
  );
};

export default MobileNav;