import { FC } from "react";
import {
  IPlannedStream,
  IVkStream,
  IYoutubeStream,
} from "../../../../features/api/apiSlice";

//* Props
import { PlannedFormItemProps } from "./PlannedFormItem.interface";

// * Components
import PlatformIcon from "../../../PlatformIcon";

// * Helpers
import cn from "classnames";

//* Styles
import styles from "./PlannedFormItem.module.css";

const PlannedFormItem: FC<PlannedFormItemProps> = ({
  isSelected,
  handleClick,
  plannedStream,
  platform,
}) => {
  const streamItemClasses = cn(styles.stream, {[styles.stream_selected]: isSelected});

  const getEventRole = (event: IPlannedStream) => {
    if (plannedStream.type === "channel") {
      return "Создание нового эфира";
    }

    if (plannedStream.platform === "youtube") {
      return (plannedStream as IYoutubeStream).privacy_status === "private" ? "Приватный" : "Публичный";
    }
  };

  const getEventThumbnail = (event: IPlannedStream) => {
    if (event.platform === "youtube") {
      return (event as IYoutubeStream).thumbnails.medium.url;
    }

    if (event.platform === "vk") {
      return (event as IVkStream).thumbnails.url;
    }
  };

  return (
    <div className={streamItemClasses} onClick={() => handleClick(plannedStream)}>
      {plannedStream.type === "channel" && platform !== "other" ? (
        <div className={styles.stream__image}>
          <PlatformIcon platform={platform} />
        </div>
      ) : (
        <img
          className={styles.stream__image}
          src={getEventThumbnail(plannedStream)}
          alt={plannedStream.title}
        />
      )}

      <div className={styles.stream__info}>
        <p className={styles.stream__name}>{plannedStream.title}</p>
        <p className={styles.stream__role}>{getEventRole(plannedStream)}</p>
      </div>
    </div>
  );
};

export default PlannedFormItem;
