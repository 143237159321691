import { NavButtonProps } from "./NavButton/NavButton.interface";

// * Images
import broadcast from "./assets/broadcast.svg";
import tariff from "./assets/tariff.svg";
import profile from "./assets/profile.svg";

export const buttons: NavButtonProps[] = [
  {
    image: broadcast,
    label: "Эфиры",
    url: "/broadcasts",
  },

  {
    image: tariff,
    label: "Мой тариф",
    url: "/tariffs",
  },

  {
    image: profile,
    label: "Профиль",
    url: "/profile",
  },
];
