import { FC, useState } from 'react';

// * Components
import { Button } from '../../Button';
import Icon from '../../Icon';

// * Props
import { UserAvatarProps } from './UserAvatar.interface';

// * Utils
import { checkFile } from '../../../helpers/checkFile';

// * Hooks
import { useUploadAvatarMutation } from '../../../features/api/apiSlice';
import { useScreenWidth } from '../../../hooks/useScreenWidth';

// * Helpers
import cn from "classnames";

// * Styles
import styles from "./UserAvatar.module.css";

const UserAvatar: FC<UserAvatarProps> = ({ user }) => {
  // * States
  const [ uploadError, setUploadError ] = useState("");

  // * Hooks
  const [ changeAvatar ] = useUploadAvatarMutation();
  const { isMobile } = useScreenWidth();

  // * Handlers
  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUploadError("");

    const fileList = e.target.files as FileList;
    const file = fileList[0];

    const validation = checkFile(file);

    if(!validation.status) {
        return setUploadError(validation.message);
    }

    const body = new FormData();
    body.append("avatar", file);

    changeAvatar({
        body,
        uid: user?.user_id
    });
  };

  return (
    <div className={styles['avatar-block']}>
      <span className={cn(styles['avatar-block__img'], { [styles['avatar-block-empty']]: !user?.avatar })}>
        {user?.avatar ? <img src={user.avatar} alt="" /> : <Icon name="user" /> }
      </span>

      { isMobile && <p className={styles['avatar-block__email']}>{ user?.user_email }</p> }

      <label className={styles['avatar-block__upload']}>
        <input type="file" id="avatar" name="avatar" accept="image/png, image/jpeg" onChange={handleUpload}/>
        <Button kind="secondary">Загрузить фото</Button>
      </label>

      { uploadError && <span className={styles["avatar-block__upload-error"]}>{uploadError}</span> }

      { !isMobile && <span className={styles['avatar-block__hint']}>Максимальный размер 2 МБ</span> }
    </div>
  );
};

export default UserAvatar;