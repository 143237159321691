import { FC } from "react";

// * Components
import { CopyField } from "../../CopyField";
import TooltipButton from "../../TooltipButton";

// * Hooks
import { useGetBroadcastQuery } from "../../../features/api/apiSlice";
import { useScreenWidth } from "../../../hooks/useScreenWidth";

// * Props
import { StreamInfoProps } from "./StreamInfo.interface";

//* Styles
import styles from "./StreamInfo.module.css";

const StreamInfo: FC<StreamInfoProps> = ({ broadcastId }) => {
  const { data } = useGetBroadcastQuery(broadcastId);
  const { isMobile } = useScreenWidth();

  return (
    <div className={styles.info}>
      <div className={styles.info__field}>
        <div className={styles["info__field-legend"]}>
          URL сервера
          {!isMobile && (
            <TooltipButton
              position="top-right"
              text="URL сервера уникальный для каждого пользователя и для каждого Эфира. Его необходимо скопировать в настройки OBS."
            />
          )}
        </div>
        <CopyField
          color="gray"
          type="text"
          defaultValue={data?.broadcast.capture_url}
          readOnly={true}
        />
      </div>

      <div className={styles.info__field}>
        <div className={styles["info__field-legend"]}>
          Ключ потока
          {!isMobile && (
            <TooltipButton
              position="top-right"
              text="Ключ потока так же, как и URL сервера, необходимо скопировать в настройки OBS."
            />
          )}
        </div>
        <CopyField
          color="gray"
          type="password"
          value={data?.broadcast.rtmp_key}
          readOnly={true}
        />
      </div>
    </div>
  );
};

export default StreamInfo;
