import React, { useState } from "react";

// * Helpers
import { formatDate } from "../../helpers/formatDate";
import { formatTime } from "../../helpers/formatTime";

// * Hooks
import { useGetTransactionsQuery } from "../../features/api/apiSlice";

// * Components
import Layout from "../../components/Layout";
import Table from './Table';
import { Pagination } from "../../components/Pagination";

// * Local
import styles from './PaymentHistory.module.css';

const TRANSACTIONS_PER_PAGE_LIMIT = 9;

const PaymentHistory: React.FC = () => {
  const [ page, setPage ] = useState(1);
  const { data } = useGetTransactionsQuery({page, limit: TRANSACTIONS_PER_PAGE_LIMIT});

  return (
    <Layout
      title="В профиль"
      returnUrl="/profile"
    >
      <div className={styles.payments}>
        <h1 className={styles.payments__heading}> История платежей </h1>

        <div className={styles.payments__table}>
          {(data?.transactions && data.transactions.length > 0) ? (
            <Table
              transactions={data.transactions}
              columns={[
                {
                  name: 'Тариф',
                  key: 'tariff_name',
                  transformValue: (name) => name.replace(/Restream\s/gm, 'R')
                },
                {
                  name: 'Сумма платежа',
                  key: 'sum',
                  transformValue: (sum) => sum + ' ₽'
                },
                {
                  name: 'Дата оплаты',
                  key: 'buy_date',
                  transformValue: (date) => formatDate(date, '.')
                } ,
                {
                  name: 'Время оплаты',
                  key: 'buy_date',
                  transformValue: (date) => formatTime(date, true)
                },
                {
                  name: 'Дата окончания',
                  key: 'terminate_date',
                  transformValue: (date) => formatDate(date, '.')
                },
              ]}
            />
          )
          : (
            <p className={styles['payments__table-text-empty']}>
              Тут будет отображаться история ваших платежей
            </p>
          )}
        </div>

        {(data?.transactions && data.transactions.length > 0) && (
          <div className={styles.payments__pagination}>
            <Pagination
              pageCount={Math.ceil(data.total / TRANSACTIONS_PER_PAGE_LIMIT)}
              onChangePage={(page) => setPage(page)}
            />
          </div>
        )}
      </div>
    </Layout>
  );
};

export default PaymentHistory;