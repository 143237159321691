import { useState, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useCreateBroadcastMutation } from "../../features/api/apiSlice";
import { formatLength } from "../../helpers/formatInput";
import { Button } from "../Button";
import { InputField } from "../InputField";
import s from "./StartBroadcasting.module.css";

type Props = {
    onError: (error: string) => void;
};

const errorText = 'Заполните название эфира';

export function StartBroadcasting({ onError }: Props) {
    const navigate = useNavigate();
    const [ createBroadcast ] = useCreateBroadcastMutation();
    const [ error, setError ] = useState('');
    const inputRef = useRef<HTMLInputElement>(null);
    const handleStart = useCallback(async () => {
        if (!inputRef.current) return;
        const { value } = inputRef.current;
        if (value.length === 0) {
            setError(errorText);
            onError(errorText);
            return;
        }
        const result = await createBroadcast({
            name: value,
            start_at: new Date().toISOString()
        });
        if ('data' in result) {
            const { id } = result.data.broadcast;
            navigate(`/broadcast/${id}`);
        }
    }, [onError]);
    const handleInput = useCallback(() => {
        setError('');
        onError('');
    }, []);
    return (
        <div className={s['start-broadcasting']}>
            <div className={s['start-broadcasting__title']}>Начните эфир сейчас</div>
            <InputField
                onInput={handleInput}
                ref={inputRef}
                className={s['start-broadcasting__input-field']}
                isError={Boolean(error)}
                placeholder="Название эфира"
                formatter={formatLength}
            />
            <Button
                onClick={handleStart}
                className={s['start-broadcasting__button']}
                kind="primary"
            >В ЭФИР!</Button>
            <div className={s['start-broadcasting__error']}>{error}</div>
        </div>
    );
}