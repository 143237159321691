import { FC } from "react";

//* Props
import { InfoCardProps } from './InfoCard.interface';

//* Styles
import styles from './InfoCard.module.css';


const InfoCard: FC<InfoCardProps> = ({ title, text, image }) => {
  return (
    <div className={styles.info}>
      <div className={styles.info__wrapper}>
        <div className={styles.info__text}>
          <h2>{ title }</h2>
          <p>{ text }</p>
        </div>

        <img src={image} />
      </div>
    </div>
  );
};

export default InfoCard;