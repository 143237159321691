import { FC, useRef, useState } from "react";

// * Components
import Icon from "../../Icon";
import List from "./List";
import { CSSTransition } from "react-transition-group";

// * Hooks
import { useClickOutside } from "../../../hooks/useClickOutside";

// * Helpers
import cn from "classnames";

//* Styles
import styles from "./HeaderProjects.module.css";

const HeaderProjects: FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);

  const [isListOpen, setIsListOpen] = useState(false);

  useClickOutside(containerRef, () => {
    setIsListOpen(false);
  });

  return (
    <div ref={containerRef} className={styles.projects}>
      <div
        className={cn(styles.projects__icon, {[styles.projects__icon_active]: isListOpen})}
        onClick={() => setIsListOpen((isListOpen) => !isListOpen)}
      >
        <Icon name="projects" />
      </div>

      <CSSTransition
        nodeRef={listRef}
        in={isListOpen}
        timeout={200}
        unmountOnExit
        classNames="fade-in"
      >
        <div ref={listRef} className={styles.projects__list}>
          <List />
        </div>
      </CSSTransition>
    </div>
  );
};

export default HeaderProjects;
