import { FC } from 'react';

// * Image
import networks from "./assets/networks.png";

// * Styles
import style from "./GreetingImage.module.css";

const GreetingImage: FC = () => {
  return (
    <img src={networks} alt="Greeting" className={style['greeting-image']} />
  );
};

export default GreetingImage;