import { FC, useEffect } from "react";
import { Notify as NotifyProps, removeNotify } from "../../features/notifySlice";

// * Icons
import close from "./assets/close.svg";

// * Components
import Icon from "../Icon";

// * Hooks
import { useAppDispatch } from "../../app/hooks";
import { MarkNotificationsAsReadOptions, useMarkNotificationAsReadMutation } from "../../features/api/apiSlice";

//* Styles
import styles from './Notify.module.css';

// * Helpers
import cn from "classnames";

const deleteTimeout = 10000;

const Notify: FC<NotifyProps> = ({ notify_type = "success", message, id, type }) => {
  const dispatch = useAppDispatch();
  const barStyle = { animationDuration: `${deleteTimeout / 1000}s` };

  const [markNotificationsAsRead] = useMarkNotificationAsReadMutation();

  const handleRemove = (id: number) => {
    const payload: Required<MarkNotificationsAsReadOptions> = {
      systemNotificationIds: [],
      personalNotificationIds: []
    };

    if (type === "personal") {
      payload.personalNotificationIds.push(id);
    } else {
      payload.systemNotificationIds.push(id);
    }

    dispatch(removeNotify(id));
    markNotificationsAsRead(payload);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      handleRemove(Number(id));
    }, deleteTimeout);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div className={cn(styles.notify)}>
      <div className={styles.notify__wrapper}>
        <Icon name={`notify-${notify_type}`} />

        <p dangerouslySetInnerHTML={{__html: message}}></p>

        <div className={styles.notify__close} onClick={() => handleRemove(Number(id))}>
          <img src={close} />
        </div>
      </div>

      <div className={styles.notify__bar} style={barStyle}></div>
    </div>
  );
};

export default Notify;