import { FC, useEffect } from "react";
import { Notify } from "../../../../features/notifySlice";

// * Props
import { NotificationsListProps } from "./NotificationsList.interface";

// * Hooks
import { useGetNotificationsQuery, useMarkNotificationAsReadMutation } from "../../../../features/api/apiSlice";

// * Components
import Icon from "../../../Icon";
import NotificationItem from "../NotificationItem";

//* Styles
import styles from "./NotificationsList.module.css";

const NotificationsList: FC<NotificationsListProps> = ({
  handleNotificationsClose,
}) => {
  const [markNotificationsAsRead] = useMarkNotificationAsReadMutation();

  const { data: notifications } = useGetNotificationsQuery(
    {
      isUnread: false
    },
    {
      pollingInterval: Number(process.env.REACT_APP_NOTIFY_POLLING_INTERVAL)
    }
  );

  const getUnreadNotificationIds = (notificationType: Notify["type"]) => {
    if(!notifications) return;

    return notifications.notifications
      .filter(({ type, read }) => type === notificationType && !read)
      .map(({ id }) => id);
  };

  useEffect(() => {
    markNotificationsAsRead({
      systemNotificationIds: getUnreadNotificationIds("system"),
      personalNotificationIds: getUnreadNotificationIds("personal"),
    });
  }, [notifications]);

  return (
    <div className={styles.notifications}>
      <div className={styles.notifications__header}>
        <h5 className={styles.notifications__title}>Уведомления</h5>

        <div
          className={styles.notifications__close}
          onClick={() => handleNotificationsClose()}
        >
          <Icon name="close" />
        </div>
      </div>

      <div className={styles.notifications__body}>
        {notifications && [...notifications.notifications].reverse().map((notify) => (
          <NotificationItem key={notify.id} notify={notify} />
        ))}
      </div>
    </div>
  );
};

export default NotificationsList;
