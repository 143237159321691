import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { CSSTransition } from 'react-transition-group';
import { Button } from '../../Button';

// * Hooks
import { useScrollButton } from './hooks/useScrollButton';
import { useChat } from '../../../hooks/useChat';

// * Components
import Captcha from './Captcha';
import Settings from '../../ChatSettingsPopup';
import MessageTechnical from './MessageTechnical';
import Message from './Message';

// * Local
import styles from "./Chat.module.css";
import Form from './Form';
import ButtonScroll from './ButtonScroll';

const Chat: React.FC = () => {
  const params = useParams<{id: string}>();

  const { messages, captchaError, handleSendChatMessage, setCaptchaError, refetchMessages } = useChat({
    broadcastId: Number(params.id)
  });

  const [ windowRef, isScrollButtonVisible, scrollWindow ] = useScrollButton({
    appearsAfterScrolledPixels: 350,
  });
  const [ isFocusing, setFocusing ] = useState(true);

  const [ isSettingsShow, setIsSettingsShow ] = useState(false);

  const handleSendCaptcha = (captchaKey: string) => {
    if(!captchaError) return;

    const { message, error, platform } = captchaError;

    handleSendChatMessage({
      message,
      captcha_key: captchaKey,
      captcha_sid: error?.captcha_sid,
      retry_platform: platform
    });

    setCaptchaError(null);
  };

  // * Scroll comments to bottom on initialize and on new comment.
  useEffect(() => {
    if (!isFocusing) {
      scrollWindow('smooth');
    }
  }, [messages]);

  // * Нужно, чтобы отобразить только что отправленные сообщения, когда юзер переключается между вкладками
  useEffect(() => {
    refetchMessages();
  }, []);

  return (
    <React.Fragment>
      <Button
        className={styles['chat__widget-button']}
        kind="primary"
        onClick={() => setIsSettingsShow(true)}
      >
        Настроить виджет чата
      </Button>

      <div className={styles.chat__block}>
        {captchaError &&
          <div className={styles.chat__captcha}>
            <Captcha
              captchaImage={captchaError.error?.captcha_img}
              onSubmit={handleSendCaptcha}
            />
          </div>
        }

        <div
          className={styles.chat__messages}
          ref={windowRef}
          onMouseEnter={() => setFocusing(true)}
          onMouseLeave={() => setFocusing(false)}
        >
          <li className={styles.chat__message}>
            <MessageTechnical
              content={`
                Для работы мультичата необходимо запустить эфир. На данный момент сообщения поддерживаются для: Twitch, Trovo, ВК, GoodGame, Youtube. Больше информации
                <a href="https://restream.su/instruction#rec580752096" target="_blank" rel="noreferrer" class=${styles.link}>здесь</a>
              `}
            />
          </li>

          {
            messages.map((message, index) => (
              <li
                className={styles.chat__message}
                key={index}
              >
                <Message message={message}/>
              </li>
            ))
          }
        </div>

        <div className={styles.chat__form}>
          <Form isDisabled={Boolean(captchaError)} onSubmit={({ message }) => handleSendChatMessage({ message })}>
            <CSSTransition
              in={isScrollButtonVisible}
              timeout={300}
              classNames={{
                enter: styles['button-scroll-enter'],
                enterActive: styles['button-scroll-enter-active'] ,
                exit: styles['button-scroll-exit'],
                exitActive: styles['button-scroll-exit-active']
              }}
              unmountOnExit
              mountOnEnter
            >
              <ButtonScroll onClick={() => scrollWindow('smooth')}/>
            </CSSTransition>
          </Form>
        </div>
      </div>

      <Settings
        isShow={isSettingsShow}
        onClose={() => setIsSettingsShow(false)}
      />

    </React.Fragment>
  );
};

export default Chat;