import { FC } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

// * Components
import { Button } from "../../../Button";

//* Props
import { CaptchaProps } from "./Captcha.interface";

//* Styles
import styles from "./Captcha.module.css";
import PlatformIcon from "../../../PlatformIcon";

interface FormValues {
  captchaKey: string;
}

const Captcha: FC<CaptchaProps> = ({
  captchaImage,
  onSubmit,
}) => {
  const { register, handleSubmit } = useForm<FormValues>();

  const handleSendCaptcha: SubmitHandler<FormValues> = ({ captchaKey }) => {
    onSubmit(captchaKey);
  };

  return (
    <div className={styles.captcha}>
      <div className={styles.captcha__logo}>
        <div className={styles.captcha__icon}>
          <PlatformIcon platform="vk" />
        </div>
        ВКонтакте
      </div>

      <form className={styles.captcha__form} onSubmit={handleSubmit(handleSendCaptcha)}>
        <img className={styles.captcha__image} src={captchaImage} alt="Введите капчу"/>

        <input
          className={styles.captcha__input}
          type="text"
          placeholder="Код с картинки"
          autoFocus={true}
          {...register("captchaKey", {
            required: "Пожалуйста, введите капчу",
          })}
        />

        <div className={styles.captcha__button}>
          <Button kind="primary" type="submit">
            Отправить
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Captcha;
