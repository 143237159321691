import { ChangeEvent, forwardRef, useState } from "react";

//* Props
import { TextAreaFieldProps } from "./TextAreaField.interface";

// * Helpers
import cn from "classnames";

//* Styles
import styles from "./TextAreaField.module.css";

const TextAreaField = forwardRef<HTMLTextAreaElement, TextAreaFieldProps>(
  ({ label, error, className, defaultValue, isError, maxLength, ...restProps }, ref) => {
    const [value, setValue] = useState(defaultValue);

    const handleSetValue = (e: ChangeEvent<HTMLTextAreaElement>) => {
      const value = e.target.value;
      setValue(value);
    };

    return (
      <label className={styles.field}>
        {label && <div className={styles.field__label}>{label}</div>}

        <textarea
          {...restProps}
          ref={ref}
          value={value}
          maxLength={maxLength}
          className={cn(className, styles.field__textarea, {
            [styles.field__textarea_error]: isError,
          })}
          onChange={handleSetValue}
        />

        {error && (
          <div className={styles.field__error}>{error}</div>
        )}

        {maxLength && (
          <div className={styles.field__counter}>
            {value ? String(value).length : 0}/{maxLength}
          </div>
        )}
      </label>
    );
  }
);

export default TextAreaField;
