import { ComponentProps } from "react";
import classNames from "classnames";
import s from './Button.module.css';
import { useToggleChannelsMutation } from "../../features/api/apiSlice";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import TooltipButton from "../TooltipButton";

type Kind = {
    kind: 'primary' | 'secondary';
};

type ButtonProps = ComponentProps<'button'> & Kind;

type LinkButtonProps = ComponentProps<'a'> & Kind;

function getButtonBaseClassName(className: string | undefined, kind: Kind['kind']) {
    return classNames(className, s['button'], s[`button_${kind}`]);
}

export function Button({ kind, className, ...restProps }: ButtonProps) {
    let buttonClassName = getButtonBaseClassName(className, kind);
    return <button {...restProps} className={buttonClassName} />;
}

export function LinkButton({ kind, className, ...restProps }: LinkButtonProps) {
    const linkClassName = getButtonBaseClassName(className, kind);
    return <a {...restProps} className={linkClassName} />;
}

export function ButtonToggleOnOff() {
    const [isToggleChannels] = useToggleChannelsMutation();
    const params = useParams();
    const broadcastId = Number(params["id"]);
    const { theme } = useAppSelector((state) => state.options);
    function onOffToggleHandler(target: string){
        isToggleChannels({ broadcastId: broadcastId, target: target});
    };
    return (
        <div className={s["container"]}>
            <div className={theme === "dark" ? s["text-dark"] : s["text-light"]}>Переключить
                <TooltipButton
                    position="bottom-left"
                    text="Нажмите на кнопки ON или OFF, чтобы включить или выключить каналы. При нажатии на кнопку ON включится столько каналов, сколько предусмотрено в вашем тарифном плане."
                    />
            </div>
            <div className={s["container-button"]}>
                    <button onClick={() => {onOffToggleHandler("on");}} className={theme === "dark" ? s["button-on-dark"] : s["button-on-light"]}>ON</button>
                <div className={s["container-button"]}><span className={theme === "dark" ? s["distance-between-buttons-dark"] : s["distance-between-buttons-light"]}>|</span>
                    <button onClick={() => {onOffToggleHandler("off");}} className={theme === "dark" ? s["button-off-dark"] : s["button-off-light"]}>OFF</button>
                </div>
            </div>
        </div>
    );
}