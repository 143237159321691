import { FC, useState } from 'react';

// * Props
import { AsideWithTabsProps } from './AsideWithTabs.interface';

// * Helpers
import cn from "classnames";

// * Styles
import styles from "./AsideWithTabs.module.css";
import Icon from '../Icon';

const AsideWithTabs: FC<AsideWithTabsProps> = ({ items }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  return (
    <div className={cn(styles.aside)}>
      <div className={styles.aside__header}>
        <div className={styles.aside__tabs}>
          {items.map((item, idx) =>
            <div
              className={cn(styles.aside__tab, {[styles["aside__tab_active"]]: idx === activeTabIndex})}
              key={item.title}
              onClick={() => setActiveTabIndex(idx)}
            >
              <p className={styles["aside__tab-text"]}>
                {item.title}
              </p>

              {item.tabIcon && (
                <div className={styles["aside__tab-icon"]}>
                  <Icon name={item.tabIcon} />
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <div className={styles.aside__content}>
        {items[activeTabIndex].component}
      </div>
    </div>
  );
};

export default AsideWithTabs;