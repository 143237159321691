import { RefObject } from "react";

// * Styles
import styles from "../Tooltip.module.css";

export const useTooltip = (tooltipRef: RefObject<HTMLDivElement> ) => {

  const handleMouseEnter = () => {
    if(tooltipRef.current) {
      tooltipRef.current.classList.remove(styles["tooltip-hidden"]);
      tooltipRef.current.classList.add(styles["tooltip-visible"]);
    }
  };

  const handleMouseLeave = () => {
    if(tooltipRef.current) {
      tooltipRef.current.classList.remove(styles["tooltip-visible"]);
      tooltipRef.current.classList.add(styles["tooltip-hidden"]);
    }
  };

  return {
    handleMouseEnter,
    handleMouseLeave,
  };
};