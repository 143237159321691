import { FC, useRef } from "react";

// * Hooks
import { User, useToggleMailingMutation } from "../../../features/api/apiSlice";

// * Components
import { Checkbox } from "../../Checkbox";

// * Styles
import styles from './ProfileMailing.module.css';

const ProfileMailing: FC<{ user?: User }> = ({ user }) => {
  const checkboxInputRef = useRef<HTMLInputElement | null>(null);
  const [toggleMailing] = useToggleMailingMutation();

  const handleChange = () => {
    if(checkboxInputRef.current) {
      toggleMailing({
        userId: user?.user_id,
        noMailing: !checkboxInputRef.current.checked,
      });
    }
  };

  return (
    <Checkbox inputRef={checkboxInputRef} value={!user?.no_mailing} onChange={() => handleChange()}>
      <div className={styles.mailing}>
        <p className={styles.mailing__primary}>Информационная рассылка</p>
        <p className={styles.mailing__secondary}>При отключении вы будете получать только служебные письма</p>
      </div>
    </Checkbox>
  );
};

export default ProfileMailing;