// https://stackoverflow.com/questions/19540077/converting-unix-time-to-minutes-ago-in-javascript
export const formatTimestamp = (timestamp: number) => {
  // Формула: Миллисекунды * секунды * минуты * часы
  const millisecondsPerDay = 1000 * 60 * 60 * 24;
  const millisecondsPerHour = 1000 * 60 * 60;
  const millisecondsPerMinute = 1000 * 60;
  const millisecondsPerSecond = 1000;

  const days = Math.floor(timestamp / millisecondsPerDay);
  const hours = Math.floor((timestamp % millisecondsPerDay) / millisecondsPerHour);
  const minutes = Math.floor((timestamp % millisecondsPerHour) / millisecondsPerMinute);
  const seconds = Math.floor((timestamp % millisecondsPerMinute) / millisecondsPerSecond);

  let formattedTime = "";

  if (days > 0) {
    formattedTime += `${days} д. `;
  }
  if (hours > 0) {
    formattedTime += `${hours} ч. `;
  }
  if (minutes > 0) {
    formattedTime += `${minutes} м. `;
  }
  if (days <= 0 && seconds > 0) {
    formattedTime += `${seconds} с. `;
  }

  return formattedTime.trim();
};
