import { ComponentProps } from "react";
import cn from "classnames";
import s from "./Error.module.css";

type Props = ComponentProps<'div'>;

export function Error({ className, ...restProps}: Props) {
    return (
        <div {...restProps} className={cn(s['popup__error'], className)} />
    );
}