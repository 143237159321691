import { ComponentProps, forwardRef, useEffect, useState } from "react";
import classNames from "classnames";
import s from "./InputField.module.css";

export type Props = ComponentProps<'input'> & {
    isError?: boolean;
    labelClassName?: string;
    formatter?: (value: string) => string;
    isReset?: boolean;
    useValueState?: boolean;
    color?: "purple" | "gray";
    error?: string;
    isDisabled?: boolean;
};

export const InputField = forwardRef<HTMLInputElement, Props>(({
    labelClassName,
    className,
    isError = false,
    onFocus,
    onBlur,
    formatter,
    defaultValue,
    isReset = false,
    useValueState = true,
    color = "purple",
    error,
    isDisabled,
    ...restProps
}, ref) => {
    const [ isFocus, setIsFocus ] = useState(false);
    const [ value, setValue ] = useState(defaultValue);

    useEffect(() => {
        const value = restProps.value;
        if (!value) return;

        setValue(value);
    }, [restProps.value]);

    useEffect(() => {
        if (isReset) {
            setValue('');
        }
    }, [isReset]);
    return (
        <label className={classNames(labelClassName, s['input-field'])}>
            <input
                {...restProps}
                value={useValueState ? value : defaultValue}
                ref={ref}
                className={classNames(className,
                    s['input-field__input'],
                    s[`input-field__input_${color}`],
                    { [s['input-field__input_error']]: isError },
                    { [s['input-field__input_focus']]: isFocus },
                    { [s['input-field__input_disabled']]: isDisabled }
                )}
                readOnly={isDisabled}
                onFocus={(event) => {
                    setIsFocus(true);
                    onFocus?.(event);
                }}
                onBlur={(event) => {
                    setIsFocus(false);
                    onBlur?.(event);
                }}
                onChange={(event) => {
                    let { value } = event.target;
                    setValue(formatter?.(value) ?? value);
                }}
            />

            {error &&
                <div className={s['input-field__error']}>
                    {error}
                </div>
            }

            {restProps.maxLength &&
                <div className={s["input-field__counter"]}>
                    {value ? String(value).length : 0}/{restProps.maxLength}
                </div>
            }
        </label>
    );
});