import { FC, useState } from 'react';

// * Components
import TabTitle from './TabTitle';

// * Props
import { TabsProps } from './Tabs.interface';

// * Styles
import styles from "./Tabs.module.css";

const Tabs: FC<TabsProps> = ({ items }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

  return (
    <div className={styles.tabs}>

      <ul className={styles.tabs__titles}>
        { items.map((item, index) => (
            <TabTitle
              key={item.title}
              title={item.title}
              tabIndex={index}
              isActive={index === selectedTabIndex}
              setSelectedTab={setSelectedTabIndex}
            />
          ))
        }
      </ul>

      {items[selectedTabIndex].component}
    </div>
  );
};

export default Tabs;