import { useRef, useState } from "react";
import Icon from "../Icon";
import { InputField, Props as InputFieldProps } from "../InputField";
import { PasswordField } from "../PasswordField";
import s from "./CopyField.module.css";
import cn from "classnames";
import { delayAfterSuccessfulCopy } from "../../constants";
import { useChangeRtmpKeyMutation } from "../../features/api/apiSlice";
import { useParams } from "react-router-dom";
import classNames from "classnames";

type Props = Pick<InputFieldProps, 'value' | 'defaultValue' | 'type' | 'readOnly' | "useValueState" | "color">;

export function CopyField({ color = "purple", ...props }: Props) {
    const [isCopied, setIsCopied] = useState(false);
    const fieldRef = useRef<HTMLInputElement>(null);
    const fieldProps = {
        ...props,
        isError: false,
        color,
        labelClassName: s['copy-field__input'],
        ref: fieldRef,
    };

    const field = props.type === 'password'
        ? <PasswordField {...fieldProps} icon="toggle" />
        : <InputField {...fieldProps} />;
    function copyFieldValue() {
        if (!fieldRef.current) return;
        navigator.clipboard
            .writeText(fieldRef.current.value)
            .then(() => setIsCopied(true))
            .catch((error) => console.error(error));
        setTimeout(() => {
          setIsCopied(false);
        }, delayAfterSuccessfulCopy);
    }
    const params = useParams();
    const [ keyGenerate ] = useChangeRtmpKeyMutation();
    const broadcastId = Number(params["id"]);
    const [isRotating, setIsRotating] = useState(false);
    const keyHandler = () => {
      setIsRotating(true);
      keyGenerate({ broadcastId });

      setTimeout(() => {
        setIsRotating(false);
      }, 500);
    };

    return (
        props.type === 'password' ?
            (<div className={cn(s['copy-field__reload-button'], s[`copy-field_${color}`])}>
                {field}
                <div className={cn(s['copy-field-buttons'])}>
                    <button type="button" className={classNames(s['reload-button'], { [s['reload-button--is-rotating']]: isRotating })} onClick={keyHandler}>
                        <Icon name="reload" />
                    </button>
                    <button type="button" className={s['copy-field__button']} onClick={copyFieldValue}>
                        <Icon name={isCopied ? "done" : "copy"} />
                    </button>
                </div>
            </div>)
            :
            (<div className={cn(s['copy-field'], s[`copy-field_${color}`])}>
                {field}
                <div className={cn(s['copy-field-buttons'])}>
                    <button type="button" className={s['copy-field__button']} onClick={copyFieldValue}>
                        <Icon name={isCopied ? "done" : "copy"} />
                    </button>
                </div>
            </div>)
    );
}