import { FC, useState, useCallback } from 'react';

// * Components
import Layout from '../../components/Layout';
import { Broadcasts } from '../../components/Broadcasts';
import { CreateBroadcastPopup } from '../../components/CreateBroadcastPopup';

// * Styles
import styles from "./BroadcastsMobile.module.css";

const BroadcastsMobile: FC = () => {

  // * Popup state
  const [ isShowCreateBroadcastPopup, setIsShowCreateBroadcastPopup ] = useState(false);
  const handleCreateBroadcast = useCallback(() => setIsShowCreateBroadcastPopup(true), []);

  return (
    <Layout>
      <div className={styles.broadcasts}>
        <Broadcasts onCreateBroadcast={handleCreateBroadcast}/>
      </div>

      {isShowCreateBroadcastPopup && (
        <CreateBroadcastPopup onClose={() => setIsShowCreateBroadcastPopup(false)} />
      )}
    </Layout>
  );
};

export default BroadcastsMobile;