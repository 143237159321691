import React from "react";

import { ExclamationMark } from "../ExclamationMark";
import { PopupTitle } from "../PopupTitle";
import { useScreenWidth } from "../../hooks/useScreenWidth";

import { PopupTitleDangerousProps } from "./PopupTitleDangerous.interface";
import styles from './PopupTitleDangerous.module.css';

const PopupTitleDangerous: React.FC<PopupTitleDangerousProps> = ({ children }) => {
  const { isMobile } = useScreenWidth();

  return isMobile ? (
    <PopupTitle>
        <ExclamationMark className={styles['exclamation-mark']} />
        {children}
    </PopupTitle>
  ) : (
    <React.Fragment>
        <ExclamationMark className={styles['exclamation-mark']} />
        <PopupTitle>
          {children}
        </PopupTitle>
    </React.Fragment>
  );
};

export default PopupTitleDangerous;