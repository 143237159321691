import { FC } from 'react';

// * Router hooks
import { useLocation } from 'react-router-dom';

// * Components
import { Link } from 'react-router-dom';

// * Props
import { NavButtonProps } from './NavButton.interface';

// * Styles
import styles from "./NavButton.module.css";

// * Helpers
import cn from 'classnames';

const NavButton: FC<NavButtonProps> = ({ label, image, url }) => {
  const { pathname } = useLocation();

  const linkClass = cn(styles["nav-button"], { [styles.active]: pathname === url });

  return (
    <Link className={linkClass} to={url}>
      <img
        src={image}
        alt={label}
      />

      { label }
    </Link>
  );
};

export default NavButton;