import React from "react";
import cn from "classnames";

// * Local
import { PlatformProps } from './Platform.interface';
import styles from './Platform.module.css';

const Platform: React.FC<PlatformProps> = ({ icon, name, onClick, isDisabled }) => {
  return (
    <button
      className={cn(
        styles.platform,
        {[styles.platform_disabled]: isDisabled}
      )}
      onClick={onClick}
      disabled={isDisabled}
    >
      <div className={styles.platform__icon}>
        {icon}
      </div>

      <p className={styles.platform__name}>
        {name}
      </p>
    </button>
  );
};

export default Platform;