import { useEffect, useRef } from "react";

// * Styles
import styles from "../NotifyContainer.module.css";

export const useContainerPosition = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if(!containerRef.current) return;
      const action = window.scrollY > 50 ? "add" : "remove";
      containerRef.current.classList[action](styles["offset"]);
    };

    document.addEventListener("scroll", handleScroll);
    return () => document.removeEventListener("scroll", handleScroll);
  }, []);

  return [
    containerRef,
  ];
};