import { PageContainerWithBackground } from "../../components/PageContainerWithBackground";
import { FormWithLogo } from "../../components/FormWithLogo";
import s from "./Login.module.css";
import { InputField } from "../../components/InputField";
import { PasswordField } from "../../components/PasswordField";
import { Popover } from "../../components/Popover";
import { FormEvent, useCallback, useRef, useState } from "react";
import { Button } from "../../components/Button";
import { checkEmail } from "../../helpers/checkEmail";
import { Link } from "../../components/Link";
import { SocialNetworksLoginOrSignup } from "../../components/SocialNetworksLoginOrSignup";
import { useNavigate } from "react-router-dom";
import { useSignInMutation } from "../../features/api/apiSlice";
import { useUtmTag } from "../../hooks/useUtmTag";

type Props = {
  serverError: string;
  setServerError: (error: string) => void;
};

export function Login({ serverError, setServerError }: Props) {
  const [signIn] = useSignInMutation();
  const navigate = useNavigate();
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const { utmTagFromUrl } = useUtmTag();
  const signUpUrl = utmTagFromUrl ? `/signup?utm_source=${utmTagFromUrl}` : "/signup";

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const isInvalieEmail = !checkEmail(emailRef.current?.value ?? "");
      const isEmptyPassword = passwordRef.current?.value === "";
      if (isInvalieEmail) {
        setEmailError("Некорректный e-mail");
      }
      if (isEmptyPassword) {
        setPasswordError("Некорректный пароль");
      }
      if (isInvalieEmail || isEmptyPassword) {
        return;
      }
      if (!emailRef.current || !passwordRef.current) {
        return;
      }
      const result = await signIn({
        email: emailRef.current.value,
        password: passwordRef.current.value,
      });
      if ("error" in result) {
        setServerError((result.error as any).data.error);
      } else if ("data" in result) {
        navigate("/", { replace: true });
      }
    },
    []
  );
  const handleInput = useCallback((event: FormEvent<HTMLInputElement>) => {
    const { currentTarget } = event;
    if (currentTarget === emailRef.current) {
      setEmailError("");
    }
    if (currentTarget === passwordRef.current) {
      setPasswordError("");
    }
    setServerError("");
  }, []);

  return (
    <PageContainerWithBackground>
      <FormWithLogo onSubmit={handleSubmit} legendSmallIndent={true}>
        <div className={s["login"]}>
          <Popover text={emailError} className={s["login__popover"]}>
            <InputField
              ref={emailRef}
              type="email"
              placeholder="Email"
              isError={Boolean(emailError)}
              onInput={handleInput}
            />
          </Popover>
          <Popover text={passwordError} className={s["login__popover"]}>
            <PasswordField
              ref={passwordRef}
              placeholder="Пароль"
              icon="toggle"
              isError={Boolean(passwordError)}
              onInput={handleInput}
            />
          </Popover>
          <Popover text={serverError} className={s["login__popover"]}>
            <Button kind="primary" type="submit">
              Войти
            </Button>
          </Popover>
          <Button
            kind="secondary"
            type="button"
            onClick={() => navigate("/reset")}
          >
            Восстановить пароль
          </Button>
          <div className={s["login__text"]}>
            <div>
              Нет аккаунта? <Link to={signUpUrl}>Зарегистрируйтесь</Link>
            </div>
            <div>или войдите через соцсети:</div>
          </div>
          <SocialNetworksLoginOrSignup />
        </div>
      </FormWithLogo>
    </PageContainerWithBackground>
  );
}
