import { useCallback, useContext, useEffect, useState } from 'react';
import { Broadcast, Message, useGetChatMessagesQuery } from '../features/api/apiSlice';
import { SocketContext } from '../providers/SocketProvider/SocketProvider';
import { useSocketApi } from './useSocketApi';
import { SendChatMessageAction, SocketApiActionType, VkCaptchaErrorAction } from '../types/socket.api.types';

interface UseChatParams {
  broadcastId: Broadcast['id'];
  chatToken?: string;
}

export const useChat = ({ broadcastId, chatToken }: UseChatParams) => {
  const { connectToChatSocket, chatSocketIdentifier } = useContext(SocketContext);

  const [ captchaError, setCaptchaError ] = useState<VkCaptchaErrorAction["payload"] | null>(null);
  const [ messages, setMessages ] = useState<Message[]>([]);

  // * Get messages from chat
  const { isSuccess, data: fetchedMessages, refetch: refetchMessages } = useGetChatMessagesQuery({
    id: String(broadcastId),
    token: chatToken,
  });

  const { sendMessage } = useSocketApi({
    onNewChatMessage: (payload) => {
      setMessages((state) => {
        return [...state, payload];
      });
    },

    onVkCaptchaError: (payload) => {
      setCaptchaError(payload);
    }
  });

  const handleSendChatMessage = useCallback((data: SendChatMessageAction["payload"]) => {
    if (!chatSocketIdentifier) return;

    sendMessage({
      action: SocketApiActionType.SEND_NEW_CHAT_MESSAGE,
      identifier: chatSocketIdentifier,
      payload: {
        ...data,
        broadcast_id: broadcastId,
      },
    });
  }, [chatSocketIdentifier]);

  useEffect(() => {
    connectToChatSocket(broadcastId);

    if (isSuccess) {
      const reversedMessages = [...fetchedMessages.response].reverse();
      setMessages([...messages, ...reversedMessages]);
    }
  }, [setMessages, fetchedMessages, isSuccess]);

  return { messages, captchaError, handleSendChatMessage, setCaptchaError, refetchMessages };
};