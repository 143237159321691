import { FC, useEffect } from 'react';

// * Hooks
import { useSliderInput } from './hooks/useSliderInput';

// * Props
import { InputSliderProps } from './InputSlider.interface';

// * Styles
import styles from "./InputSlider.module.css";

const InputSlider: FC<InputSliderProps> = ({ range, max, min, markStep, step, changeRange }) => {
  // * Hooks
  const { tumbStyle, getTickmarksArray, calcTumbPosition } = useSliderInput();

  // * Set tumb value position
  useEffect(() => {
    calcTumbPosition(range, max, min);
  }, [range, max, min]);

  // * Handlers
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    changeRange(value);
    calcTumbPosition(value, max, min);
  };
  return (
    <div className={styles.slider}>
      <input
        type="range"
        value={range}
        max={max}
        min={min}
        step={step}
        onChange={(e) => handleChange(e)}
        list="tickmarks"
      />

      <span style={tumbStyle} className={styles["slider__range-tumb"]}>
        { range }%
      </span>

      <datalist className={styles.slider__tickmarks} id="tickmarks">
        { getTickmarksArray(min, max, markStep).map((mark) =>
            <option key={mark} value={mark}>
              {mark}%
            </option>)
        }
      </datalist>
    </div>
  );
};

export default InputSlider;