import { FC, useEffect, useRef } from "react";
import videojs from "video.js";

// * Config
import { PLAYER_DEFAULTS } from "./VideojsPlayer.config";

//* Props
import { VideojsPlayerProps } from "./VideojsPlayer.interface";

// * Styles
import "./video.js-custom.css";
import "video.js/dist/video-js.css";
import "@videojs/themes/dist/fantasy/index.css";

const VideojsPlayer: FC<VideojsPlayerProps> = ({
  broadcastUrl,
  playerOptions,
}) => {
  const videoRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<videojs.Player | null>(null);
  const reloadTimeoutIdRef = useRef<ReturnType<typeof setTimeout>>();

  const handlePlayerError = () => {
    // Т.к ссылка на трансляцию будет недоступна какое-то время, то перезапускаем плеер каждые 5 секунд
    // пока она не станет доступной
    reloadTimeoutIdRef.current = setTimeout(() => {
      if (!broadcastUrl) return;

      playerRef.current?.src({
        src: broadcastUrl,
        type: "application/x-mpegURL",
      });
    }, 5000);
  };

  useEffect(() => {
    if (!playerRef.current && videoRef.current) {
      const videoElement = document.createElement("video-js");

      videoRef.current.appendChild(videoElement);

      playerRef.current = videojs(videoElement, PLAYER_DEFAULTS);
    }

    return () => {
      clearTimeout(reloadTimeoutIdRef.current);

      if (playerRef.current && !playerRef.current.isDisposed()) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, [playerOptions]);

  useEffect(() => {
    // Ссылка на трансляцию изначально не установлена в плеер, устанавливаем ее только при запуске трансляции
    // для избежания случайного запуска плеера с недоступной ссылкой
    if (broadcastUrl) {
      playerRef.current?.src({
        src: broadcastUrl,
        type: "application/x-mpegURL",
      });
      playerRef.current?.on("error", handlePlayerError);
      return;
    }

    clearTimeout(reloadTimeoutIdRef.current);
    playerRef.current?.off("error", handlePlayerError);
    playerRef.current?.removeClass("vjs-waiting");
    playerRef.current?.hasStarted(false);
    playerRef.current?.pause();
  }, [broadcastUrl]);

  return <div ref={videoRef} className="video-js vjs-theme-fantasy"></div>;
};

export default VideojsPlayer;
