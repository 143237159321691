import s from "./Greeting.module.css";

// * Components
import GreetingImage from "./GreetingImage";

// * Hooks
import { useGetUserQuery } from "../../features/api/apiSlice";
import { useScreenWidth } from "../../hooks/useScreenWidth";

export function Greeting() {
    const { isMobile } = useScreenWidth();
    const { data: user } = useGetUserQuery();

    return (
        <div className={s['greeting']}>
            <div className={s['greeting__wrapper']}>
                <div className={s['greeting__title']}>Привет, рестример!</div>
                <div className={s['greeting__subtitle']}>Прочти <a href="https://restream.su/instruction" target="_blank" rel="noreferrer" className={s['greeting__subtitle-link']}>инструкцию</a> по началу работы<br/>с кабинетом</div>
                <div className={s['tariff-info']}>
                    <span className={s['tariff-info__value']}>{user?.tariff.name}</span><span>Тарифный план</span>
                    <span className={s['tariff-info__value']}>{user?.tariff.days_left}</span><span>Дней до окончания подписки</span>
                    <span className={s['tariff-info__value']}>{user?.tariff.max_online_outputs}</span><span>Доступных каналов</span>
                </div>
            </div>

            { !isMobile && <GreetingImage />}
        </div>
    );
}