import React, { FC, useState } from "react";

//* Props
import { TruncatedTextProps } from "./TruncatedText.interface";

// * Styles
import styles from "./TruncatedText.module.css";

const TruncatedText: FC<TruncatedTextProps> = ({ maxLength, text }) => {
  const [isFullText, setIsFullText] = useState(false);

  const truncatedText = text.slice(0, maxLength);

  if (text.length <= maxLength) {
    return <p className={styles.truncated__text}>{text}</p>;
  }

  return (
    <div className={styles.truncated}>
      <p className={styles.truncated__text}>
        {isFullText ? text : truncatedText}
        <span className={styles.truncated__more} onClick={() => setIsFullText(isFullText => !isFullText)}>
          {isFullText ? "Скрыть" : "Далее"}
        </span>
      </p>
    </div>
  );
};

export default TruncatedText;