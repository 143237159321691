import { useState } from "react";

export const useSliderInput = () => {
  // * Constants
  const tumbWidth = 2;
  const tumbOffset = 0.25;

  // * Styles for a floating element with a value
  const [tumbStyle, setTumbStyle] = useState({ left: "" });

  const calcTumbPosition = (value: string | number, max: number, min: number) => {
    const position = ((Number(value) - min) * 100) / (max - min);

    setTumbStyle({ left: `calc(${position}% + (${tumbWidth - position * tumbOffset}px))`});
  };

  const getTickmarksArray = (min: number, max: number, step: number) => {
    return Array.from(
      { length: Math.floor( ((max - min) / step) + 1 ) }, // new-array length
      (_, idx) => min + (idx * step) // Iterates each element in new-array
    );
  };

  return {
    tumbStyle,
    getTickmarksArray,
    calcTumbPosition,
  };
};