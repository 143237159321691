import { ComponentProps } from 'react';
import s from './Switch.module.css';

type Props = Omit<ComponentProps<'input'>, 'type' | 'className'>;

export function Switch(props: Props) {
	return (
		<label className={s.label}>
			<input {...props} type="checkbox" className={s.input} />
			<span className={s['fake-input']}></span>
		</label>
	);
};