import { Message } from "../../features/api/apiSlice";

export const previewMessages: Message[] = [
  {
    platform: "rutube",
    user: "User 1",
    message: "А у нас в квартире газ! А у вас?",
    date: 1,
  },
  {
    platform: "youtube",
    user: "User 2",
    message: "А у нас водопровод! Вот!",
    date: 2,
  },
  {
    platform: "ok",
    user: "User 3",
    message: "А из нашего окна площадь Красная видна!",
    date: 3,
  }
];

export const messages: Message[] = [
  {
    platform: "restream",
    user: "Restream.su",
    message: "стрим запущен с помощью нашего сервиса.",
    date: 1,
  },
];